.spectrum-Accordion {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.spectrum-Accordion-itemIndicator {
  display: block;

  position: absolute;
  left: 16px;
  top: 14.5px;

  transition: transform ease 130ms;
}
.spectrum-Accordion-item {
  z-index: inherit;
  position: relative;

  display: list-item;
  margin: 0;

  border-bottom: 1px solid transparent;
}
.spectrum-Accordion-item:first-of-type {
    border-top: 1px solid transparent;
  }
.spectrum-Accordion-itemHeading {
  margin: 0;
}
.spectrum-Accordion-itemHeader {
  position: relative;

  display: -ms-flexbox;

  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;

  box-sizing: border-box;
  padding: 12px 16px 12px 34px;
  margin: 0;

  min-height: 39px;

  font-size: 11px;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 0.0006em;

  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: inherit;
  border: 0;
  font-family: inherit;
  text-align: start;
  width: 100%;
}
.spectrum-Accordion-itemHeader:focus {
    outline: none;
  }
.spectrum-Accordion-itemHeader:focus::after {
      content: '';

      position: absolute;
      left: 0;
      top: -1px;
      bottom: -1px;

      width: 2px;
    }
.spectrum-Accordion-itemContent {
  padding: 0 16px 16px 16px;
  display: none;
}
.spectrum-Accordion-item.is-open > .spectrum-Accordion-itemHeading > .spectrum-Accordion-itemIndicator {
        transform: rotate(90deg);
      }
.spectrum-Accordion-item.is-open > .spectrum-Accordion-itemIndicator {
      transform: rotate(90deg);
    }
.spectrum-Accordion-item.is-open > .spectrum-Accordion-itemHeader::after {
      height: 39px;
    }
.spectrum-Accordion-item.is-open > .spectrum-Accordion-itemContent {
      display: block;
    }
.spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader {
      cursor: default;
    }
