import { Flex, ProgressCircle } from '@adobe/react-spectrum';
import { AdobeIMS } from '@identity/imslib/adobe-ims/AdobeIMS';
import { QueryClientProvider } from '@tanstack/react-query';
import { createBrowserHistory } from 'history';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { commonApi } from '@exchange-frontends/api';
import { ProductsSVGs } from '@exchange-frontends/components';
import { useIMS } from '@exchange-frontends/custom-hooks';
import { makeVersionString } from '@exchange-frontends/utils';

import RequiresAuthorization from './RequiresAuthorization';
import ScrollToTop from './ScrollToTop';
import TopBanner from './TopBanner';
import { ROUTES, queryClient } from './constants';
import Footer from './navigation/Footer';
import Header from './navigation/Header';
import { ContainerContext, addCSS, addScript } from './utils';

const FeaturedPage = lazy(() => import('./pages/featured'));
const BrowsePage = lazy(() => import('./pages/browse'));
const ManagePage = lazy(() => import('./pages/manage'));
const DetailsPage = lazy(() => import('./pages/details'));
const PublisherPage = lazy(() => import('./pages/publisher'));
const AboutPage = lazy(() => import('./About'));

export const history = createBrowserHistory();

/* Initializing global xchgVersions */
window.xchgVersions = {
  __container: makeVersionString(),
};

/* Initializing global adobeid obtained from IMSS */
window.adobeid = {
  autoValidateToken: true,
  client_id: process.env.REACT_APP_CLIENT_ID,
  scope: process.env.REACT_APP_SCOPE,
  locale: 'en_US',
  environment: process.env.REACT_APP_ENVIRONMENT,
  onReady: function () {
    // console.log('IMS Ready');
  },
};

/* Initializing global fedsConfig for the Privacy library in the footer */
window.fedsConfig = {
  privacy: {
    otDomainId: '7a5eb705-95ed-4cc4-a11d-0cc5760e93db-test',
    footerLinkSelector: '[data-nav-action="open-adchoices-modal"] span span span',
  },
};

/**
 * @description This function is used to inject the Adobe Privacy, Profile, and AppLauncher  scripts and CSS
 * @returns {boolean} Returns true if the scripts and CSS are injected successfully
 */
function inJectAdobePrivacyProfileAndLauncher() {
  const profilePath = process.env.REACT_APP_PROFILE_PATH;
  const appLauncherPath = process.env.REACT_APP_APP_LAUNCHER_PATH;
  return (
    addScript(process.env.REACT_APP_PRIVACY_PATH) &&
    addScript(profilePath + '/adobe-profile/latest/adobe-profile.min.js') &&
    addCSS(profilePath + '/adobe-profile/latest/adobe-profile.min.css') &&
    addScript(appLauncherPath + '/appl/latest/AppLauncher.js') &&
    addCSS(appLauncherPath + '/appl/latest/AppLauncher.css')
  );
}
const adobeIms = new AdobeIMS();

/** @description This function is used to fetch the orgs from IMS */
export const fetchOrgs = async () => {
  try {
    const { token } = window.adobeIMS.getAccessToken();
    const profileInfo = await window.adobeIMS.getProfile();

    // const url = `${process.env.REACT_APP_TRANSPORTER_API_HOST}`;
    // const url = `${process.env.REACT_APP_IMS_API_PATH}/account_cluster/v2?client_id=${process.env.REACT_APP_CLIENT_ID}`;
    const url = `${process.env.REACT_APP_IMS_API_PATH}/organizations/v6?client_id=${process.env.REACT_APP_CLIENT_ID}`;

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'x-api-key': process.env.REACT_APP_X_API_KEY,
      },
    };

    const response = await fetch(url, requestOptions).then((response) => response);
    /**
     * @type {{
     *  orgName: string;
     *  orgRef: { ident: string; authSrc: string; };
     *  orgType: string;
     *  countryCode: string;
     *  groups: { groupName: string; role: string; ident: number; groupType: string; groupDisplayName: string; }[];
     * }[]} data
     */
    const data = await response.json();
    const orgs = data?.map((org) => {
      const code = `${org?.orgRef?.ident}@${org?.orgRef?.authSrc}`;
      const type = org?.orgType === 'Enterprise' ? 'entp' : org?.orgType?.toLowerCase();
      return {
        id: code,
        code,
        name: org.orgName,
        type,
        imsType: type,
        role: profileInfo?.roles?.find((role) => role?.named_role === 'org_admin' && code === role?.organization)
          ? 'ADMIN'
          : 'NOT_ADMIN',
        maxApps: null, // not required???
        apps: null, // not required???
      };
    });
    return orgs;
  } catch (error) {
    console.error(error);
  }
};

const LoadingPage = () => {
  return (
    <Flex width="100%" flexGrow={1} alignItems="center" justifyContent="center">
      <ProgressCircle isIndeterminate size="L" aria-label="Loading page" />
    </Flex>
  );
};

function App() {
  commonApi.staticDataSettings.setEndpoint(process.env.STATIC_DATA_ENDPOINT);

  const [orgs, setOrgs] = useState();
  const [orgsLoaded, setOrgsLoaded] = useState(false);
  const ims = useIMS();

  /**
   * @description This useEffect is used to initialize the Adobe Profile and App Launcher
   */
  useEffect(() => {
    inJectAdobePrivacyProfileAndLauncher();
    adobeIms?.initialize();
    window.adobeIMS = adobeIms;
    const waitForAdobeIms = setInterval(() => {
      if (adobeIms?.initialized && window.AppLauncher) {
        const getAndSetProfile = async () => {
          window?.adobeProfile?.init({
            target: document.querySelector('#nav-adobe-profile'),
            locale: 'en',
          });
          window.adobeProfile?.addEventListener(window?.adobeProfile?.events?.sign_out, () => {
            localStorage.removeItem('orgs');
            localStorage.removeItem('selectedOrg');
          });

          const userId = await adobeIms?.getProfile()?.userId;
          const appLauncherConfig = {
            variant: 'multiCloud', // Optional
            analyticsContext: {
              consumer: {
                name: 'exchange', // Name of the client Application
                version: window?.xchgVersions?.__container, // Client application version
                platform: 'Web', // Web/Desktop
                client_id: process.env.REACT_APP_CLIENT_ID, // IMS client id
              },
              event: {
                visitor_guid: userId, // visitor_guid to be sent with ingest(analytics) event
              },
            },
            target: document.querySelector('#nav-adobe-app-launcher'), // Mandatory
            env: process.env.REACT_APP_APPLAUNCHER_ENV, // Mandatory
            theme: 'light', // Optional
            onViewAllApps: () => {}, // Optional
          };
          window.AppLauncher(appLauncherConfig);

          const orgs = await fetchOrgs();
          setOrgs(orgs);
          setOrgsLoaded(true);
        };
        if (adobeIms?.isSignedInUser()) {
          getAndSetProfile();
        } else {
          localStorage.removeItem('orgs');
          localStorage.removeItem('selectedOrg');
        }
        clearInterval(waitForAdobeIms);
      }
    }, [50]);
  }, []);

  /**
   * @description This useEffect is used to set the orgs in the localStorage
   * @dependency orgs
   */
  useEffect(() => {
    if (orgs) {
      window.localStorage.setItem('orgs', JSON.stringify(orgs));
    }
  }, [orgs]);

  return (
    <QueryClientProvider client={queryClient}>
      <ContainerContext.Provider value={{ orgs, orgsLoaded, ims }}>
        <ProductsSVGs />
        <Flex direction="column" gap="100px">
          <Flex direction="column" minHeight="100vh">
            <div>
              <Header routes={ROUTES} adobeIms={adobeIms} orgs={orgs} />
              <TopBanner />
            </div>
            <Flex flex={1} direction="column">
              <ScrollToTop />
              <Routes>
                <Route
                  path={`${ROUTES.FEATURED}/*`}
                  element={
                    <Suspense fallback={<LoadingPage />}>
                      <FeaturedPage />
                    </Suspense>
                  }
                />
                <Route
                  path={`${ROUTES.MANAGE}/*`}
                  element={
                    <Suspense fallback={<LoadingPage />}>
                      <RequiresAuthorization Element={orgsLoaded ? ManagePage : LoadingPage} />
                    </Suspense>
                  }
                />
                <Route
                  path={`${ROUTES.DETAILS}/*`}
                  element={
                    <Suspense fallback={<LoadingPage />}>
                      <DetailsPage />
                    </Suspense>
                  }
                />
                <Route
                  path={`${ROUTES.PUBLISHER}/*`}
                  element={
                    <Suspense fallback={<LoadingPage />}>
                      <PublisherPage />
                    </Suspense>
                  }
                />
                <Route
                  path={`${ROUTES.BROWSE}/*`}
                  element={
                    <Suspense fallback={<LoadingPage />}>
                      <BrowsePage />
                    </Suspense>
                  }
                />
                <Route
                  path={`${ROUTES.ABOUT}/*`}
                  element={
                    <Suspense fallback={<LoadingPage />}>
                      <AboutPage />
                    </Suspense>
                  }
                />
              </Routes>
            </Flex>
          </Flex>
          <Footer />
        </Flex>
      </ContainerContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
