/* istanbul ignore file */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { appsStoreSlice } from './apps';
import { envsStoreSlice } from './envs';
import { orgsStoreSlice } from './orgs';

const store = (set, get) => ({
  ...orgsStoreSlice(set, get),
  ...appsStoreSlice(set, get),
  ...envsStoreSlice(set, get),
});

export const useStore = create(devtools(store));
