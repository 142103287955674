export const SAP_CODE_TO_ICON_ID = Object.freeze({
  APRO: 'svg-icon-acrobat-pro',
  AEFT: 'svg-icon-after-effects',
  FLPR: 'svg-icon-animate',
  AUDT: 'svg-icon-audition',
  KBRG: 'svg-icon-bridge',
  ESHR: 'svg-icon-dimension',
  DRWV: 'svg-icon-dreamweaver',
  ILST: 'svg-icon-illustrator',
  AICY: 'svg-icon-incopy',
  IDSN: 'svg-icon-indesign',
  LTRM: 'svg-icon-lightroom-classic',
  LRCC: 'svg-icon-lightroom',
  PHSP: 'svg-icon-photoshop',
  PRLD: 'svg-icon-prelude',
  RUSH: 'svg-icon-premiere-rush',
  PPRO: 'svg-icon-premiere-pro',
  PRTM: 'svg-icon-primetime',
  SPRK: 'svg-icon-xd',
});
export const APP_BUILDER_ENV_TYPE = 'appBuilder';

export const ENV_STATUS = Object.freeze({
  DRAFT: 'DRAFT',
  INSTALLED: 'DEPLOYED',
  DEPLOYMENT_FAILED: 'DEPLOYMENT_FAILED',
  DELETED: 'DELETED',
  DEPLOYMENT_IN_PROGRESS: 'DEPLOYMENT_IN_PROGRESS',
  UNDEPLOYMENT_IN_PROGRESS: 'UNDEPLOYMENT_IN_PROGRESS',
});

export const APP_STATUS = Object.freeze({});

export const CONSENT_STATUSES = Object.freeze({
  REQUESTED: 'REQUESTED',
  ALLOWED: 'ALLOWED',
  DENIED: 'DENIED',
});

export const CONSENT_ACTIONS = Object.freeze({
  ALLOW: 'ALLOW',
  DENY: 'DENY',
  REQUEST: 'REQUEST',
});
export const CONSENT_STATUS_TO_APP_STATUS = Object.freeze({
  REQUESTED: 'pending',
  ALLOWED: 'published',
  DENIED: 'rejected',
});

export const DEPLOYMENT_STATUS = Object.freeze({
  SUCCESS: 'DEPLOYED',
  FAILED: 'DEPLOYMENT_FAILED',
  IN_PROGRESS: 'DEPLOYMENT_IN_PROGRESS',
  UNDEPLOYMENT_IN_PROGRESS: 'UNDEPLOYMENT_IN_PROGRESS',
  UNDEPLOYED: 'UNDEPLOYED',
  UNDEPLOYMENT_FAILED: 'UNDEPLOYMENT_FAILED',
});

export const ACTIVATION_STATUS = Object.freeze({
  FAILED: 1,
  SUCCESS: 0,
  1: 'FAILED',
  0: 'SUCCESS',
});
