/**
 * Starts a long running poll process that will call the given function
 * until the validate function returns true or maxAttempts is reached
 * or the action is aborted. (Stolen from MDFW)
 * @template T
 * @param {Object} params
 * @param {() => Promise<T>} params.fn
 * @param {(obj: T) => boolean} params.validate
 * @param {number} params.interval
 * @param {number} params.maxAttempts
 * @param {AbortSignal} params.signal
 */
export const poll = async ({ fn, validate, interval, maxAttempts, signal }) => {
  let attempts = 0;

  const callPollFunction = async (resolve, reject) => {
    const result = await fn();
    attempts++;

    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts to poll for a response'));
    } else if (signal?.aborted) {
      return reject(new Error('Polling aborted'));
    } else {
      setTimeout(callPollFunction, interval, resolve, reject);
    }
  };

  return new Promise(callPollFunction);
};
