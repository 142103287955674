//@ts-check
/**
 * @template ServerErrorResponse
 */
export class RequestError extends Error {
  static genericErrorMessage = 'Something went wrong. Please try again in a few minutes.';

  /**
   * @param {Object} config
   * @param {string} [config.message]
   * @param {Response} [config.fetchResponse]
   * @param {string} [config.textResponse]
   * @param {ServerErrorResponse} [config.jsonResponse]
   * @param {Error} [config.originalError]
   */
  constructor(config) {
    super(config.message || RequestError.genericErrorMessage);
    this.fetchResponse = config.fetchResponse;
    this.textResponse = config.textResponse;
    this.jsonResponse = config.jsonResponse;
    this.originalError = config.originalError;
  }
}
