import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Text,
  useDialogContainer,
} from '@adobe/react-spectrum';
import React from 'react';

const SpProductProfilesDialog = () => {
  const { dismiss } = useDialogContainer();

  return (
    <Dialog size="L">
      <Heading>Select product profiles</Heading>
      <Divider />
      <Content>
        <Flex gap="size-400" direction="column">
          <Text>
            Select an API and choose the product profiles to assign. Your integration&apos;s service account will gain
            access to granular features through the product profiles selected here.
          </Text>
          {/* @TODO: Add product profile logic here XCHG-11723 */}
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dismiss}>
          Cancel
        </Button>
        <Button variant="accent" onPress={dismiss}>
          Continue with selected product profiles
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default SpProductProfilesDialog;
