//@ts-check
import { entitlementApiClient } from '../../utils/RequestClient';

/**
 * @param {object} params
 * @param {string} params.orgId
 * @param {string} params.token
 * @param {string} params.appId
 * @param {'ALLOW' | 'DENY' | 'REQUEST' | 'REVOKE'} params.action
 * @param {string} [params.notes]
 * @returns {Promise<import('@action-types/consent').Consent>}
 * @throws {RequestError<import('@action-types/entitlement').EntitlementApiErrorResponse>}
 */
const patchConsent = ({ token, orgId, appId, action, notes = 'N/A' }) => {
  return entitlementApiClient.fetch({
    endpoint: `/enterprise/consent`,
    requestOptions: {
      method: 'PATCH',
      body: JSON.stringify({ action, appId, notes }),
      headers: {
        'x-org-id': orgId,
        authorization: token,
      },
    },
  });
};

export default patchConsent;
