import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  ProgressCircle,
  Text,
  TextField,
} from '@adobe/react-spectrum';
import DeleteOutline from '@spectrum-icons/workflow/DeleteOutline';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../../../store';
import { ENV_STATUS } from '../../../../../../../utils/Constants';
import { UserDataContext } from '../../../../../../App';
import { NonAdminNote } from '../../../common/StyledComponents';

export const DeleteEnvDialog = ({ env, orgId, app, close }) => {
  const envLoading = useStore((state) => state.envLoading);
  const removeEnv = useStore((state) => state.deleteEnv);
  const { accessToken } = useContext(UserDataContext) ?? {};
  const [nameTyped, setNameTyped] = useState('');
  const [nameError, setNameError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const handleNameTyped = (value) => {
    setNameTyped(value);
    if (value !== env.name) {
      setNameError(true);
      setIsDisabled(true);
    } else {
      setNameError(false);
      setIsDisabled(false);
    }
  };
  const deleteEnv = (close) => {
    removeEnv(accessToken, orgId, app.id, env?.id).then((response) => {
      if (response) {
        close();
        navigate(`/manage/apps/ec/${orgId}/view/${app?.id}`);
      } else {
        close();
      }
    });
  };
  return (
    <Dialog maxWidth="size-6000">
      {envLoading ? (
        <Content height="100%">
          <Flex alignItems="center" direction="column">
            <ProgressCircle size="L" isIndeterminate aria-label="Creating environment" marginStart="size-150" />
            <em>Deleting environment…</em>
          </Flex>
        </Content>
      ) : (
        <>
          <Heading>Delete environment: {env?.name}</Heading>
          <Divider />
          <Content>
            <>
              <Text marginY="size-175">
                <strong>This action can not be undone.</strong>
              </Text>
              <p>To delete this environment, type in the environment name.</p>
              <TextField
                width="100%"
                label="Env name"
                isRequired="true"
                aria-invalid="true"
                id="envName"
                aria-describedby="envNameError"
                aria-required="true"
                value={nameTyped}
                autoComplete="off"
                placeholder="Type in environment name"
                validationState={nameError ? 'invalid' : undefined}
                onChange={handleNameTyped}
                autoFocus
              />
            </>
          </Content>
          <ButtonGroup>
            <Button onPress={close} variant="primary" data-launchid="Cancel env deletion">
              Cancel
            </Button>
            <Button
              variant="negative"
              onPress={() => deleteEnv(close)}
              data-launchid="Delete environment"
              isDisabled={isDisabled}
            >
              Delete environment
            </Button>
          </ButtonGroup>
        </>
      )}
    </Dialog>
  );
};
export const EnvDetailsButtons = ({ env, app, orgId }) => {
  const selectedOrg = useStore((state) => state.selectedOrg);
  const isAdmin = selectedOrg?.role === 'ADMIN';
  const disable = useStore((state) => state.isDeploying);
  const isDeleteEnvDisabled = [
    ENV_STATUS.INSTALLED,
    ENV_STATUS.DEPLOYMENT_IN_PROGRESS,
    ENV_STATUS.UNDEPLOYMENT_IN_PROGRESS,
  ].includes(env?.status);
  return (
    <Flex>
      <DialogTrigger>
        <ActionButton
          label="Delete environment"
          isDisabled={isDeleteEnvDisabled || disable || !isAdmin}
          data-testid="env-view-action-button"
        >
          <DeleteOutline />
          <Text>Delete environment</Text>
        </ActionButton>
        {(close) => <DeleteEnvDialog close={close} env={env} orgId={orgId} app={app} />}
      </DialogTrigger>
      {!isAdmin && <NonAdminNote />}
    </Flex>
  );
};
