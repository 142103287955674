import { Flex, ProgressCircle } from '@adobe/react-spectrum';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { parseFromLocalStorage } from '@exchange-frontends/utils';

import useEnterpriseEntitlements from '../../../../actions/entitlementApi/selectors/ecAppsList/useEnterpriseEntitlements';
import useSpFeatureFlag from '../../../../actions/utilApi/selectors/useSpFeatureFlag';
import { useStore } from '../../store';
import { TABS_DEFINITION } from '../../utils/TabsDefinition';

const selectedOrgSelector = (state) => state.selectedOrg;
const ccEntitlementsSelector = (state) => state.entitlements;
const allAppBuilderAppsSelector = (state) => state.allApps;

export const DefaultTab = () => {
  const selectedOrg = useStore(selectedOrgSelector);
  const ccEntitlements = useStore(ccEntitlementsSelector);
  const allAppBuilderApps = useStore(allAppBuilderAppsSelector);
  const spFeatureFlagEnabled = useSpFeatureFlag().data;
  const spEntitlementsData = useEnterpriseEntitlements('S2S', { enabled: spFeatureFlagEnabled });

  const orgs = parseFromLocalStorage('orgs');
  const hasOrgs = orgs?.length > 0;
  const isEnterpriseOrg = selectedOrg?.type === 'entp';
  const isLoadingEntpEntitlements = !allAppBuilderApps || (spEntitlementsData.isLoading && spFeatureFlagEnabled);
  const missingData = !selectedOrg || !ccEntitlements || (isLoadingEntpEntitlements && isEnterpriseOrg);
  const isLoading = missingData && hasOrgs;

  if (isLoading) {
    return (
      <Flex justifyContent="center" flexGrow={1} alignItems="center">
        <ProgressCircle isIndeterminate size="L" />
      </Flex>
    );
  }

  const hasAppBuilderApps = allAppBuilderApps?.length > 0;
  const hasCCApps = ccEntitlements?.length > 0;
  const hasSpApps = spEntitlementsData?.data?.enterpriseEntitlements?.length > 0;
  const hasApps = hasCCApps || hasAppBuilderApps || (spFeatureFlagEnabled && hasSpApps);

  const paths = [
    {
      condition: spFeatureFlagEnabled && isEnterpriseOrg && (hasSpApps || !hasApps),
      path: TABS_DEFINITION.INTEGRATIONS.computePath(TABS_DEFINITION.INTEGRATIONS.path),
    },
    {
      condition: isEnterpriseOrg && (hasAppBuilderApps || !hasApps),
      path: TABS_DEFINITION.APP_BUILDER.computePath(TABS_DEFINITION.APP_BUILDER.path),
    },
    {
      path: TABS_DEFINITION.CC_PLUGINS.path,
      // FALLBACK: make sure a path is always returned
      condition: true,
    },
  ];

  const defaultPath = paths.find((tab) => tab.condition).path;

  return <Navigate replace to={defaultPath} />;
};
