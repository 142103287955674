import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useStore } from '../../../../store';
import { AppManage } from './AppManage';
import AppManageLayout from './common/AppManageLayout';
import ErrorContainer from './env/ErrorContainer';
import EnvDetails from './env/tabs/DetailsTab';
import EnvInsights from './env/tabs/InsightsTab';

const EnvConfiguration = React.lazy(() => import('./env/tabs/ConfigurationTab'));

export const ECRoutes = () => {
  const selectedOrg = useStore((state) => state.selectedOrg);

  return (
    <Routes>
      <Route
        index
        element={
          <AppManageLayout>
            <AppManage selectedOrg={selectedOrg} />
          </AppManageLayout>
        }
      />
      <Route
        path=":envId/*"
        element={
          <AppManageLayout>
            <Routes>
              <Route
                path="details"
                element={
                  <ErrorContainer>
                    <EnvDetails />
                  </ErrorContainer>
                }
              />
              <Route
                path="deploy"
                element={
                  <ErrorContainer>
                    <Suspense fallback={<div>Loading...</div>}>
                      <EnvConfiguration />
                    </Suspense>
                  </ErrorContainer>
                }
              />
              <Route
                path="insights"
                element={
                  <ErrorContainer>
                    <EnvInsights />
                  </ErrorContainer>
                }
              />
            </Routes>
          </AppManageLayout>
        }
      />
    </Routes>
  );
};
