import { error, success } from '@react/react-spectrum/Toast';
import qs from 'query-string';

import { commonApi, handleApiCall, manageApi } from '@exchange-frontends/api';

import { ITEMS_PER_PAGE } from '../components/tabs/apps-list/AppsList';
import { CONSENT_ACTIONS, CONSENT_STATUS_TO_APP_STATUS } from '../utils/Constants';

export const INIT_STATE = Object.freeze({
  currentAppLoading: false,
  allApps: null,
  ecAppsLoading: true,
  ccPlugins: null,
  entitlements: null,
  ccPluginsLoading: true,
  appBuilderExtensionPoints: new Map([]),
  hostApps: [],
});

const toTimestamp = (date = '2222.02.15') => new Date(date).getTime();

export const appsStoreSlice = (set, get) => ({
  ...INIT_STATE,

  setAppStoreValue: (value) => set(value),

  fetchCCPlugins: async (accessToken, entitlements) => {
    const page = Number(qs.parse(location.search).page ?? '1');

    const paginatedEntitlements = entitlements.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
    const ids = paginatedEntitlements.map((ent) => ent.appId);

    const acquiredDatesMap = new Map(entitlements.map((ent) => [ent.appId, toTimestamp(ent.modified.on)]));

    const handler = handleApiCall(manageApi.getCcApps, {
      accessToken,
      ids,
      acquiredDatesMap,
    });

    try {
      const ccPlugins = await handler.run();
      set({ ccPlugins, ccPluginsLoading: false });
      return ccPlugins;
    } catch (err) {
      error(err.userMessage, { timeout: 0 });
      console.error(err);
      set({ ccPluginsLoading: false });
      return [];
    }
  },

  fetchECApps: async (accessToken, orgId, mode = 'card') => {
    // eslint-disable-next-line no-unused-vars
    const { ccPlugins, ccPluginsLoading, ...ecInitState } = INIT_STATE;
    set(ecInitState);
    const handler = handleApiCall(manageApi.getEcApps, { accessToken, orgId });
    const entitlementsHandler = handleApiCall(manageApi.getEnterpriseEntitlements, {
      accessToken,
      orgId,
      appType: 'ABD',
    });
    let jaegerApps = [];
    let publicApps = [];
    let allApps = [];
    try {
      const ecApps = await handler.run();
      // TODO: pass a filter to the backend once they support it
      jaegerApps = ecApps.filter((app) => app.jaeger);
      jaegerApps = jaegerApps.map((object) => {
        return { ...object, distribution: 'private' };
      });
      /** load public apps */
      const entitlements = await entitlementsHandler.run();
      let isvApps = [];
      let consents = [];
      let users = [];
      //load public apps
      if (entitlements.length > 0) {
        const appIds = entitlements.map((entitlement) => entitlement.appId).join(', ');
        const apps = entitlements.map(({ appId }) => ({ appId }));
        const consentHandler = handleApiCall(manageApi.getConsent, { accessToken, orgId, appIds });
        const response = await consentHandler.run();
        consents = response?.consents;
        users = response?.user;
        const publicAppHandler = handleApiCall(manageApi.getPublicEc, { accessToken, orgId, apps, mode });
        isvApps = await publicAppHandler.run();
      }
      publicApps = consents.map((object) => {
        const id = object.appId;
        const isvApp = isvApps.find((app) => app.id === id);
        const status = CONSENT_STATUS_TO_APP_STATUS[object.status];
        const submittedBy = users.find((user) => user?.guid === object?.submittedBy)?.email || object?.submittedBy;
        return isvApp
          ? { ...object, ...isvApp, distribution: 'public', status: status, submittedBy: submittedBy }
          : { ...object, distribution: 'public', id: id, status: status, submittedBy: submittedBy };
      });
      allApps = [...publicApps, ...jaegerApps];
      set({ allApps: allApps, ecAppsLoading: false });
      return allApps;
    } catch (err) {
      error(err.userMessage, { timeout: 0 });
      console.error(err);
      allApps = [...publicApps, ...jaegerApps];
      set({ ecAppsLoading: false, allApps: allApps });
      return allApps;
    }
  },
  reviewXRApp: async (accessToken, orgId, appId, action, notes, handleRedirect, close) => {
    const handler = handleApiCall(manageApi.reviewXrApp, { accessToken, orgId, appId, action, notes });
    try {
      await handler.run();
      close();
      if (action === 'APPROVE') {
        const app = get().allApps.find((app) => app.id === appId);
        set((state) => ({
          allApps: state.allApps.map((item) =>
            item.id === app.id ? { ...item, lastModifiedDatetime: new Date().getTime(), status: 'PUBLISHED' } : item
          ),
        }));
      } else {
        handleRedirect && handleRedirect();
        set((state) => ({
          allApps: state.allApps.filter((app) => app.id !== appId),
        }));
      }
      success('App successfully reviewed.');
      return { ok: true };
    } catch (err) {
      console.error(err);
      return { ok: false, error: err.userMessage };
    }
  },
  retractXRApp: async (accessToken, orgId, appId, email, name, handleRedirect, close) => {
    const handler = handleApiCall(manageApi.retractXrApp, { accessToken, orgId, appId, email, name });
    try {
      await handler.run();
      close();
      handleRedirect && handleRedirect();
      set((state) => ({
        allApps: state.allApps.filter((app) => app.id !== appId),
      }));
      success('App successfully retracted.');
      return { ok: true };
    } catch (err) {
      console.error(err);
      return { ok: false, error: err.userMessage };
    }
  },
  fetchEntitlements: async (accessToken) => {
    const handler = handleApiCall(manageApi.getEntitlements, {
      accessToken,
    });
    try {
      const entitlements = await handler.run();
      set({ entitlements });
      return entitlements;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  fetchCurrentApp: async (accessToken, orgId, appId, mode = 'details') => {
    const handler = handleApiCall(manageApi.getPublicEc, { accessToken, orgId, appIds: [{ appId }], mode });
    try {
      set({ currentAppLoading: true });
      const app = await handler.run();
      const allApps = get().allApps.map((item) =>
        item.id === app?.[0].id
          ? {
              ...item,
              ...app[0],
            }
          : item
      );

      set({ allApps: allApps, currentAppLoading: false });
      return { ok: true, currentApp: app[0] };
    } catch (err) {
      set({ currentAppLoading: false });
      console.error(err);
      return { ok: false, error: err.userMessage ?? 'Something went wrong' };
    }
  },

  /** public EC apps */
  updateConsent: async (accessToken, orgId, appId, appVersionId, action, notes = '', close = null, message = null) => {
    const handler = handleApiCall(manageApi.updateConsent, {
      accessToken,
      orgId,
      appId,
      appVersionId,
      action,
      notes,
    });
    try {
      const consent = await handler.run();
      const app = get().allApps.find((app) => app.id === appId);
      const status = CONSENT_STATUS_TO_APP_STATUS[consent.status];
      set((state) => ({
        allApps: state.allApps.map((item) =>
          item.id === app.id
            ? {
                ...item,
                distribution: 'public',
                status: status,
              }
            : item
        ),
      }));
      /** close modal dialog */
      if (action !== CONSENT_ACTIONS.REQUEST) close();

      let actionMessage = '';
      switch (action) {
        case CONSENT_ACTIONS.REQUEST:
          actionMessage = 'Request for review is sent successfully';
          break;
        case CONSENT_ACTIONS.ALLOW:
          actionMessage = 'Application approved';
          break;
        case CONSENT_ACTIONS.DENY:
          actionMessage = 'Application rejected';
          break;
        default:
          break;
      }
      actionMessage = message || actionMessage;
      success(actionMessage);
      return { ok: true };
    } catch (err) {
      console.error(err);
      return { ok: false, error: err.userMessage ?? 'Something went wrong' };
    }
  },

  removePlugins: async () => {
    set({ ccPlugins: null, ccPluginsLoading: true });
  },

  setInitialCCState: () => {
    set({ ccPluginsLoading: true });
  },

  fetchAppBuilderExtensionPoints: async () => {
    const handler = handleApiCall(commonApi.staticData, { slice: 'extensionPoints' });
    try {
      const response = await handler.run();
      set({ appBuilderExtensionPoints: response });
      return response;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  downloadEcAppCode: async (accessToken, orgId, appVersionId) => {
    const handler = handleApiCall(
      manageApi.downloadAppCode,
      (manageApi.getConsent, { accessToken, orgId, appVersionId })
    );
    try {
      const response = await handler.run();
      return { ok: true, data: response };
    } catch (err) {
      console.error(err);
      error(err.userMessage, { timeout: 0 });
      return { ok: false };
    }
  },
});
