{
  "name": "@react-spectrum/provider",
  "version": "3.8.2",
  "description": "Spectrum UI components in React",
  "license": "Apache-2.0",
  "main": "dist/main.js",
  "module": "dist/module.js",
  "exports": {
    "types": "./dist/types.d.ts",
    "import": "./dist/import.mjs",
    "require": "./dist/main.js"
  },
  "types": "dist/types.d.ts",
  "source": "src/index.ts",
  "files": [
    "dist",
    "src"
  ],
  "sideEffects": [
    "*.css"
  ],
  "targets": {
    "main": {
      "includeNodeModules": [
        "@adobe/spectrum-css-temp"
      ]
    },
    "module": {
      "includeNodeModules": [
        "@adobe/spectrum-css-temp"
      ]
    }
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/adobe/react-spectrum"
  },
  "dependencies": {
    "@react-aria/i18n": "^3.8.2",
    "@react-aria/overlays": "^3.17.0",
    "@react-aria/utils": "^3.20.0",
    "@react-spectrum/utils": "^3.10.2",
    "@react-types/provider": "^3.6.4",
    "@react-types/shared": "^3.20.0",
    "@swc/helpers": "^0.5.0",
    "clsx": "^1.1.1"
  },
  "devDependencies": {
    "@adobe/spectrum-css-temp": "3.0.0-alpha.1"
  },
  "peerDependencies": {
    "react": "^16.8.0 || ^17.0.0-rc.1 || ^18.0.0",
    "react-dom": "^16.8.0 || ^17.0.0-rc.1 || ^18.0.0"
  },
  "publishConfig": {
    "access": "public"
  }
}
