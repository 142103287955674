import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  ProgressCircle,
  Text,
  TextField,
} from '@adobe/react-spectrum';
import Add from '@spectrum-icons/workflow/Add';
import React, { useContext, useMemo, useState } from 'react';

import { useStore } from '../../../../../store';
import { UserDataContext } from '../../../../App';
import { AppDetailsSection, EmptyStateContainer, ItalicText, NonAdminNote } from '../common/StyledComponents';
import EnvCard from './Card';

const envNamePattern = /^[A-Za-z0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*$/;
const EnvListContainer = ({ envList, app, orgId }) => {
  return (
    <Flex gap="size-400" width="976px" wrap="wrap" marginStart="16px">
      {envList?.map((env) => (
        <EnvCard key={env.id} env={env} app={app} orgId={orgId} />
      ))}
    </Flex>
  );
};

const EnvList = ({ app, orgId }) => {
  const addEnv = useStore((state) => state.createEnv);
  const { accessToken } = useContext(UserDataContext) ?? {};
  const envLoading = useStore((state) => state.envLoading);
  const deletionInProgress = useStore((state) => state.deletionInProgress);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const stateEnvList = useStore((state) => state.envList);
  // const [envList, setEnvList] = useState([]);
  const selectedOrg = useStore((state) => state.selectedOrg);
  const isAdmin = selectedOrg?.role === 'ADMIN';
  const envList = useMemo(() => stateEnvList?.[app?.id] || [], [stateEnvList?.[app?.id]]);

  const [envName, setEnvName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleNameTyped = (value) => {
    setEnvName(value);
    setNameError(false);
    setErrorMessage('');
    if (value.length > 10 || value.length < 3) {
      setNameError(true);
      setErrorMessage('Environment name must be between 3 and 10 characters long.');
    }
    if (value !== '' && !envNamePattern.test(value.trim())) {
      setNameError(true);
      setErrorMessage('Environment name should only contain English alphanumeric or Latin alphabet characters.');
    }
  };

  const saveEnv = (close) => {
    setIsDialogOpened(true);
    addEnv(close, accessToken, orgId, app.id, app.appVersionId, envName, app.name)
      .then(() => {
        setIsDialogOpened(false);
      })
      .finally(() => {
        setEnvName('');
      });
  };

  return (
    <>
      <Flex alignItems="center" gap="size-250" marginTop="size-200" wrap="wrap">
        <AppDetailsSection>Environments</AppDetailsSection>
        {!deletionInProgress && envLoading && !isDialogOpened ? (
          <Flex width="100%" justifyContent="center" alignItems="center">
            <ProgressCircle size="L" isIndeterminate aria-label={'Loading...'} />
          </Flex>
        ) : (
          <Flex>
            <DialogTrigger>
              <ActionButton isDisabled={!isAdmin}>
                <Add />
                <Text>Add Environment</Text>
              </ActionButton>
              {(close) => (
                <Dialog maxWidth="size-6000">
                  {envLoading ? (
                    <Content>
                      <Flex alignItems="center" direction="column" justifyContent="center">
                        <ProgressCircle
                          size="L"
                          isIndeterminate
                          aria-label="Creating environment"
                          marginStart="size-150"
                        />
                        <ItalicText>Creating environment…</ItalicText>
                      </Flex>
                    </Content>
                  ) : (
                    <>
                      <Heading>Add new environment</Heading>
                      <Divider />
                      <Content>
                        <Flex wrap="wrap" gap="28px">
                          <Text>
                            Give your environment a name unique to this application. Once the environment is created,
                            you will not be able to edit its name.
                          </Text>
                          <TextField
                            label="Environment name"
                            isRequired
                            aria-invalid="true"
                            id="envName"
                            value={envName}
                            aria-describedby="envNameError"
                            aria-required="true"
                            validationState={nameError ? 'invalid' : undefined}
                            onChange={handleNameTyped}
                            placeholder="Enter a name unique to this application"
                            width="100%"
                            errorMessage={errorMessage}
                            aria-errormessage={errorMessage}
                          />
                        </Flex>
                      </Content>
                      <ButtonGroup>
                        <Button onPress={close} variant="primary" data-launchid="Cancel env creation">
                          Cancel
                        </Button>
                        <Button
                          variant="cta"
                          isDisabled={!envName.length >= 1 || nameError}
                          onPress={() => saveEnv(close)}
                          data-launchid="Save environment"
                        >
                          Save environment
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </Dialog>
              )}
            </DialogTrigger>
            {!isAdmin && <NonAdminNote />}
          </Flex>
        )}
      </Flex>
      {!envLoading ? (
        <>
          {envList?.length === 0 ? (
            <EmptyStateContainer
              header="Your application is now approved!"
              content="Add a new environment to start installing your application."
            />
          ) : (
            <EnvListContainer envList={envList} app={app} orgId={orgId} />
          )}
        </>
      ) : (
        <>
          {envList?.length > 0 && (isDialogOpened || deletionInProgress) && (
            <EnvListContainer envList={envList} app={app} orgId={orgId} />
          )}
        </>
      )}
    </>
  );
};

export default EnvList;
