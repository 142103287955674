//@ts-check
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  View,
} from '@adobe/react-spectrum';
import React, { useMemo } from 'react';

import { useStaticData } from '@exchange-frontends/custom-hooks';
import { getProductNames } from '@exchange-frontends/utils';

import illustrationPath from '../../../../static/illustrations/Illu_ConsentFlow_320x180.png';

import { LINKS } from '../../../constants';

/** @type {import('.').DialogTitle} */
const titleMap = {
  beginInstallation: 'Additional installation steps required',
  applicationAcquired: 'Application acquired successfully',
};

/**
 * @typedef EcAcquiredAdminDialogProps
 * @property {string[]} [requiredProducts]
 * @property {string[]} [optionalProducts]
 * @property {import('.').DialogVariant} [dialogVariant]
 * @property {() => void} close
 */

/** @param {EcAcquiredAdminDialogProps} props */
const EcAcquiredAdminDialog = ({ close, requiredProducts, optionalProducts, dialogVariant = 'beginInstallation' }) => {
  const productLocs = useStaticData('hostApps');

  const mappedRequiredProducts = useMemo(
    () => getProductNames(requiredProducts, productLocs),
    [requiredProducts, productLocs]
  );

  const mappedOptionalProducts = useMemo(
    () => getProductNames(optionalProducts, productLocs),
    [optionalProducts, productLocs]
  );

  return (
    <Dialog>
      <Heading>{titleMap[dialogVariant]}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-200">
          <Text>
            This application has some extra steps to configure before app installation. This process must be completed
            by the system administrator of your organization&apos;s Adobe account.
          </Text>
          <View backgroundColor="static-white" padding="size-200">
            <Flex gap="size-200">
              <Image
                src={illustrationPath.startsWith('/') ? illustrationPath : `/${illustrationPath}`}
                alt="Consent flow"
              />
              <Text>
                You are about to be taken to a browser based workflow to approve the connection between this application
                and your Adobe organization account.
              </Text>
            </Flex>
          </View>
          {mappedRequiredProducts?.length > 0 && (
            <>
              <Text>
                Please make sure your organization has the following product(s) that the publisher selected as required
                for this application.
              </Text>
              <View backgroundColor="static-white" padding="size-200">
                <Heading level={4} margin={0}>
                  {mappedRequiredProducts.join(', ')}
                </Heading>
              </View>
            </>
          )}
          {mappedOptionalProducts?.length > 0 && (
            <>
              <Text>
                The below Adobe products are {mappedRequiredProducts?.length ? 'also ' : ''}supported by this
                application, but it doesn&apos;t require them to work.
              </Text>
              <View backgroundColor="static-white" padding="size-200">
                <Heading level={4} margin={0}>
                  {mappedOptionalProducts.join(', ')}
                </Heading>
              </View>
            </>
          )}
          <Text>
            After access has been granted by the system administrator, you will be able to proceed with the installation
            as normal.
          </Text>
          <Text>
            <Link>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.SP.VIEW_DOCS}>
                Read more
              </a>
            </Link>{' '}
            about accessing and managing service integration applications.
          </Text>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={close}>
          Cancel
        </Button>
        {/* @TODO: redirect to IMS browser based workflow */}
        <Button variant="cta" onPress={close}>
          Continue
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default EcAcquiredAdminDialog;
