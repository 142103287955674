//@ts-check
import { ActionButton, Flex, Heading, Link, Text } from '@adobe/react-spectrum';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import React from 'react';

import useConsent from '../../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEcAppDetails from '../../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { useStore } from '../../../../../store';
import { TEST_IDS } from '../IntegrationDetails';
import ScopeDescriptionsTable from './ScopeDescriptionsTable';
import ScopeServicesTable from './ScopeServicesTable';
import TechAccountSection from './TechAccountSection';

const AccessDetailsTab = () => {
  const orgId = useStore((state) => state.selectedOrg?.code);
  const appDetails = useEcAppDetails();
  const consentDetails = useConsent();

  const techAccountId = consentDetails.data?.consent?.technicalAccountId;
  const scopeServices = appDetails.data?.sdks;
  const scopeDescriptions = appDetails.data?.scopeDescription;

  return (
    <Flex data-testid={TEST_IDS.ACCESS_DETAILS_TAB} gap="size-500" direction="column">
      <Flex wrap="wrap-reverse" width="100%" justifyContent="space-between" alignItems="center">
        <Heading>Access details</Heading>
        <ActionButton
          onPress={() => {
            window.open(
              `${process.env.ADMIN_CONSOLE}/${orgId}/insights/logs/auditLogsCal/view`,
              '_blank',
              'noopener noreferrer'
            );
          }}
        >
          <LinkOutLight size="S" />
          <Text>View audit logs on Admin Console</Text>
        </ActionButton>
      </Flex>
      {techAccountId && <TechAccountSection techAccountId={techAccountId} />}
      {(scopeServices?.length > 0 || scopeDescriptions?.length > 0) && (
        <Flex direction="column" gap="size-300" data-testid={TEST_IDS.SCOPES_SECTION}>
          <Flex direction="column" gap="size-100">
            <strong>Scopes with access granted</strong>
            <Flex direction="column">
              <Text>
                This is a list of scopes that customers have allowed your application access from their Adobe
                organization account on their behalf.
              </Text>
              <Link>
                {/* @TODO: Update the href value to the actual documentation link */}
                <a href="#" target="_blank" rel="noopener noreferrer">
                  View scope documentation
                </a>
              </Link>
            </Flex>
          </Flex>
          {scopeServices?.length > 0 && <ScopeServicesTable scopeServices={scopeServices} />}
          {scopeDescriptions?.length > 0 && <ScopeDescriptionsTable scopeDescriptions={scopeDescriptions} />}
        </Flex>
      )}
    </Flex>
  );
};

export default AccessDetailsTab;
