import { ActionButton, Divider, Flex, Heading, ProgressCircle, Text, View } from '@adobe/react-spectrum';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import Alert from '@spectrum-icons/workflow/Alert';
import Copy from '@spectrum-icons/workflow/Copy';
import '@spectrum-web-components/table/sp-table-body.js';
import '@spectrum-web-components/table/sp-table-cell.js';
import '@spectrum-web-components/table/sp-table-checkbox-cell.js';
import '@spectrum-web-components/table/sp-table-head-cell.js';
import '@spectrum-web-components/table/sp-table-head.js';
import '@spectrum-web-components/table/sp-table-row.js';
import '@spectrum-web-components/table/sp-table.js';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { handleApiCall, manageApi } from '@exchange-frontends/api';
import { CopyButton } from '@exchange-frontends/components';

import { useStore } from '../../../../../../../store';
import { ACTIVATION_STATUS } from '../../../../../../../utils/Constants';
import { UserDataContext } from '../../../../../../App';
import AppStatusLight from '../../../common/AppStatusLight';
import { NoInsightsContainer } from '../../../common/StyledComponents';

function formatTimestamp(timestamp) {
  const t = parseInt(timestamp);
  const date = new Date(t);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  const second = String(date.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
}

const AccordionItemHeader = ({ activation }) => {
  return (
    <Flex paddingStart="size-125" paddingEnd="size-300" width="100%">
      <sp-table-row style={{ gap: '5%' }}>
        <sp-table-cell style={{ wordWrap: 'break-word', maxWidth: '20%' }}>{activation?.activationId}</sp-table-cell>
        <sp-table-cell>{activation?.start ? formatTimestamp(activation.start) : '-'}</sp-table-cell>
        <sp-table-cell>{activation?.end ? formatTimestamp(activation.end) : '-'}</sp-table-cell>
        <sp-table-cell>
          <Flex justifyContent="space-between" alignItems="center">
            <AppStatusLight status={ACTIVATION_STATUS?.[activation?.status]} />
            {activation?.status === ACTIVATION_STATUS.FAILED ? <Alert size="XS" color="negative" /> : ''}
          </Flex>
        </sp-table-cell>
      </sp-table-row>
    </Flex>
  );
};

const DeploymentAccordionItem = styled(AccordionItem)`
  background: white;

  .spectrum-Accordion-itemHeader {
    text-transform: none;
  }
`;
export const ExecutionLogs = ({ orgId, appId, envId, currentDeployment, isEnvInstalled }) => {
  const fetchActivationLogs = useStore((state) => state.fetchActivationLogs);
  const [isLogsLoading, setIsLogsLoading] = useState(true);
  const { accessToken } = useContext(UserDataContext) ?? {};
  const [logString, setLogString] = useState('');
  const [logs, setLogs] = useState('');
  const [activations, setActivations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /** load activations */
  useEffect(() => {
    if (currentDeployment?.id) {
      let mounted = true;
      const deploymentId = currentDeployment.id;
      const handler = handleApiCall(manageApi.getActivations, { accessToken, orgId, appId, envId, deploymentId });
      (async () => {
        try {
          const activations = await handler.run();
          if (mounted) {
            setActivations(activations);
            setIsLoading(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted && err.name !== 'AbortError') {
            setIsLoading(false);
          }
        }
      })();
      return () => {
        mounted = false;
        handler.controller.abort();
      };
    } else {
      setActivations([]);
      setIsLoading(false);
    }
  }, [accessToken, appId, envId, orgId, currentDeployment?.id]);

  const fetchLogs = async (key) => {
    if (key || key === 0) {
      const currentItem = activations[key];
      const activationId = currentItem.activationId;
      if (activationId !== null) {
        setIsLogsLoading(true);
        fetchActivationLogs(accessToken, orgId, appId, envId, currentDeployment?.id, activationId)
          .then((response) => {
            setLogs(response.join(''));
            if (response.length === 0) {
              response = 'No logs available for this activation';
            } else {
              response = response.map((item) => <div key={item}>${item}</div>);
            }
            setLogString(response);
          })
          .finally(() => {
            setIsLogsLoading(false);
          });
      } else {
        setLogString(currentItem.logs);
        setIsLogsLoading(false);
      }
    }
  };

  return isLoading ? (
    <Flex alignSelf="center" justifyContent="center" alignItems="center" height="10%" width="100%" marginTop="52px">
      <ProgressCircle size="L" isIndeterminate aria-label="Loading…" />
    </Flex>
  ) : activations.length > 0 ? (
    <>
      <sp-table>
        <sp-table-head style={{ padding: '0 0 0 10px' }}>
          <sp-table-head-cell>activation ID</sp-table-head-cell>
          <sp-table-head-cell>Activation Start Date</sp-table-head-cell>
          <sp-table-head-cell>Activation End Date</sp-table-head-cell>
          <sp-table-head-cell style={{ padding: '10px 16px 10px 5%' }}>status</sp-table-head-cell>
        </sp-table-head>
      </sp-table>
      <Accordion
        aria-label="Default"
        ariaLevel={3}
        defaultSelectedIndex={undefined}
        multiselectable={false}
        onChange={fetchLogs}
      >
        {activations.map((activation) => (
          <DeploymentAccordionItem
            key={activation?.activationId}
            disabled={false}
            header={<AccordionItemHeader activation={activation} />}
            selected={false}
          >
            {' '}
            {isLogsLoading ? (
              <Flex justifyContent="center" alignItems="center" height="10%">
                <ProgressCircle size="L" isIndeterminate aria-label="Loading…" />
              </Flex>
            ) : (
              <View paddingStart="size-550" paddingEnd="size-300">
                <View backgroundColor="default" paddingX="size-100" paddingY="size-200" paddingBottom="size-200">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Heading level="4">Execution Logs</Heading>
                    <CopyButton text={logs}>
                      <ActionButton label="Copy" onPress={(e) => e.target.closest('div').click()}>
                        <Copy />
                        <Text>Copy logs</Text>
                      </ActionButton>
                    </CopyButton>
                  </Flex>
                  <Divider size="S" />
                  <div>{logString}</div>
                </View>
              </View>
            )}
          </DeploymentAccordionItem>
        ))}
      </Accordion>
    </>
  ) : (
    <NoInsightsContainer
      orgId={orgId}
      appId={appId}
      envId={envId}
      isEnvInstalled={isEnvInstalled}
      currentDeployment={currentDeployment}
    />
  );
};
