// SPECTRUM
import { Flex, Item, Picker, ProgressCircle, Text } from '@adobe/react-spectrum';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { handleApiCall, manageApi } from '@exchange-frontends/api';

import { getRuntimeTotals } from '../../../../../../../utils/InsightsHelper';
import { UserDataContext } from '../../../../../../App';
import { NoInsightsContainer } from '../../../common/StyledComponents';

const SectionHeader = styled.h3`
  margin: 0;
  text-align: left;
  font: normal normal bold 18px/22px adobe-clean;
  letter-spacing: 0;
  color: var(--spectrum-global-color-gray-900);
  opacity: 1;
`;

const SectionContent = styled.p`
  margin: 14px 0 0 0;
  text-align: left;
  font: normal normal 300 40px/51px adobe-clean;
  letter-spacing: 0;
  color: var(--spectrum-global-color-gray-900);
  opacity: 1;
`;

const HeadingSubtitle = styled.p`
  margin: 4px 0 0 0;
  text-align: left;
  font: normal normal bold 11px/14px adobe-clean;
  letter-spacing: 0.66px;
  color: var(--spectrum-global-color-gray-700);
  text-transform: uppercase;
  opacity: 1;
`;

const options = [
  {
    label: 'This week',
    value: 'thisWeek',
  },
  {
    label: 'Last week',
    value: 'lastWeek',
  },
  {
    label: 'This month',
    value: 'thisMonth',
  },
  {
    label: 'Last month',
    value: 'lastMonth',
  },
];

export const Insights = ({ orgId, appId, envId, isEnvInstalled, currentEnv, currentDeployment }) => {
  const { accessToken } = useContext(UserDataContext) ?? {};

  const [selectedPeriod, setSelectedPeriod] = useState('lastMonth');
  const [isLoading, setIsLoading] = useState(true);
  const [insights, setInsights] = useState(null);

  const currentRuntimeUsageTotal = insights?.runtimeUsageTotal[selectedPeriod] || 0;
  const currentRuntimeTotalActivations = insights?.runtimeActivationsTotal[selectedPeriod] || 0;
  const currentRuntimeTotalActivationsError = insights?.runtimeActivationsErrorTotal[selectedPeriod] || 0;

  // load insights
  useEffect(() => {
    if (currentDeployment?.id && appId && envId && accessToken) {
      let mounted = true;
      const deploymentId = currentDeployment.id;
      const handler = handleApiCall(manageApi.getInsights, { accessToken, orgId, appId, envId, deploymentId });
      (async () => {
        try {
          setIsLoading(true);
          const response = await handler.run();
          if (mounted) {
            if (response !== null) {
              setInsights({
                runtimeUsageTotal: {
                  lastMonth: getRuntimeTotals(response.runtimeUsageTotalsLastMonth),
                  thisMonth: getRuntimeTotals(response.runtimeUsageTotalsThisMonth),
                  lastWeek: getRuntimeTotals(response.runtimeUsageTotalsLastWeek),
                  thisWeek: getRuntimeTotals(response.runtimeUsageTotalsThisWeek),
                },
                runtimeActivationsTotal: {
                  lastMonth: getRuntimeTotals(response.runtimeActivationsTotalsLastMonth),
                  thisMonth: getRuntimeTotals(response.runtimeActivationsTotalsThisMonth),
                  lastWeek: getRuntimeTotals(response.runtimeActivationsTotalsLastWeek),
                  thisWeek: getRuntimeTotals(response.runtimeActivationsTotalsThisWeek),
                },
                runtimeActivationsErrorTotal: {
                  lastMonth: getRuntimeTotals(response.runtimeActivationsErrorsTotalsLastMonth),
                  thisMonth: getRuntimeTotals(response.runtimeActivationsErrorsTotalsThisMonth),
                  lastWeek: getRuntimeTotals(response.runtimeActivationsErrorsTotalsLastWeek),
                  thisWeek: getRuntimeTotals(response.runtimeActivationsErrorsTotalsThisWeek),
                },
              });
            }
            setIsLoading(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted) {
            setInsights(null);
            setIsLoading(false);
          }
        }
      })();
      return () => {
        mounted = false;
        handler.controller.abort();
      };
    } else {
      setInsights(null);
      setIsLoading(false);
    }
  }, [accessToken, appId, envId, orgId, currentDeployment?.id]);

  return isLoading ? (
    <Flex justifyContent="center" alignItems="center" height="10%" marginTop="52px">
      <ProgressCircle size="L" isIndeterminate aria-label="Loading…" />
    </Flex>
  ) : insights !== null && isEnvInstalled ? (
    <>
      <Flex justifyContent="space-between" alignItems="center" gap="24px" marginTop="24px">
        <Flex direction="column" gap="12px">
          <Text>ENVIRONMENT NAME</Text>
          <Text>{currentEnv?.name}</Text>
        </Flex>
        <Picker
          label="View by"
          labelPosition="side"
          items={options}
          defaultSelectedKey="lastMonth"
          onSelectionChange={(selected) => setSelectedPeriod(selected)}
        >
          {(item) => <Item key={item.value}>{item.label}</Item>}
        </Picker>
      </Flex>
      <Flex marginTop="24px" justifyContent="space-between">
        <Flex direction="column">
          <SectionHeader>Total Usage</SectionHeader>
          <SectionContent>{!isEnvInstalled ? '–' : parseFloat(currentRuntimeUsageTotal) / 1000}</SectionContent>
          <HeadingSubtitle>GB/SECOND</HeadingSubtitle>
        </Flex>
        <Flex direction="column">
          <SectionHeader>Total Activations</SectionHeader>
          <SectionContent>{!isEnvInstalled ? '–' : currentRuntimeTotalActivations}</SectionContent>
          <HeadingSubtitle>Activations</HeadingSubtitle>
        </Flex>
        <Flex direction="column">
          <SectionHeader>Total Errors Activations</SectionHeader>
          <SectionContent>{isLoading || !isEnvInstalled ? '–' : currentRuntimeTotalActivationsError}</SectionContent>
          <HeadingSubtitle>ACTIVATION ERRORS</HeadingSubtitle>
        </Flex>
      </Flex>
    </>
  ) : (
    <NoInsightsContainer
      title="No insights are available"
      orgId={orgId}
      appId={appId}
      envId={envId}
      isEnvInstalled={isEnvInstalled}
      currentDeployment={currentDeployment}
    />
  );
};
