//@ts-check
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const selectedStyles = css`
  cursor: default;
  pointer-events: none;
  background-color: var(--spectrum-gray-200);
`;

const defaultStyles = css`
  cursor: pointer;
  pointer-events: auto;
  background-color: transparent;
`;

export const SideNavLink = styled(Link)`
  padding: var(--spectrum-global-dimension-size-75) var(--spectrum-global-dimension-size-150);
  border-radius: var(--spectrum-alias-border-radius-regular);
  color: inherit;
  text-decoration: none;
  display: block;
  ${(props) => (props['aria-selected'] ? selectedStyles : defaultStyles)}
  :hover {
    background-color: var(--spectrum-gray-200);
  }
`;

export const SideNavList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: var(--spectrum-global-dimension-size-50);
`;

export const SideNavListItem = styled.li`
  flex-grow: 1;
`;
