import { Button, ProgressCircle, Text } from '@adobe/react-spectrum';
import p from 'prop-types';
import React, { useContext, useState } from 'react';

import { commonApi, handleApiCall } from '@exchange-frontends/api';
import { handleManageForLaunch } from '@exchange-frontends/utils';

import { UserDataContext } from '../../../App';

/**
 * Button used for CC apps on the Manage Card.
 * @returns
 */
const CCCardButton = (props) => {
  const { loading, app } = props;
  const { profile } = useContext(UserDataContext) ?? {};
  const [isFetchingLink, setIsFetchingLink] = useState(false);

  const onButtonClick = async () => {
    const route = 'manage';
    const action = 'view';
    const params = { pluginId: app.appNumber };

    handleManageForLaunch();

    const handler = handleApiCall(commonApi.getCreativeCloudLink, { route, action, params });
    try {
      setIsFetchingLink(true);
      const { deeplinkUrl } = await handler.run();
      window.location.href = `${deeplinkUrl}&guid=${profile?.userId}`;
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetchingLink(false);
    }
  };
  return (
    <Button variant="primary" onPress={onButtonClick} isDisabled={isFetchingLink || loading}>
      {isFetchingLink && <ProgressCircle size="S" aria-label="deeplink-progress" isIndeterminate marginEnd="5px" />}
      <Text data-launchid="Manage button">Manage</Text>
    </Button>
  );
};

CCCardButton.propTypes = {
  loading: p.bool.isRequired,
  app: p.object,
};

CCCardButton.defaultProps = {
  loading: false,
};

export default CCCardButton;
