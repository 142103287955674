//@ts-check
import { Flex, View } from '@adobe/react-spectrum';
import React from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { ROUTES } from '../../../../../../constants';
import { SideNavLink, SideNavList, SideNavListItem } from '../../../../../components/styled-components/SideNav';
import { TABS_DEFINITION } from '../../../../utils/TabsDefinition';

/**
 * @param {object} props
 * @param {{name: string, path: string}[]} props.routes
 * @param {string} props.selectedTab
 */
const SideNavigation = ({ routes, selectedTab }) => {
  const integrationDetailsPath = `${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATION_DETAILS.path}`;
  const routeParams = useParams();

  return (
    <View minWidth={{ base: '100%', S: 160, M: 200, L: 240 }}>
      <nav aria-label="App details side navigation">
        <SideNavList>
          {routes.map(({ path, name }) => (
            <SideNavListItem key={path}>
              <SideNavLink
                aria-selected={selectedTab === path}
                to={generatePath(integrationDetailsPath, { ...routeParams, tab: path })}
              >
                <Flex justifyContent={{ base: 'center', S: 'start' }}>{name}</Flex>
              </SideNavLink>
            </SideNavListItem>
          ))}
        </SideNavList>
      </nav>
    </View>
  );
};

export default SideNavigation;
