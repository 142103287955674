//@ts-check
import { Cell, Column, Row, TableBody, TableHeader, TableView } from '@adobe/react-spectrum';
import React from 'react';

import { LineClampWithPopup } from '../../../../../../components/LineClampWithPopup';

const scopeServicesColumns = [
  {
    label: 'Service',
    key: 'service',
  },
  {
    label: 'Scopes',
    key: 'scopes',
  },
];

/**
 * @param {object} props
 * @param {import('@action-types/ecApp').AppDetails['sdks']} props.scopeServices
 */
const ScopeServicesTable = ({ scopeServices }) => {
  return (
    <TableView overflowMode="wrap" aria-label="Service scopes">
      <TableHeader columns={scopeServicesColumns}>
        {(column) => <Column key={column.key}>{column.label}</Column>}
      </TableHeader>
      <TableBody items={scopeServices}>
        {(sdk) => (
          <Row key={sdk.sdkCode}>
            {(columKey) => (
              <Cell key={`${sdk.sdkCode}-${columKey}`}>
                <LineClampWithPopup lines={2}>
                  {sdk?.[scopeServicesColumns.find((column) => columKey === column.key)?.key]}
                </LineClampWithPopup>
              </Cell>
            )}
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};

export default ScopeServicesTable;
