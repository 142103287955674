import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Flex,
  Item,
  ListBox,
  TextField,
  View,
} from '@adobe/react-spectrum';
import ChevronLeft from '@spectrum-icons/workflow/ChevronLeft';
import Search from '@spectrum-icons/workflow/Search';
import React, { useState } from 'react';

import { useMediaQuery } from '@exchange-frontends/custom-hooks';

import { NavLink, NavLinkContent, NavLinkContentWrapper, NavLinkText, NavList, NavListItem } from './HeaderComponents';

/**
 * @description: Displays a list of organizations and allows the user to select one.
 * @param {object[]} orgs - List of organizations
 * @param {object} selectedOrg - The currently selected organization
 * @param {function} setSelectedOrg - Function to set the selected organization
 * @returns {JSX.Element}
 */
const OrgSwitcher = ({
  orgs,
  selectedOrg,
  setSelectedOrg,
  smallOrgSwitcherOpen = false,
  setSmallOrgSwitcherOpen = () => {},
}) => {
  // Limit the number of organizations displayed in the list
  const maxOrgsDisplayed = 8;
  const [searchTerm, setSearchTerm] = useState('');
  const [displayedOrgs, setDisplayedOrgs] = useState(orgs?.slice(0, maxOrgsDisplayed) || []);
  const maxWidth600 = useMediaQuery('(max-width:600px)');

  /**
   * @description: Filters the list of organizations based on the search term.
   *   The list is limited to the first <maxOrgsDisplayed> results.
   * @param {string} searchTerm
   * @returns {void}
   */
  const filterOrgs = (searchTerm) => {
    const filteredOrgs = searchTerm
      ? orgs.filter((org) => org.name.toLowerCase().includes(searchTerm.toLowerCase())).slice(0, maxOrgsDisplayed)
      : orgs.slice(0, maxOrgsDisplayed);
    setDisplayedOrgs(filteredOrgs);
    return filteredOrgs;
  };

  /**
   * @description: Handles the keyup event on the search field.
   *  If the enter key is pressed and there is only one result displayed, select that result.
   *  Otherwise, filter the list of organizations based on the search term.
   *  @param {object} e - The keyup event
   *  @param {function} close - Function to close the dialog
   *  @returns {void}
   */
  const handleOnKeyUp = (e, close) => {
    if (e.key === 'Enter') {
      // if enter is pressed and there is only one result displayed, select that result
      if (filterOrgs(searchTerm)?.length === 1) {
        setSelectedOrg(displayedOrgs[0]);
        setSearchTerm('');
        setDisplayedOrgs(filterOrgs(''));
        setSmallOrgSwitcherOpen(false);
        close();
      }
    } else {
      return filterOrgs(e.target.value);
    }
  };

  /**
   * @description: Handles the selection change event on the list of organizations.
   * Sets the selected organization and closes the dialog.
   * @param {object[]} selected - The selected organization
   * @param {function} close - Function to close the dialog
   * @returns {void}
   */
  const handleOnSelectionChange = (selected, close) => {
    setSelectedOrg(orgs?.find((org) => org.name === [...selected]?.[0]));
    setSearchTerm('');
    close();
    setSmallOrgSwitcherOpen(false);
    setDisplayedOrgs(filterOrgs(''));
  };

  /**
   * @description: Renders the organization switcher. At smaller screen sizes,
   * the switcher will be in the hamburger menu, so it displays differently.
   */
  return maxWidth600 ? (
    <NavList
      className={`nav-list-wrapper--main nav-list--slidein ${smallOrgSwitcherOpen ? ' nav-list--slidein--open' : ''}`}
    >
      <NavListItem>
        <View paddingStart={'size-100'}>
          <Flex direction="row" alignItems="center">
            <NavLink
              arial-label="Close organization switcher"
              href="/"
              className="nav-link--main"
              onClick={(e) => {
                e.preventDefault();
                setSmallOrgSwitcherOpen(!smallOrgSwitcherOpen);
              }}
            >
              <NavLinkContentWrapper>
                <NavLinkContent>
                  <NavLinkText style={{ marginLeft: '-20px' }}>
                    <ChevronLeft size="S" />
                    <span>
                      <strong>Selected organization: </strong>
                      {selectedOrg?.name}
                    </span>
                  </NavLinkText>
                </NavLinkContent>
              </NavLinkContentWrapper>
            </NavLink>
          </Flex>
        </View>
      </NavListItem>
      <NavListItem>
        <Flex width="100%" direction="column" gap="size-50">
          <View padding={'size-100'}>
            <TextField
              type="search"
              label={null}
              name="search-organizations"
              value={searchTerm}
              width="100%"
              placeholder="Search organizations"
              icon={<Search />}
              onChange={setSearchTerm}
              marginBottom="size-100"
              onKeyUp={(e) => handleOnKeyUp(e, () => {})}
            />
            {displayedOrgs?.length > 0 && (
              <ListBox
                items={displayedOrgs}
                aria-label="Organizations list"
                selectionMode="single"
                selectedKeys={[selectedOrg?.name]}
                onSelectionChange={(selected) => handleOnSelectionChange(selected, () => {})}
              >
                {(org) => <Item key={org.name}>{org.name}</Item>}
              </ListBox>
            )}
          </View>
        </Flex>
      </NavListItem>
    </NavList>
  ) : (
    <DialogTrigger hideArrow type="popover">
      <ActionButton aria-label="Organizations" isQuiet={true}>
        {selectedOrg?.name}
      </ActionButton>
      {(close) => (
        <Dialog>
          <Content margin="-32px">
            <Flex direction="column" gap="size-50">
              <TextField
                type="search"
                label={null}
                name="search-organizations"
                value={searchTerm}
                width="100%"
                placeholder="Search organizations"
                icon={<Search />}
                onChange={setSearchTerm}
                marginBottom="size-100"
                onKeyUp={(e) => handleOnKeyUp(e, close)}
              />
              {displayedOrgs?.length > 0 && (
                <ListBox
                  items={displayedOrgs}
                  aria-label="Organizations list"
                  selectionMode="single"
                  selectedKeys={[selectedOrg?.name]}
                  onSelectionChange={(selected) => handleOnSelectionChange(selected, close)}
                >
                  {(org) => <Item key={org.name}>{org.name}</Item>}
                </ListBox>
              )}
            </Flex>
          </Content>
        </Dialog>
      )}
    </DialogTrigger>
  );
};

export default OrgSwitcher;
