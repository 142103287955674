import { ActionButton, Flex, Link, Text, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import Copy from '@spectrum-icons/workflow/Copy';
import React, { useCallback, useContext } from 'react';

import { CopyButton } from '@exchange-frontends/components';

import { useStore } from '../../../../../store';
import { getExtensionDetailsList, getExtensionName } from '../../../../../utils/ECAppsExtensionsHelper';
import { ConfigContext } from '../../../../App';
import { CapitalizedText, ExtensionDetailsLink, SmallerText } from './StyledComponents';

const getEndpointWithEllipsis = (endpoint) => {
  if (endpoint.length <= 31) {
    return (
      <Link UNSAFE_style={{ pointerEvents: 'auto' }} href={endpoint}>
        {endpoint}
      </Link>
    );
  }
  const end = endpoint.slice(endpoint.length - 30);
  return (
    <Link UNSAFE_style={{ pointerEvents: 'auto' }}>
      <ExtensionDetailsLink href={endpoint} data-content-end={end}>
        {endpoint}
      </ExtensionDetailsLink>
    </Link>
  );
};

// Prepend all ExcShell endpoints with https://experience.adobe.com/?devMode=true#/custom-apps/?localDevUrl=
// See https://jira.corp.adobe.com/browse/XCHG-3611
const ProductEndpoints = ({ product }) => {
  const appBuilderExtensionPoints = useStore(
    useCallback((state) => {
      return state.appBuilderExtensionPoints;
    }, [])
  );
  const { excShellUrl } = useContext(ConfigContext) ?? '';
  const appBuilderExtensionName = getExtensionName(product.name, appBuilderExtensionPoints, excShellUrl);
  const endpointsArray = getExtensionDetailsList(product, appBuilderExtensionPoints, excShellUrl);

  return (
    <Flex key={product.name} direction="column" marginBottom="size-200" maxWidth="300px">
      <Text marginBottom="size-50">{appBuilderExtensionName}</Text>
      {endpointsArray.map((endpoint) => (
        <Flex key={product.label} gap="10px" alignItems="center">
          <SmallerText key={endpoint}>
            <CapitalizedText>{product.label}:</CapitalizedText>
            <TooltipTrigger>
              {getEndpointWithEllipsis(endpoint)}
              <Tooltip>{endpoint} </Tooltip>
            </TooltipTrigger>
          </SmallerText>
          <CopyButton text={endpoint}>
            <ActionButton
              label="Copy"
              UNSAFE_style={{ pointerEvents: 'auto' }}
              onPress={(e) => e.target.closest('div').click()}
            >
              <Copy />
              <Text>Copy</Text>
            </ActionButton>
          </CopyButton>
        </Flex>
      ))}
    </Flex>
  );
};

export default ProductEndpoints;
