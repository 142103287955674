import { TBody, TH, THead, TR, Table } from '@react/react-spectrum/Table';
import React from 'react';

import { ENV_STATUS } from '../../../../../../../utils/Constants';
import { formatDate } from '../../../../../../../utils/ECAppsExtensionsHelper';
import AppStatusLight from '../../../common/AppStatusLight';
import { AppDetailsTD } from '../../../common/StyledComponents';

const EnvDetailTable = ({ env }) => {
  return (
    <Table quiet style={{ width: '50%' }}>
      <THead>
        <TH>STATUS</TH>
        <TH>VERSION</TH>
        <TH>LAST MODIFIED</TH>
      </THead>
      <TBody>
        <TR>
          <AppDetailsTD style={{ paddingLeft: '0' }}>
            <AppStatusLight status={env?.status} />
          </AppDetailsTD>
          <AppDetailsTD>{env?.status === ENV_STATUS.DRAFT ? '-' : env?.deployedVersion || '-'}</AppDetailsTD>
          <AppDetailsTD>{env?.createdAt ? formatDate(env?.createdAt) : 'N/A'}</AppDetailsTD>
        </TR>
      </TBody>
    </Table>
  );
};

export default EnvDetailTable;
