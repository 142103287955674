//@ts-check
import { AlertDialog, DialogContainer, Flex, Text } from '@adobe/react-spectrum';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import { WORKFLOWS } from './IntegrationDetails';

const AccessGrantedDialog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workflow = searchParams.get('workflow');
  const consentData = useConsent();
  const status = consentData.data?.consent?.status;
  const isOpen = workflow === WORKFLOWS.CONSENTED && status === 'ALLOWED';

  const closeDialog = () => {
    searchParams.delete('workflow');
    setSearchParams(searchParams);
  };

  const onContinue = () => {
    searchParams.set('workflow', WORKFLOWS.PROFILES);
    setSearchParams(searchParams);
  };

  return (
    <DialogContainer onDismiss={closeDialog}>
      {isOpen && (
        <AlertDialog
          width="100%"
          maxWidth="600px"
          cancelLabel="Close"
          variant="confirmation"
          primaryActionLabel="Continue"
          title="Access granted successfully"
          onSecondaryAction={closeDialog}
          onPrimaryAction={onContinue}
        >
          <Flex direction="column" gap="size-250">
            <Text>
              Next steps are to add product profiles for your integration&apos;s service account to gain access to
              granular features.
            </Text>
            <Text>
              This step must be completed by the system administrator of your organization&apos;s Adobe account.
            </Text>
          </Flex>
        </AlertDialog>
      )}
    </DialogContainer>
  );
};

export default AccessGrantedDialog;
