import { Flex, ProgressCircle } from '@adobe/react-spectrum';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { commonApi, excApi, manageApi } from '@exchange-frontends/api';
import { AppCrashComponent, ErrorBoundary } from '@exchange-frontends/components';
import { useIMS } from '@exchange-frontends/custom-hooks';
// Shared libs
import { handleManageForLaunch, makeVersionString } from '@exchange-frontends/utils';

import useSpFeatureFlag from '../../../actions/utilApi/selectors/useSpFeatureFlag';
import { useStore } from '../store';
import { INIT_STATE } from '../store/apps';
import { INIT_STATE as INIT_STATE_ENVS } from '../store/envs';
import { TABS_DEFINITION } from '../utils/TabsDefinition';
import Header from './header/Header';
import { DefaultTab } from './tabs/DefaultTab';

// Styled Components
/**
 * main content container
 * @memberof AppsList
 */
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  max-width: 1062px;
  /* 800 + 24 + 24 = 848, therefore < 849 needs padding */
  @media screen and (max-width: 1110px) {
    padding: 0px 24px;
  }
`;

export const UserDataContext = React.createContext(null);
export const EnvironmentDataContext = React.createContext(null);
export const ConfigContext = React.createContext(null);

window.xchgVersions = {
  ...(window.xchgVersions ?? {}),
  manage: makeVersionString(),
};

const App = ({ config }) => {
  manageApi.settings.setEndpoint(config?.apiUrl ?? process.env.ENDPOINT);
  manageApi.settings.setApiKey(config?.apiKey ?? process.env.API_KEY);
  manageApi.envSetup.setEndpoint(config?.installEndpoint ?? process.env.INSTALLATION_SERVICE_URL);
  manageApi.envSetup.setApiKey(config?.rnrApiKey ?? process.env.REACT_APP_CLIENT_ID);
  manageApi.appRegistrySetup.setEndpoint(config?.appRegistryEndpoint ?? process.env.APP_REGISTRY_URL);
  manageApi.appRegistrySetup.setApiKey(config?.apiKey ?? process.env.API_KEY);
  commonApi.staticDataSettings.setEndpoint(config?.staticDataEndpoint ?? process.env.STATIC_DATA_ENDPOINT);
  commonApi.getCreativeCloudLinkSettings.setEndpoint(config?.apiUrl ?? process.env.ENDPOINT);
  commonApi.getCreativeCloudLinkSettings.setApiKey(config?.apiKey ?? process.env.API_KEY);
  excApi.settings.setEndpoint(config?.apiUrl ?? process.env.ENDPOINT);
  excApi.settings.setApiKey(config?.apiKey ?? process.env.API_KEY);
  const selectedOrg = useStore((state) => state.selectedOrg);
  const orgsFetchError = useStore((state) => state.orgsFetchError);
  const ccPlugins = useStore((state) => state.ccPlugins);
  const fetchECApps = useStore((state) => state.fetchECApps);
  const fetchCCPlugins = useStore((state) => state.fetchCCPlugins);
  const fetchEntitlements = useStore((state) => state.fetchEntitlements);
  const entitlements = useStore((state) => state.entitlements);
  const fetchAppBuilderExtPoints = useStore((state) => state.fetchAppBuilderExtensionPoints);
  const ims = useIMS();
  // useState Hooks
  const [userData, setUserData] = useState(null);
  const { isLoading } = useSpFeatureFlag();

  // useEffect Hooks
  useEffect(() => {
    let mounted = true;
    const getUserData = async () => {
      if (ims?.initialized && ims?.isSignedInUser()) {
        if (mounted) {
          setUserData({
            accessToken: ims?.getAccessToken()?.token,
            profile: await ims.getProfile(),
          });
        }
      }
    };
    getUserData();
    return () => {
      mounted = false;
      useStore.setState({
        ...INIT_STATE,
        ...INIT_STATE_ENVS,
      });
    };
  }, [ims]);

  useEffect(() => {
    if (userData?.accessToken && !entitlements) {
      fetchEntitlements(userData?.accessToken);
    }
  }, [userData?.accessToken, entitlements]);

  useEffect(() => {
    if (userData?.accessToken && ccPlugins === null && entitlements) {
      fetchCCPlugins(userData.accessToken, entitlements);
    }
  }, [ccPlugins, fetchCCPlugins, userData?.accessToken, entitlements]);

  useEffect(() => {
    fetchAppBuilderExtPoints();
  }, [fetchAppBuilderExtPoints]);

  useEffect(() => {
    if (userData?.accessToken && selectedOrg?.code) {
      fetchECApps(userData.accessToken, selectedOrg.code);
    }
  }, [userData?.accessToken, selectedOrg?.code]);

  return (
    <ErrorBoundary isError={orgsFetchError !== null} errorComponent={<AppCrashComponent appName="Manage" />}>
      {isLoading ? (
        <Flex justifyContent="center" flexGrow={1} alignItems="center">
          <ProgressCircle aria-label="Loading routes" isIndeterminate size="L" />
        </Flex>
      ) : (
        <UserDataContext.Provider value={userData}>
          <ConfigContext.Provider value={config}>
            <Flex
              gap={{ base: 'size-300', S: 'size-500' }}
              width="100%"
              flexGrow={1}
              direction="column"
              alignItems="center"
            >
              <Header />
              <BodyContainer onClickCapture={handleManageForLaunch}>
                <Routes>
                  {Object.values(TABS_DEFINITION)
                    .filter((route) => !route.getIsEnabled || route.getIsEnabled())
                    .map(({ path, key, Element }) => (
                      <Route key={`route-${key}`} path={path} element={<Element />} />
                    ))}
                  <Route index element={<DefaultTab />} />
                  <Route path="*" element={404} />
                </Routes>
              </BodyContainer>
            </Flex>
          </ConfigContext.Provider>
        </UserDataContext.Provider>
      )}
    </ErrorBoundary>
  );
};

export default App;
