import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useUpdateConsent from '../../../../../../actions/entitlementApi/selectors/useUpdateConsent';
import { useStore } from '../../../../store';
import { WORKFLOWS } from './IntegrationDetails';

const useConsentBackupStrategy = () => {
  const routeParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const updateConsent = useUpdateConsent();
  const consent = useConsent();
  const [calledUpdateConsent, setCalledUpdateConsent] = useState(false);
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');

  const status = consent.data?.consent?.status;
  const workflow = searchParams.get('workflow');
  const notConsented = status && status !== 'ALLOWED';
  const shouldUpdateConsent =
    isAdmin && workflow === WORKFLOWS.CONSENTED && notConsented && !consent.isFetching && !calledUpdateConsent;
  const isUpdatingConsent = updateConsent.isLoading || shouldUpdateConsent;

  // Update consent status if the workflow is 'consented' and the status is not 'ALLOWED'.
  // This is a backup strategy in case the consent status is not updated when the user consents from the IMS browser based workflow.
  useEffect(() => {
    if (shouldUpdateConsent) {
      (async () => {
        try {
          setCalledUpdateConsent(true);
          await updateConsent.mutateAsync({
            appId: routeParams.appId,
            action: 'ALLOW',
            refetch: consent.refetch,
            showErrorToast: true,
          });
        } catch (error) {
          // Do nothing. The error will be handled by the useUpdateConsent hook.
        }
      })();
    }
  }, [consent.refetch, routeParams.appId, searchParams, setSearchParams, shouldUpdateConsent, updateConsent]);

  return { isUpdatingConsent, updateConsent };
};

export default useConsentBackupStrategy;
