import { Flex, Text } from '@adobe/react-spectrum';
import { useBreakpoint } from '@react-spectrum/utils';
import qs from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

import { ErrorBoundary, Pagination, SortDropdown } from '@exchange-frontends/components';
import { useAnalyticsPageLoad } from '@exchange-frontends/custom-hooks';
import { managePage } from '@exchange-frontends/utils';

import { ROUTES } from '../../../../../constants';
import { useStore } from '../../../store';
import { NotFoundMessage } from '../../common/NotFoundMessage';
import Card from './ManageCard';
import CCCardButton from './buttons/CCCardButton';

export const ITEMS_PER_PAGE = 50;

const defaultSortType = 'mostrecent';
const stringAzCompareFunction = (property) => (a, b) => {
  if (!a || (!a[property] && b[property])) {
    return 1;
  }
  if (!b || (a[property] && !b[property])) {
    return -1;
  }

  const nameA = a[property]?.toUpperCase() ?? '';
  const nameB = b[property]?.toUpperCase() ?? '';

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
const sortTypeFunctions = {
  mostrecent: (a, b) =>
    (b?.lastModifiedDatetime ?? b?.acquiredDate ?? 0) - (a?.lastModifiedDatetime ?? a?.acquiredDate ?? 0) ?? 0,
  title: stringAzCompareFunction('title'),
  publisher: stringAzCompareFunction('publisherName'),
};
const ccPluginsSortOptions = [
  { key: 'mostrecent', name: 'Most recent' },
  { key: 'title', name: 'Title (A-Z)' },
  { key: 'publisher', name: 'Publisher (A-Z)' },
];

// Selectors
// These are here so that they are not re-declared on every render
const selectedOrgSelector = (state) => state.selectedOrg;
const ccPluginsLoadingSelector = (state) => state.ccPluginsLoading;
const removePluginsSelector = (state) => state.removePlugins;
const setInitialCCStateSelector = (state) => state.setInitialCCState;

const AppsList = () => {
  // useState Hooks
  const [sortType, setSortType] = useState(defaultSortType);
  const navigate = useNavigate();
  const location = useLocation();
  const entitlements = useStore((state) => state.entitlements);
  const removePlugins = useStore(removePluginsSelector);
  const setInitialCCState = useStore(setInitialCCStateSelector);

  const page = useMemo(() => Number(qs.parse(location.search).page ?? '1'), [location.search]);

  const { matchedBreakpoints } = useBreakpoint();

  // Custom Hooks
  const selectedOrg = useStore(selectedOrgSelector);
  const ccPluginsLoading = useStore(ccPluginsLoadingSelector);
  const loading = ccPluginsLoading;

  const apps = useStore(
    useCallback(
      (state) => {
        let apps = Array(4).fill(null);
        if (!state.ccPluginsLoading) {
          apps = state.ccPlugins;
        }
        return apps?.sort(sortTypeFunctions[sortType]);
      },
      [sortType]
    )
  );

  const onPageChange = (page) => {
    window.scrollTo(0, 0);
    navigate({
      search: qs.stringify(Object.assign({}, qs.parse(location.search), { page })),
    });
  };

  useEffect(() => {
    removePlugins();
    return () => {
      setInitialCCState();
    };
  }, [location.search, removePlugins, setInitialCCState]);

  useAnalyticsPageLoad(managePage, !loading, apps?.length);

  return (
    <>
      <Helmet>
        <title>{`Manage apps for ${selectedOrg?.name ?? ''} | Adobe Exchange`}</title>
        <meta name="robots" content="noodp, FOLLOW, INDEX" />
        <meta name="title" content={`Manage apps for ${selectedOrg?.name ?? ''} | Adobe Exchange`} />
        <meta property="og:title" content="" />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="og:type" content="website" />
        <meta name="keywords" content="" />
      </Helmet>
      {selectedOrg && apps?.length ? (
        <Flex width="100%" justifyContent="space-between" alignItems="center" marginBottom="size-200">
          <Text data-launchid="total-results">
            {loading ? (
              `Loading plugins`
            ) : (
              <>
                {apps?.length} {'plugin'}
                {apps?.length > 1 ? 's' : ''}
              </>
            )}{' '}
          </Text>
          <SortDropdown
            defaultSelectedKey={defaultSortType}
            onSelectionChange={setSortType}
            customItems={ccPluginsSortOptions}
            id="manage-sort"
          />
        </Flex>
      ) : null}
      <Flex alignItems="center" direction="column">
        {/*render cc plugins*/}
        {apps?.map((app, idx) => (
          <ErrorBoundary
            key={app?.id ?? idx}
            errorMessage={`We've encountered an error trying to display the app with id ${app?.id ?? 'undefined'}`}
          >
            {() => (
              <Card
                data-testid={`app-card-${app?.id ?? idx}`}
                loading={loading}
                key={app?.id ?? app?.appNumber ?? idx}
                app={app}
                roundedBottom={apps.length === 1 || idx == apps.length - 1}
                roundedTop={idx === 0}
                customButton={() => <CCCardButton app={app} loading={loading} data-launchid="Manage button" />}
                hideButton={!selectedOrg?.role === 'ADMIN'}
                titleLink={`/apps/cc/${app?.id ?? app?.appNumber}`}
              />
            )}
          </ErrorBoundary>
        ))}
        {entitlements?.length > ITEMS_PER_PAGE && !ccPluginsLoading && (
          <Pagination
            enablePageNumbers={matchedBreakpoints.includes('S') ? true : false}
            defaultPage={page}
            totalPages={Math.ceil(entitlements?.length / ITEMS_PER_PAGE)}
            onChange={onPageChange}
          />
        )}
        {apps?.length === 0 ? (
          <NotFoundMessage
            headingProps={{ maxWidth: '600px' }}
            heading={['No Creative Cloud plugins to manage.', 'Looking for your acquired Creative Cloud plugin?']}
            contentProps={{ marginTop: 'size-150' }}
            content="You might have acquired your plugin using a different organization. Try signing out and then sign back in with the organization you used to acquire the plugin."
            link={{
              text: 'Explore thousands of Creative Cloud plugins',
              href: ROUTES.BROWSE + '/cc',
            }}
            linkProps={{ marginTop: 'size-150' }}
          />
        ) : null}
      </Flex>
    </>
  );
};

export default AppsList;
