// @ts-check
import { ActionButton, Content, Dialog, DialogTrigger, Text } from '@adobe/react-spectrum';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const TextContainer = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer */
  user-select: text; /* Standard */
  &&&& {
    + button > span {
      padding: 0;
      color: var(--spectrum-accent-color-1000);
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

/**
 * @typedef {Object} CustomProps
 * @property {number} lines - The number of lines to display.
 *
 *
 * Renders a component that truncates text with a button to show more.
 * @param {CustomProps & JSX.IntrinsicElements['span']} props - The component props.
 * @returns {JSX.Element} The LineClampWithButton component.
 */
export const LineClampWithPopup = ({ children, lines, style, ...others }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [textWidth, setTextWidth] = useState(0);
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;
    const lineHeight = parseInt(window.getComputedStyle(textElement).lineHeight, 10);
    const textHeight = textElement.scrollHeight;
    const textWidth = textElement.offsetWidth;
    const numberOfLines = textHeight / lineHeight;

    if (numberOfLines > lines) {
      setIsOverflowing(true);
      setTextWidth(textWidth);
    }
  }, [lines]);

  return (
    <>
      <TextContainer
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        style={{ WebkitLineClamp: lines, ...(style ?? {}) }}
        {...others}
        ref={textRef}
      >
        {children}
      </TextContainer>
      {isOverflowing && (
        <DialogTrigger type="popover">
          <ActionButton isQuiet height="18px">
            Show more
          </ActionButton>
          <Dialog marginY="-24px" marginX="-22px" minWidth={textWidth + 'px'}>
            <Content>
              <Text>{children}</Text>
            </Content>
          </Dialog>
        </DialogTrigger>
      )}
    </>
  );
};
