//@ts-check
import { Content, ContextualHelp, Flex, Heading, LabeledValue, Link, StatusLight, Text } from '@adobe/react-spectrum';
import User from '@spectrum-icons/workflow/User';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useStaticData } from '@exchange-frontends/custom-hooks';

import useActivityLog from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useActivityLog';
import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { formatDate } from '../../../../../../utils';
import IconLabeledValue from '../../../../../components/IconLabeledValue';
import { consentStatusMap } from '../IntegrationsList/integrationsTableColumns';
import IntegrationActivityLogTable from './IntegrationActivityLogTable';
import { TEST_IDS } from './IntegrationDetails';
import IntegrationHelperText from './IntegrationHelperText';
import NonAdminBanner from './NonAdminBanner';

/**
 * @param {string[]} [products]
 * @param {Map<string, { label: string }> | null} [productsMap]
 */
const mapAndSortProducts = (products, productsMap) =>
  products?.map((app) => productsMap?.get(app)?.label || app).sort((a, b) => a.localeCompare(b)) || [];

const IntegrationApplicationDetails = () => {
  const consent = useConsent();
  const appDetails = useEcAppDetails();

  const activityLog = useActivityLog();
  const latestActivityLog = activityLog[activityLog.length - 1];
  const status = consent.data?.consent?.status;
  const statusDetails = consentStatusMap[status];

  const productsMap = /** @type {Map<string, { label: string }> | null} */ (useStaticData('hostApps'));
  const requiredProducts = mapAndSortProducts(appDetails.data?.hostApps, productsMap);
  const optionalProducts = mapAndSortProducts(appDetails.data?.optionalProducts, productsMap);
  const hasRequiredProducts = requiredProducts?.length > 0;
  const hasOptionalProducts = optionalProducts?.length > 0;
  const hasProducts = hasRequiredProducts || hasOptionalProducts;

  const publisher = appDetails.data?.publisher;
  const description = appDetails.data?.description;

  return (
    <Flex direction="column" gap="size-500" data-testid={TEST_IDS.APP_DETAILS_SECTION}>
      <NonAdminBanner />
      <IntegrationHelperText />
      <Flex wrap justifyContent={{ base: 'space-between', S: 'start' }} gap={{ base: 'size-300', S: 'size-600' }}>
        {statusDetails && (
          <IconLabeledValue label="Status" testId={TEST_IDS.APP_HEADER_STATUS}>
            <Flex alignItems="center" gap="size-25">
              <StatusLight marginStart="-12px" variant={statusDetails.variant}>
                {statusDetails.label}
              </StatusLight>
              {status === 'REVOKED' && (
                <ContextualHelp marginTop="-4px" variant="info">
                  <Heading>Access has been revoked</Heading>
                  <Content>
                    <Text>
                      This application can no longer access Adobe APIs on your organization&apos;s behalf. Your current
                      configurations and historical data should remain unaffected. Click &apos;Begin installation&apos;
                      if you want to allow access to this application again.
                    </Text>
                  </Content>
                </ContextualHelp>
              )}
            </Flex>
          </IconLabeledValue>
        )}
        {latestActivityLog && (
          <>
            <IconLabeledValue
              testId={TEST_IDS.APP_HEADER_EMAIL}
              label={`${latestActivityLog.action} by`}
              icon={<User size="S" />}
            >
              <Link>
                <a href={`mailto:${latestActivityLog.user}`}>{latestActivityLog.user}</a>
              </Link>
            </IconLabeledValue>
            <IconLabeledValue
              testId={TEST_IDS.APP_HEADER_DATE}
              label={`Date ${
                (latestActivityLog.key === 'revoked' && latestActivityLog.key) || latestActivityLog.action.toLowerCase()
              }`}
            >
              {formatDate(latestActivityLog.date)}
            </IconLabeledValue>
          </>
        )}
      </Flex>
      <Flex direction="column" gap="size-300">
        <Heading margin={0} level={3}>
          Application details
        </Heading>
        {hasProducts && (
          <Flex direction="column" gap="size-100">
            <LabeledValue label="Products" value="" />
            <Flex gap="size-300">
              {hasRequiredProducts && (
                <Flex data-testid={TEST_IDS.REQUIRED_PRODUCTS} flex={1} direction="column" gap="size-100">
                  <strong>Required</strong>
                  <span>{requiredProducts?.join(', ')}</span>
                </Flex>
              )}
              {hasOptionalProducts && (
                <Flex data-testid={TEST_IDS.OPTIONAL_PRODUCTS} flex={1} direction="column" gap="size-100">
                  <strong>Optional</strong>
                  <span>{optionalProducts?.join(', ')}</span>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
        {publisher && (
          <IconLabeledValue testId={TEST_IDS.PUBLISHER_NAME} label="Publisher">
            <Link>
              <RouterLink to={`/publisher/ec/${publisher.id}`}>{publisher.name}</RouterLink>
            </Link>
          </IconLabeledValue>
        )}
        {description && (
          <IconLabeledValue testId={TEST_IDS.DESCRIPTION} label="Description">
            {description}
          </IconLabeledValue>
        )}
      </Flex>
      <IntegrationActivityLogTable activityLog={activityLog} />
    </Flex>
  );
};

export default IntegrationApplicationDetails;
