import { Button, Flex, Text } from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import Info from '@spectrum-icons/workflow/Info';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from './constants';
import { getStaticData } from './utils';

const Container = styled.div`
  width: 100%;
  min-height: 48px;
  background-color: var(${(props) => props.$bgColor});
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--spectrum-global-color-static-gray-50);
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const icons = {
  info: Info,
  warning: Alert,
  error: Alert,
  success: CheckmarkCircle,
};

const colors = {
  info: '--spectrum-global-color-static-blue-600',
  warning: '--spectrum-global-color-static-orange-600',
  error: '--spectrum-global-color-static-red-600',
  success: '--spectrum-global-color-static-green-600',
};

/**
 * This function will return the route that matches the pathname.
 * @param {string} pathname The current pathname
 * @returns {string} The route name
 */
const pathToRoute = (pathname = '') => {
  const matchedRoutes = Object.entries(ROUTES)
    .filter(([_key, value]) => pathname.match(value) !== null)
    .sort((a, b) => b[1].length - a[1].length);
  return matchedRoutes[0]?.[0];
};

const TopBanner = () => {
  const location = useLocation();
  const [bannerData, setBannerData] = useState(null);
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const Icon = icons[bannerData?.type];
  const color = colors[bannerData?.type];

  useEffect(() => {
    let mounted = true;
    getStaticData('notificationBanner').then((response) => {
      if (mounted) {
        let foundBannerForPage = false;
        const matchedRoute = pathToRoute(location.pathname);
        if (matchedRoute) {
          const data = response?.get(matchedRoute);
          if (data) {
            const now = new Date();
            const start = new Date(data.schedule.start);
            const end = data.schedule.end ? new Date(data.schedule.end) : new Date();
            if (now >= start && now <= end) {
              foundBannerForPage = true;
              setBannerData(data);
              setShouldShowBanner(true);
            }
          }
        }
        if (!foundBannerForPage) {
          setShouldShowBanner(false);
          setBannerData(null);
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [location.pathname]);

  return shouldShowBanner ? (
    <Flex gap="size-50" direction="column">
      <Container $bgColor={color}>
        <ContentContainer>
          <TextContainer>
            <Icon size="S" flexShrink={0} />
            <Text flexGrow={1} marginX="size-125">
              {bannerData?.text}
            </Text>
          </TextContainer>
          {bannerData?.link && (
            <Button
              flexShrink={0}
              variant="overBackground"
              isQuiet
              justifySelf="end"
              elementType="a"
              href={bannerData?.link}
              target="_blank"
            >
              Learn more
            </Button>
          )}
        </ContentContainer>
      </Container>
    </Flex>
  ) : null;
};

export default TopBanner;
