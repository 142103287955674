//@ts-check
import { Cell, Column, Link, Row, TableBody, TableHeader, TableView, Text } from '@adobe/react-spectrum';
import React from 'react';

import { formatDate } from '../../../../../../utils';
import WrappedText from '../../../../../components/styled-components/WrappedText';

/** @type {import('./types/activityLogTable').ActivityLogTableColumn[]} */
const columns = [
  {
    key: 'action',
    title: 'Action',
    CellContent: ({ data: { action } }) => <Text>{action}</Text>,
  },
  {
    key: 'date',
    title: 'Date',
    CellContent: ({ data: { date } }) => <Text>{formatDate(date, true)}</Text>,
  },
  {
    key: 'user',
    title: 'User',
    CellContent: ({ data: { user } }) => (
      <Link>
        <a href={`mailto:${user}`} target="_blank" rel="noopener noreferrer">
          {user}
        </a>
      </Link>
    ),
  },
];

/**
 * @param {object} props
 * @param {ReturnType<import('../../../../../../actions/entitlementApi/selectors/ecAppDetails/useActivityLog')['default']>} props.activityLog
 */
const IntegrationActivityLogTable = ({ activityLog }) => (
  <TableView overflowMode="wrap" aria-label="Activity log table">
    <TableHeader columns={columns}>{(column) => <Column key={column.key}>{column.title}</Column>}</TableHeader>
    <TableBody items={activityLog}>
      {(row) => (
        <Row key={row.key}>
          {(columnKey) => {
            const { CellContent } = columns.find((column) => column.key === columnKey);
            return (
              <Cell key={`${columnKey}-${row.key}`}>
                <WrappedText>
                  <CellContent data={row} />
                </WrappedText>
              </Cell>
            );
          }}
        </Row>
      )}
    </TableBody>
  </TableView>
);

export default IntegrationActivityLogTable;
