import { DialogTrigger, Flex, Image, Link, LogicButton, View } from '@adobe/react-spectrum';
import { Card } from '@react/react-spectrum/Card';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

// Shared libs
import { WithSkeleton } from '@exchange-frontends/components';
import { useMediaQuery, useRootHref } from '@exchange-frontends/custom-hooks';

import { SAP_CODE_TO_ICON_ID } from '../../../utils/Constants';
import DebugMode, { dateFormatter } from '../../DebugMode';
import InstallInstructionsDialog from './InstallInstructionsDialog';

/**
 * react-spectrum Card overriden with custom styles
 * @memberof ManageCard
 * @type {styled-component}
 * @property {boolean} [$roundedBottom=true] - (un)set the bottom corners rounded
 * @property {boolean} [$roundedTop=true] - (un)set the top corners rounded
 */
const StyledCard = styled(Card)`
  width: 100%;
  justify-content: space-between;
  border-bottom-left-radius: ${({ $roundedBottom }) => ($roundedBottom ? '4px' : '0px')};
  border-bottom-right-radius: ${({ $roundedBottom }) => ($roundedBottom ? '4px' : '0px')};
  border-top-left-radius: ${({ $roundedTop }) => ($roundedTop ? '4px' : '0px')};
  border-top-right-radius: ${({ $roundedTop }) => ($roundedTop ? '4px' : '0px')};
`;

/**
 * custom CardBody based on the react-spectrum one
 * @memberof ManageCard
 * @type {styled-component}
 * @property {boolean} [$isMobileSized=false] - if or not the element is displayed on a mobile sized screen
 */
const CardBody = styled.div`
  display: flex;
  flex-direction: ${({ $isMobileSized }) => ($isMobileSized ? 'column' : 'row')};
  justify-content: space-between;
  align-items: ${({ $isMobileSized }) => ($isMobileSized ? 'stretch' : 'center')};
  height: 100%;
  padding: 16px;
`;

/**
 * container with padding and a fixed size for images
 * @memberof ManageCard
 * @type {styled-component}
 */
export const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: var(--spectrum-global-dimension-size-125);
  & img {
    border-radius: 4px;
  }
`;

/**
 * styled title element
 * @memberof ManageCard
 * @type {styled-component}
 */
const TitleElement = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: var(--spectrum-global-color-static-gray-900);
  word-wrap: anywhere;
`;

/**
 * styled company element
 * @memberof ManageCard
 * @type {styled-component}
 */
const PublisherElement = styled.span`
  font-size: 12px;
  color: var(--spectrum-global-color-static-gray-600);
`;

/**
 * styled description element
 * @memberof ManageCard
 * @type {styled-component}
 */
const DescriptionElement = styled.span`
  font-size: 14px;
  color: var(--spectrum-global-color-static-gray-800);
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: anywhere;
`;

/**
 * purple badge for Firefly apps
 * @memberof ManageCard
 * @type {styled-component}
 */
export const Badge = styled.span`
  padding: 4px 10px;
  background-color: #5c5ce0;
  font-size: 12px;
  color: var(--spectrum-global-color-static-gray-50);
  border-radius: 4px;
`;

const SupportedProductIcon = styled.svg`
  width: 18px;
  height: 18px;
`;

/**
 * ManageCard component expands on the react-spectrum Card with additional styling
 * @component
 * @category Manage
 * @property {app} object - object representing the app
 * @property {boolean} [roundedBottom=true] - allows you to control if the bottom corners should be rounded or not
 * @property {boolean} [roundedTop=true] - allows you to control if the top corners should be rounded or not
 * @property {boolean} [hideButton] - if set to `true` it will not render the button
 * @property {function} [onButtonPress] - function to be called when pressing the button (not required is button is hidden)
 */
const ManageCard = (props) => {
  const {
    loading,
    app,
    roundedBottom = true,
    roundedTop = true,
    hideButton,
    customButton: CustomButton,
    titleLink,
    ...rest
  } = props;
  // Custom Hooks
  const isMobileSized = useMediaQuery('(max-width: 800px)');

  const rootTitleLink = useRootHref(titleLink ?? '');

  return (
    <StyledCard $roundedBottom={roundedBottom} $roundedTop={roundedTop} allowsSelection={false} {...rest}>
      <DebugMode>
        <Flex width="100%" gap="size-50" marginStart="size-50" marginTop="size-50">
          <LogicButton>appNumber: {app?.appNumber}</LogicButton>
          <LogicButton>acquiredDate: {dateFormatter.format(new Date(app?.acquiredDate ?? 0))}</LogicButton>
        </Flex>
      </DebugMode>
      <CardBody $isMobileSized={isMobileSized}>
        <Flex
          alignItems="center"
          flex="1"
          marginBottom={isMobileSized ? 'size-125' : '0'}
          maxWidth={isMobileSized ? '100%' : '40%'}
        >
          <ImageContainer>
            <WithSkeleton shouldRender={!loading} width="60px" height="60px">
              <Image alt="App icon" width="60px" height="60px" src={app?.iconUrl} />
            </WithSkeleton>
          </ImageContainer>
          <View marginEnd="auto">
            <TitleElement>
              <WithSkeleton shouldRender={!loading} width={200}>
                {titleLink ? (
                  <RouterLink style={{ color: 'inherit', textDecoration: 'none' }} to={rootTitleLink}>
                    {app?.title}
                  </RouterLink>
                ) : (
                  app?.title
                )}
              </WithSkeleton>
            </TitleElement>
            <PublisherElement>
              <WithSkeleton shouldRender={!loading} width={100}>
                {app?.publisherName && app.publisherName}
              </WithSkeleton>
            </PublisherElement>
            {app?.supportedProducts?.length && (
              <Flex marginTop="size-75" gap="size-50">
                {app.supportedProducts.map(({ app }) => (
                  <SupportedProductIcon key={app} viewBox="0 0 240 234">
                    <use xlinkHref={`#${SAP_CODE_TO_ICON_ID[app]}`}></use>
                  </SupportedProductIcon>
                ))}
              </Flex>
            )}
          </View>
        </Flex>
        <Flex
          direction="column"
          alignItems="start"
          flex="1"
          marginBottom={isMobileSized ? 'size-125' : '0'}
          maxWidth={isMobileSized ? '100%' : '40%'}
        >
          <DescriptionElement>
            <WithSkeleton shouldRender={!loading} width={200}>
              {app?.description}
            </WithSkeleton>
          </DescriptionElement>
          <WithSkeleton shouldRender={!loading} width={150}>
            {app?.jaeger && <Badge>Adobe Developer App Builder</Badge>}
          </WithSkeleton>
          <WithSkeleton shouldRender={!loading} width={110}>
            {app?.installationInstructions && (
              <DialogTrigger>
                <Link>Install instructions</Link>
                {(close) => (
                  <InstallInstructionsDialog
                    close={close}
                    title={app?.title}
                    icon={app?.iconUrl}
                    description={app?.description}
                    instructions={app?.installationInstructions}
                  />
                )}
              </DialogTrigger>
            )}
          </WithSkeleton>
        </Flex>
        {!hideButton && CustomButton && <CustomButton />}
      </CardBody>
    </StyledCard>
  );
};

export default ManageCard;
