// import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@exchange-frontends/custom-hooks';

import {
  Nav,
  NavFooter,
  NavLink,
  NavLinkContent,
  NavLinkContentWrapper,
  NavLinkText,
  NavList,
  NavListHeader,
  NavListItem,
  NavListWrapper,
  NavPopup,
  NavPopupTrigger,
  XCHGFooter,
  XCHGFooterWrapper,
} from './FooterComponents';

/**
 * @description Footer component
 * @returns {JSX.Element}
 */
const Footer = () => {
  const [footerPopupOpen, setFooterPopupOpen] = useState();
  const maxWidth1200 = useMediaQuery('(max-width:1200px)');
  const maxWidth899 = useMediaQuery('(max-width:899px)');

  /**
   * @description close the footer popup when the screen size is greater than 1200px
   * @dependency setFooterPopupOpen
   * @dependency maxWidth1200
   */
  useEffect(() => {
    if (!maxWidth1200) {
      setFooterPopupOpen(null);
    }
  }, [setFooterPopupOpen, maxWidth1200]);

  /**
   * @description array of footer routes
   */
  const mainRoutes = [
    {
      id: 'Globalnav.Adobe',
      text: 'Adobe',
      href: '#',
      daaLl: 'Adobe-1',
      links: [
        {
          id: 'Globalnav.Adobe.Product.Adobe_com',
          text: 'Adobe.com',
          href: 'https://www.adobe.com/?promoid=ZP46FD38&mv=other',
          daaLl: 'Adobe_com-1',
        },
        {
          id: 'Globalnav.Adobe.Product.About_Exchange',
          text: 'About Exchange',
          href: 'https://exchange.adobe.com/about',
          daaLl: 'About_Exchange-2',
        },
      ],
    },
    {
      id: 'Globalnav.Creative_Cloud_publishers',
      text: 'Creative Cloud publishers',
      href: '#',
      daaLl: 'Creative_Cloud_publishers-1',
      links: [
        {
          id: 'Globalnav.Creative_Cloud_publishers.Create_a_UXP_plugin_or_cloud_integration',
          text: 'Create a cloud integration',
          href: 'https://developer.adobe.com/console',
          daaLl: 'Create_a_cloud_integration-1',
        },
        {
          id: 'Globalnav.Creative_Cloud_publishers.Create_a_CEP_plugin',
          text: 'Create a UXP or CEP plugin',
          href: 'https://developer.adobe.com/developer-distribution/creative-cloud/',
          daaLl: 'Create_a_UXP_or_CEP_plugin-2',
        },
      ],
    },
    {
      id: 'Globalnav.Experience_Cloud_partners',
      text: 'Experience Cloud partners',
      href: '#',
      daaLl: 'Experience_Cloud_partners-1',
      links: [
        {
          id: 'Globalnav.Experience_Cloud_partners.Become_a_partner',
          text: 'Become a partner',
          href: 'https://partners.adobe.com/exchangeprogram/experiencecloud',
          daaLl: 'Become_a_partner-1',
        },
        {
          id: 'Globalnav.Experience_Cloud_partners.Learn_about_partner_benefits',
          text: 'Learn about partner benefits',
          href: 'https://partners.adobe.com/exchangeprogram/experiencecloud/ProgramBenefits.html',
          daaLl: 'Learn_about_partner_benefits-2',
        },
      ],
    },
    {
      id: 'Globalnav.Community',
      text: 'Community',
      href: '#',
      daaLl: 'Community-1',
      links: [
        {
          id: 'Globalnav.Community.Adobe_Exchange_Partner_Blog',
          text: 'Adobe Exchange Partner Blog',
          href: 'https://blog.adobe.com/',
          daaLl: 'Adobe_Exchange_Partner_Blog-1',
        },
        {
          id: 'Globalnav.Community.Sign_up_for_the_Partner_Newsletter',
          text: 'Sign up for the Partner Newsletter',
          href: 'https://www.adobe.com/subscription/ecexchange-newsletter.html',
          daaLl: 'iSign_up_for_the_Partner_Newsletter-2',
        },
      ],
    },
    {
      id: 'Globalnav.Support',
      text: 'Support',
      href: '#',
      daaLl: 'Support-1',
      links: [
        {
          id: 'Globalnav.Support.Troubleshooting_for_Creative_Cloud',
          text: 'Troubleshooting for Creative Cloud',
          href: 'https://helpx.adobe.com/creative-cloud/kb/troubleshoot-common-addon-installation-issues.html',
          daaLl: 'Troubleshooting_for_Creative_Cloud-1',
        },
      ],
    },
  ];

  /**
   * @description array of secondary footer routes
   */
  const footerRoutes = [
    {
      id: 'Globalnav.Copyright_Adobe_All_rights_reserved.Privacy',
      text: 'Privacy (Updated)',
      href: `https://www.${process.env.REACT_APP_ENVIRONMENT === 'stg1' ? 'stage.' : ''}adobe.com/privacy.html`,
      daaLl: 'Privacy-1',
      dataNavAction: 'none',
    },
    {
      id: 'Globalnav.Copyright_Adobe_All_rights_reserved.Terms_of_Use',
      text: 'Terms of Use',
      href: `https://www.${process.env.REACT_APP_ENVIRONMENT === 'stg1' ? 'stage.' : ''}adobe.com/legal/terms.html`,
      daaLl: 'Terms_of_Use-2',
      dataNavAction: 'none',
    },
    {
      id: 'Globalnav.Copyright_Adobe_All_rights_reserved.Cookie_preferences',
      text: 'Cookie preferences',
      href: '#',
      daaLl: 'Cookie_preferences-3',
      dataNavAction: 'open-adchoices-modal',
    },
    {
      id: 'Globalnav.Copyright_Adobe_All_rights_reserved.Do_not_sell_my_personal_information',
      text: 'Do not sell or share my personal information',
      href: `https://www.${
        process.env.REACT_APP_ENVIRONMENT === 'stg1' ? 'stage.' : ''
      }adobe.com/privacy/us-rights.html`,
      daaLl: 'Do_not_sell_my_personal_information-4',
      dataNavAction: 'none',
    },
  ];

  return (
    <XCHGFooterWrapper>
      <XCHGFooter>
        <NavFooter id="nav-footernav" daa-lh="gnav-exchange|exchange-gnav|footer">
          <Nav>
            <NavListWrapper className="nav-list-wrapper--main">
              <NavList>
                <NavListItem>
                  <NavListWrapper id="nav-list--main">
                    <NavList className="nav-list--section">
                      {mainRoutes.map((route) => {
                        return (
                          <NavListItem key={route.id}>
                            <NavListWrapper>
                              <NavList>
                                <NavListItem id={route.id}>
                                  <NavPopupTrigger
                                    onClick={() => {
                                      setFooterPopupOpen(footerPopupOpen === route.id ? null : route.id);
                                    }}
                                  >
                                    <NavLink
                                      href={route.href}
                                      className="navLink--hoverCaret"
                                      aria-haspopup={maxWidth1200 ? 'true' : undefined}
                                      aria-expanded={`${
                                        maxWidth1200 && footerPopupOpen === route.id ? 'true' : 'false'
                                      }`}
                                      data-nav-action="open-popup"
                                      data-nav-element="link"
                                      daa-ll={route.daaLl}
                                      role={`${maxWidth1200 ? 'button' : 'heading'}`}
                                      aria-level="2"
                                      tabIndex="-1"
                                    >
                                      <NavLinkContentWrapper>
                                        <NavLinkContent>
                                          <NavLinkText>
                                            <span>{route.text}</span>
                                          </NavLinkText>
                                        </NavLinkContent>
                                      </NavLinkContentWrapper>
                                    </NavLink>
                                    <NavPopup
                                      className={`${
                                        maxWidth1200 && footerPopupOpen === route.id ? 'nav-popup-open' : undefined
                                      }`}
                                    >
                                      <NavListWrapper>
                                        <NavList>
                                          {route.links.map((link) => {
                                            return (
                                              <NavListItem key={link.id} id={link.id}>
                                                <NavLink
                                                  href={link.href}
                                                  target="_blank"
                                                  data-nav-action="none"
                                                  data-nav-element="link"
                                                  daa-ll={link.daaLl}
                                                >
                                                  <NavLinkContentWrapper>
                                                    <NavLinkContent>
                                                      <NavLinkText>
                                                        <span>{link.text}</span>
                                                      </NavLinkText>
                                                    </NavLinkContent>
                                                  </NavLinkContentWrapper>
                                                </NavLink>
                                              </NavListItem>
                                            );
                                          })}
                                        </NavList>
                                      </NavListWrapper>
                                    </NavPopup>
                                  </NavPopupTrigger>
                                </NavListItem>
                              </NavList>
                            </NavListWrapper>
                          </NavListItem>
                        );
                      })}
                    </NavList>
                  </NavListWrapper>
                </NavListItem>
                <NavListItem className="nav-footer--footer">
                  <NavListWrapper>
                    <NavList>
                      <NavListItem>
                        <NavListWrapper>
                          <NavList>&nbsp;{/* empty nav list to align the footer footer right */}</NavList>
                        </NavListWrapper>
                      </NavListItem>
                      <NavListItem id="Globalnav.Copyright_Adobe_All_rights_reserved">
                        <NavListWrapper
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: `${!maxWidth899 ? 'no-wrap' : 'wrap'}`,
                          }}
                        >
                          <NavListHeader>{`Copyright © ${new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(
                            new Date()
                          )} Adobe. All rights reserved.`}</NavListHeader>
                          <NavList daa-lh="Copyright_Adobe_All_rights_reserved" className="nav-footer-footer--links">
                            {footerRoutes.map((route) => {
                              return (
                                <NavListItem key={route.id} id={route.id} className="nav-footer-footer--li">
                                  <NavLink
                                    href={route.href}
                                    data-nav-action={route.dataNavAction}
                                    data-nav-element="link"
                                  >
                                    <NavLinkContentWrapper>
                                      <NavLinkContent>
                                        <NavLinkText>
                                          <span>{route.text}</span>
                                        </NavLinkText>
                                      </NavLinkContent>
                                    </NavLinkContentWrapper>
                                  </NavLink>
                                </NavListItem>
                              );
                            })}
                            {/* This item gets special treatment due to the adchoices image */}
                            <NavListItem id="">
                              <NavLink
                                href={`https://www.${
                                  process.env.REACT_APP_ENVIRONMENT === 'stg1' ? 'stage.' : ''
                                }adobe.com/privacy/opt-out.html#interest-based-ads`}
                                target="_blank"
                                data-nav-action="none"
                                data-nav-element="link"
                                className="nav-footer-footer--adchoices"
                              >
                                <span className="nav-navLink-image">
                                  <img
                                    alt=""
                                    src={`https://www.${
                                      process.env.REACT_APP_ENVIRONMENT === 'stg1' ? 'stage.' : ''
                                    }adobe.com/content/dam/cc/icons/adchoices-small.svg`}
                                  />
                                </span>
                                <NavLinkContentWrapper>
                                  <NavLinkContent>
                                    <NavLinkText>
                                      <span>AdChoices</span>
                                    </NavLinkText>
                                  </NavLinkContent>
                                </NavLinkContentWrapper>
                              </NavLink>
                            </NavListItem>
                          </NavList>
                        </NavListWrapper>
                      </NavListItem>
                    </NavList>
                  </NavListWrapper>
                </NavListItem>
              </NavList>
            </NavListWrapper>
          </Nav>
        </NavFooter>
      </XCHGFooter>
    </XCHGFooterWrapper>
  );
};

export default Footer;
