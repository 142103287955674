import React from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useStore } from '../../store';
import { TABS_DEFINITION } from '../../utils/TabsDefinition';

const TabLink = styled(Link)`
  display: flex;
  height: 100%;
  min-height: 45px;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  padding: 0 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  text-align: center;
  font-weight: var(--spectrum-global-font-weight-regular);
  box-shadow: ${({ $isActive }) => ($isActive ? '0 3px 0px rgba(0, 0, 0, 0.8);' : 'none')};
`;

/** Tabs component to display the tabs in the header */
const Tabs = () => {
  const location = useLocation();
  const selectedOrg = useStore((state) => state.selectedOrg);
  const pathsMap = {
    [TABS_DEFINITION.APP_BUILDER.key]: [{ path: TABS_DEFINITION.APP_BUILDER_DETAILS.path }],
    [TABS_DEFINITION.INTEGRATIONS.key]: [{ path: TABS_DEFINITION.INTEGRATION_DETAILS.path }],
  };

  return (
    <>
      {Object.keys(TABS_DEFINITION).map((tabKey) => {
        const { key, path, computePath, shouldShow, launchId, tabLabel, getIsEnabled } = TABS_DEFINITION[tabKey];
        const isEnabled = getIsEnabled ? getIsEnabled() : true;
        const computedPath = computePath ? computePath(path, { selectedOrg }) : path;
        const pathname = location.pathname.split('/manage').pop();
        const paths = pathsMap[tabKey] ?? [];
        const isActive = matchRoutes([{ path }, ...paths], pathname);

        if (shouldShow && isEnabled) {
          return (
            <TabLink key={`tab-${key}`} to={computedPath} $isActive={isActive} data-launchid={launchId ?? tabLabel}>
              {tabLabel}
            </TabLink>
          );
        }
        return null;
      })}
    </>
  );
};

export default Tabs;
