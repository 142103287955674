import { Button, ButtonGroup, Content, Dialog, Divider, Flex, Heading, Link, Text } from '@adobe/react-spectrum';
import React from 'react';
import { generatePath, matchRoutes, useLocation } from 'react-router-dom';

import { LINKS, ROUTES } from '../../../constants';
import { TABS_DEFINITION } from '../../manage/utils/TabsDefinition';

/** @type {import('.').DialogTitle} */
const titleMap = {
  beginInstallation: 'Installation must be done by a system administrator',
  applicationAcquired: 'Application was successfully acquired',
};

/**
 * @typedef EcAcquiredNonAdminDialogProps
 * @property {import('.').DialogVariant} [dialogVariant]
 * @property {string} [appId]
 * @property {string} [orgId]
 * @property {() => void} close
 */

/** @param {EcAcquiredNonAdminDialogProps} props */
const EcAcquiredNonAdminDialog = ({ dialogVariant, orgId, appId, close }) => {
  const { pathname } = useLocation();
  const appDetailsPath = `${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATION_DETAILS.path}`;
  const isAppDetailsPage = matchRoutes([{ path: appDetailsPath }], pathname)?.length > 0;

  return (
    <Dialog>
      <Heading>{titleMap[dialogVariant]}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-200">
          <Text>
            This application has some extra steps to configure before installation. This process must be completed by
            the system administrator of your organization&apos;s Adobe account.
          </Text>
          <Text>
            A request has been added to the queue and a notification has gone out to the system administrator.
            {!isAppDetailsPage && appId && orgId && (
              <Text>
                {' '}
                You can check the status of installation{' '}
                <Link>
                  <a href={generatePath(appDetailsPath, { orgId, appId })}>here</a>
                </Link>
                .
              </Text>
            )}
          </Text>
          <Text>
            <Link>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.SP.VIEW_DOCS}>
                Read more
              </a>
            </Link>{' '}
            about accessing and managing service integration applications.
          </Text>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={close}>
          Close
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default EcAcquiredNonAdminDialog;
