//@ts-check
import { Breadcrumbs, Item } from '@adobe/react-spectrum';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { ROUTES } from '../../../../../../constants';
import { TABS_DEFINITION } from '../../../../utils/TabsDefinition';

const IntegrationBreadcrumbs = () => {
  const navigate = useNavigate();
  const appDetails = useEcAppDetails();

  const items = [
    {
      key: 'manage',
      label: 'Manage',
      onAction: () =>
        navigate(TABS_DEFINITION.INTEGRATIONS.computePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATIONS.path}`)),
    },
    { key: 'app-name', label: appDetails.data?.name },
  ];

  return (
    <Breadcrumbs
      showRoot
      aria-label="Integration details breadcrumbs"
      onAction={(key) => items.find((action) => action.key === key).onAction?.()}
      flexGrow={1}
    >
      {items.map(({ key, label }) => (
        <Item key={key}>{label}</Item>
      ))}
    </Breadcrumbs>
  );
};

export default IntegrationBreadcrumbs;
