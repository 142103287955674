.spectrum--lightest .spectrum-Accordion-item {
  border-color: rgb(234, 234, 234);
}
.spectrum--lightest .spectrum-Accordion-itemIndicator {
  color: rgb(149, 149, 149);
}
.spectrum--lightest .spectrum-Accordion-itemHeader {
  color: rgb(116, 116, 116);
}
.spectrum--lightest .spectrum-Accordion-itemHeader:hover {
    color: rgb(50, 50, 50);

    background-color: rgb(244, 244, 244);
  }
.spectrum--lightest .spectrum-Accordion-itemHeader:hover + .spectrum-Accordion-itemIndicator {
      color: rgb(80, 80, 80);
    }
.spectrum--lightest .spectrum-Accordion-itemHeader.focus-ring:after  {
      background-color: rgb(55, 142, 240);
    }
.spectrum--lightest .spectrum-Accordion-item.is-open .spectrum-Accordion-itemHeader:hover {
        background-color: transparent;
      }
.spectrum--lightest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader,
.spectrum--lightest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader:hover,
.spectrum--lightest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader.focus-ring {
      color: rgb(188, 188, 188);
      background-color: transparent;
    }
.spectrum--lightest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader + .spectrum-Accordion-itemIndicator {
      color: rgb(211, 211, 211);
    }
