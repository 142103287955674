.spectrum--darkest .spectrum-Accordion-item {
  border-color: rgb(57, 57, 57);
}
.spectrum--darkest .spectrum-Accordion-itemIndicator {
  color: rgb(124, 124, 124);
}
.spectrum--darkest .spectrum-Accordion-itemHeader {
  color: rgb(162, 162, 162);
}
.spectrum--darkest .spectrum-Accordion-itemHeader:hover {
    color: rgb(239, 239, 239);

    background-color: rgb(44, 44, 44);
  }
.spectrum--darkest .spectrum-Accordion-itemHeader:hover + .spectrum-Accordion-itemIndicator {
      color: rgb(200, 200, 200);
    }
.spectrum--darkest .spectrum-Accordion-itemHeader.focus-ring:after  {
      background-color: rgb(20, 115, 230);
    }
.spectrum--darkest .spectrum-Accordion-item.is-open .spectrum-Accordion-itemHeader:hover {
        background-color: transparent;
      }
.spectrum--darkest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader,
.spectrum--darkest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader:hover,
.spectrum--darkest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader.focus-ring {
      color: rgb(92, 92, 92);
      background-color: transparent;
    }
.spectrum--darkest .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader + .spectrum-Accordion-itemIndicator {
      color: rgb(73, 73, 73);
    }
