import { Button, ButtonGroup, Content, Dialog, Divider, Flex, Heading, Image, Text, View } from '@adobe/react-spectrum';
import React from 'react';
import styled from 'styled-components';

import { ImageContainer } from './ManageCard';

const DescriptionContainer = styled.div`
  color: var(--spectrum-global-color-static-gray-600);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const InstallInstructionsDialog = ({ title, icon, description, instructions, close }) => {
  return (
    <Dialog maxWidth="1360px" width="auto">
      <Heading>Installation Instructions</Heading>
      <Divider />
      <Content>
        <Flex width="100%">
          <Flex direction="column">
            <Flex alignItems="center">
              <ImageContainer>
                <Image alt="App icon" width="40px" height="40px" src={icon} />
              </ImageContainer>
              <View marginEnd="auto">
                <Heading margin="0">{title}</Heading>
                <DescriptionContainer>{description}</DescriptionContainer>
              </View>
            </Flex>
            <Text marginTop="size-100">
              <div>{instructions}</div>
            </Text>
          </Flex>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button onPress={close} variant="primary">
          OK
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default InstallInstructionsDialog;
