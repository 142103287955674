import { error, success } from '@react/react-spectrum/Toast';

import { handleApiCall, manageApi } from '@exchange-frontends/api';

import { APP_BUILDER_ENV_TYPE, DEPLOYMENT_STATUS } from '../utils/Constants';

export const INIT_STATE = Object.freeze({
  envList: null,
  envLoading: false,
  envError: null,
  deploymentsLoading: true,
  deploymentsLogsLoading: true,
  activationLogsLoading: true,
  deploymentsLogs: [],
  activationsLogs: [],
  deletionInProgress: false,
  isDeploying: false,
  currentDeploymentsId: [],
});

export const envsStoreSlice = (set, get) => ({
  ...INIT_STATE,

  setEnvStoreValue: (value) => set(value),

  //installation service endpoints
  createEnv: async (close, accessToken, orgId, appId, appVersion, envName, appName, appType = APP_BUILDER_ENV_TYPE) => {
    const handler = handleApiCall(manageApi.createEnv, {
      accessToken,
      appVersion,
      appId,
      orgId,
      envName,
      appName,
      appType,
    });
    try {
      set({ envLoading: true });
      const env = await handler.run();
      get().envList[appId].push(env);
      set({ envLoading: false });
      close();
      success('New environment created');
      return env;
    } catch (err) {
      error(err.userMessage, { timeout: 0 });
      console.error(err);
      set({ envLoading: false });
      close();
      return null;
    }
  },

  deleteEnv: async (accessToken, orgId, appId, envId) => {
    const handler = handleApiCall(manageApi.deleteEnv, {
      accessToken,
      appId,
      orgId,
      envId,
    });
    try {
      set({ envLoading: true, deletionInProgress: true });
      await handler.run();
      get().envList[appId] = get().envList[appId].filter((env) => env.id !== envId);
      set({ envLoading: false, deletionInProgress: false });
      success('The environment was deleted');
      return true;
    } catch (err) {
      error(err.userMessage, { timeout: 0 });
      console.error(err);
      set({ envLoading: false, deletionInProgress: false });
      return false;
    }
  },

  fetchDeploymentStatus: async (accessToken, orgId, envId, deploymentId) => {
    const handler = handleApiCall(manageApi.getDeploymentStatus, {
      accessToken,
      orgId,
      envId,
      deploymentId,
    });
    try {
      const { status } = await handler.run();
      if (![DEPLOYMENT_STATUS.IN_PROGRESS, DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS].includes(status)) {
        //update current deployment status
        const d = get().currentDeploymentsId;
        const index = d.findIndex((obj) => obj?.[envId]);
        if (index !== -1) {
          const currentDeployment = { ...d[index][envId], status: status };
          d[index] = { [envId]: currentDeployment };
        }
        set({ currentDeploymentsId: d, isDeploying: false });
      }
      return status;
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  deleteDeployment: async (accessToken, orgId, appId, envId, deploymentId) => {
    const handler = handleApiCall(manageApi.deleteDeployment, {
      accessToken,
      appId,
      orgId,
      envId,
      deploymentId,
    });
    try {
      const response = await handler.run();
      const d = get().currentDeploymentsId;
      const index = d.findIndex((obj) => obj?.[envId]);
      /** update current deployment status to reflect changes in the UI */
      if (index !== -1) {
        const currentDeployment = {
          ...d[index][envId],
          status: DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS,
        };
        d[index] = { [envId]: currentDeployment };
      }
      set({ currentDeploymentsId: d, isDeploying: true });
      return { ok: true, content: response };
    } catch (err) {
      console.error(err);
      error(err.userMessage, { timeout: 0 });
      set({ isDeploying: false });
      set({ deploymentsLogsLoading: false });
      return { ok: false, error: err.userMessage, content: [] };
    }
  },

  fetchDeploymentLogsById: async (accessToken, orgId, appId, envId, deploymentId, logId) => {
    const handler = handleApiCall(manageApi.getDeploymentLogsById, {
      accessToken,
      appId,
      orgId,
      envId,
      deploymentId,
      logId,
    });
    try {
      const logs = await handler.run();
      return { ok: true, content: logs };
    } catch (err) {
      console.error(err);
      return { ok: false, error: err.userMessage, content: 'No logs found' };
    }
  },

  fetchActivationLogs: async (accessToken, orgId, appId, envId, deploymentId, activationId) => {
    const handler = handleApiCall(manageApi.getActivationLogs, {
      accessToken,
      orgId,
      appId,
      envId,
      deploymentId,
      activationId,
    });
    try {
      const logs = await handler.run();
      set({ activationLogsLoading: false, activationsLogs: logs });

      return logs;
    } catch (err) {
      console.error(err);
      set({ activationLogsLoading: false });
      return [];
    }
  },

  createDeployment: async (
    accessToken,
    orgId,
    appId,
    envId,
    appVersion,
    eventProviders,
    productProfiles,
    envConfig
  ) => {
    const handler = handleApiCall(manageApi.createDeployment, {
      accessToken,
      appId,
      orgId,
      envId,
      appVersion,
      eventProviders,
      productProfiles,
      envConfig,
    });
    try {
      const deployment = await handler.run();
      const d = get().currentDeploymentsId;
      const index = d.findIndex((obj) => obj?.[envId]);
      if (index !== -1) {
        d[index] = { [envId]: deployment };
      } else {
        d.push({ [envId]: deployment });
      }
      set({ isDeploying: true, currentDeploymentsId: d });
      return { ok: true, content: deployment };
    } catch (err) {
      console.error(err);
      error(err.userMessage, { timeout: 0 });
      set({ isDeploying: false });
      return { ok: false, error: err.userMessage };
    }
  },
});
