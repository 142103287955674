import { ActionButton, Flex, Link, ProgressCircle, Text } from '@adobe/react-spectrum';
import { useBreakpoint } from '@react-spectrum/utils';
import Copy from '@spectrum-icons/workflow/Copy';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CopyButton, Pagination } from '@exchange-frontends/components';

import { useStore } from '../../../../../../store';
import { ENV_STATUS } from '../../../../../../utils/Constants';
import { useCurrentDeploymentFetch, useCurrentEnvFetch } from '../../../../../../utils/customHooks';
import { AppDetailsSection, NoInsightsContainer, SmallerText } from '../../common/StyledComponents';
import EnvDetailTable from './details/EnvDetailsTable';

const ITEMS_PER_PAGE = 10;

const EnvDetails = () => {
  /** endpoints pagination start */
  const { matchedBreakpoints } = useBreakpoint();
  const [page, setPage] = useState(1);
  const { appId, orgId, envId } = useParams();
  const [links, setLinks] = useState({});
  const isLoading = useStore((state) => state.deploymentsLoading);
  const deployingState = useStore((state) => state.isDeploying);
  const ecAppsLoading = useStore((state) => state.ecAppsLoading);
  const { currentDeployment } = useCurrentDeploymentFetch();
  const app = useStore(
    useCallback(
      (state) => {
        let apps = [];
        let app = null;
        if (!ecAppsLoading) {
          apps = state.allApps;
          app = apps.find((obj) => obj.id === appId) || null;
        }
        return app;
      },
      [appId, ecAppsLoading]
    )
  );

  /** move to app to load currentEnv details  and load data similar to apps*/
  const { currentEnv } = useCurrentEnvFetch();
  const isEnvInstalled = currentEnv?.status === ENV_STATUS.INSTALLED;
  useEffect(() => {
    if (currentDeployment && currentDeployment?.snapshot) {
      const links = JSON.parse(currentDeployment?.snapshot) || {};
      const endpointsArray = [];
      Object.keys(links).map((key) => {
        if (typeof links[key] === 'string' && links[key].length > 0) {
          endpointsArray.push(links[key]);
        } else if (links[key] instanceof Array) {
          links[key].map((endpoint) => {
            endpointsArray.push(endpoint);
          });
        }
      });
      const groupedProducts = {};
      for (let i = 0; i < Math.ceil(endpointsArray.length / ITEMS_PER_PAGE); i++) {
        const from = i * ITEMS_PER_PAGE;
        const to = Math.min((i + 1) * ITEMS_PER_PAGE, endpointsArray.length);
        groupedProducts[i + 1] = endpointsArray.slice(from, to);
      }
      setLinks(groupedProducts);
    }
  }, [currentDeployment, currentDeployment?.snapshot, deployingState]);

  return isLoading ? (
    <Flex justifyContent="center" alignItems="center" height="10%" marginTop="52px">
      <ProgressCircle size="L" isIndeterminate aria-label="Loading…" />
    </Flex>
  ) : (
    <>
      <EnvDetailTable env={currentEnv} />
      {!isEnvInstalled && (
        <NoInsightsContainer
          orgId={orgId}
          currentDeployment={currentDeployment}
          envId={envId}
          title="No details are available"
          appId={appId}
          isEnvInstalled={isEnvInstalled}
        />
      )}
      {isEnvInstalled && links && Object.keys(links).length > 0 && (
        <>
          <AppDetailsSection>Endpoints</AppDetailsSection>
          <Flex wrap="wrap" width="size-4600" marginStart="16px">
            <>
              {links[page]?.length > 0 &&
                links[page].map((endpoint) => (
                  <Flex key={endpoint} gap="10px" alignItems="center" marginBottom="24px">
                    <SmallerText key={endpoint}>
                      <Link UNSAFE_style={{ pointerEvents: 'auto' }} href={endpoint}>
                        {endpoint}
                      </Link>
                    </SmallerText>
                    <CopyButton text={endpoint}>
                      <ActionButton label="Copy" onPress={(e) => e.target.closest('div').click()}>
                        <Copy />
                        <Text>Copy</Text>
                      </ActionButton>
                    </CopyButton>
                  </Flex>
                ))}
              {Object.keys(links).length > 1 && (
                <Pagination
                  enablePageNumbers={matchedBreakpoints.includes('S')}
                  defaultPage={page}
                  totalPages={Object.keys(links).length}
                  onChange={setPage}
                />
              )}
            </>
          </Flex>
        </>
      )}
    </>
  );
};

export default EnvDetails;
