import React from 'react';
import styled from 'styled-components';

/**
 * @description Footer wrapper styled-component
 * @returns {JSX.Element}
 */
export const XCHGFooterWrapper = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  && * {
    box-sizing: border-box;
  }
`;

/**
 * @description Footer wrapper styled-component
 * @returns {JSX.Element}
 */
export const XCHGFooter = styled.div`
  -webkit-font-smoothing: antialiased;
  ul {
    list-style: none;
    list-style-type: none;
  }
`;

/**
 * @description footer component that accepts custom props like `daa-ll`
 * @param {Object} props
 * @returns {JSX.Element}
 */
const Footer = (props) => {
  return <footer {...props} />;
};

/**
 * @description Footer styled-component
 * @returns {JSX.Element}
 */
export const NavFooter = styled(Footer)`
  background-color: #fafafa;
  padding-top: 40px;
  padding-bottom: 20px;
  justify-content: center;
`;

/**
 * @description Nav styled-component
 * @returns {JSX.Element}
 */
export const Nav = styled.nav`
  align-items: inherit;
  display: flex;
  flex-direction: inherit;
  justify-content: inherit;
  min-height: inherit;
  width: 100%;
`;

/**
 * @description NavListWrapper styled-component
 * @returns {JSX.Element}
 */
export const NavListWrapper = styled.div`
  min-height: inherit;
  display: flex;
  line-height: 1;
  width: 100%;
  flex-direction: column;
  &.nav-list-wrapper--main {
    flex-direction: column;
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 16px;
  }
  &.nav-list--section {
    border-bottom: solid 1px #999999;
    flex-direction: column;
    @media (min-width: 1200px) {
      border-top: none;
      padding-right: 8px;
    }
    [role='group'] > div {
      flex-direction: column;
      padding-bottom: 16px;
      @media (min-width: 1200px) {
        padding-bottom: 0px;
      }
    }
  }
  .nav-footer--footer > div {
    margin-top: 12px;
    a span {
      font-size: 11px;
    }
    @media (min-width: 1200px) {
      margin-top: 12px;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

/**
 * @description NavList styled-component
 * @returns {JSX.Element}
 */
export const NavList = styled.ul`
  align-items: inherit;
  display: flex;
  flex-direction: inherit;
  flex-wrap: inherit;
  height: 100%;
  justify-content: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
  #nav-list--main {
    border-bottom: solid 1px #999999;
    @media (min-width: 1200px) {
      border-bottom: none;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      li {
        flex-grow: 1;
        border-top: none;
        padding-right: 8px;
      }
    }
  }
  &.nav-footer-footer--links li.nav-footer-footer--li {
    &:after {
      margin: 0 8px;
      color: #4b4b4b;
      font-size: 11px;
      content: '/';
      align-items: center;
      display: flex;
    }
  }
`;

/**
 * @description NavListHeader styled-component
 * @returns {JSX.Element}
 */
export const NavListHeader = styled.div`
  color: #4b4b4b;
  font-size: 11px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  &:after {
    content: '/';
    color: #4b4b4b;
    font-size: 11px;
    margin: 0 8px;
  }
`;

/**
 * @description NavListItem styled-component
 * @returns {JSX.Element}
 */
export const NavListItem = styled.li`
  display: flex;
  list-style: none;
  font-size: 14px;
  line-height: 1;
  margin: 0;
`;

/**
 * @description NavPopupTrigger styled-component
 * @returns {JSX.Element}
 */
export const NavPopupTrigger = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

/**
 * @description link component that accepts custom props like `daa-ll`
 * @param {Object} props
 * @returns {JSX.Element}
 */
const Link = (props) => {
  return <a {...props} />;
};

/**
 * @description NavLink styled-component extends Link
 * @returns {JSX.Element}
 */
export const NavLink = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: inherit;
  line-height: 1.4;
  text-decoration: none;
  transition: background-color 0.1s ease;
  width: 100%;
  padding-top: 4px;
  padding-left: 0px;
  padding-bottom: 4px;
  padding-right: 0px;
  &.navLink--hoverCaret {
    position: relative;
    pointer-events: none;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: solid 1px #999999;
    @media (min-width: 899px) {
      padding-right: 35px !important;
    }
    @media (min-width: 1200px) {
      border-top: none;
      padding-top: 0px;
      padding-bottom: 8px;
    }
    :after {
      border-color: #2c2c2c;
      border-style: solid;
      border-width: 0 1px 1px 0;
      content: '';
      display: flex;
      height: 6px;
      margin-top: -3px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: rotate(45deg);
      transform-origin: 75% 75%;
      transition: transform 0.1s ease;
      width: 6px;
      @media (min-width: 1200px) {
        display: none;
      }
    }
    &[aria-expanded='true']:after {
      transform: rotate(-135deg);
    }
    span {
      font-weight: 600;
      font-size: 15px;
    }
  }
  .nav-navLink-image {
    display: block;
    flex-shrink: 0;
    width: 25px;
    & > * {
      display: block;
      width: 100%;
    }
  }
  &.nav-footer-footer--adchoices :nth-child(2):before {
    content: '';
    margin-left: 4px;
  }
`;

/**
 * @description NavLinkContentWrapper styled-component
 * @returns {JSX.Element}
 */
export const NavLinkContentWrapper = styled.span`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  white-space: nowrap;
`;

/**
 * @description NavLinkContent styled-component
 * @returns {JSX.Element}
 */
export const NavLinkContent = styled.span`
  display: flex;
  flex-direction: column;
`;

/**
 * @description NavLinkText styled-component
 * @returns {JSX.Element}
 */
export const NavLinkText = styled.span`
  color: #4b4b4b;
  font-size: 12px;
  align-items: center;
  display: flex;
  font-weight: 400;
  transition: color 0.1s ease;
  :hover {
    color: #999999;
  }
  & > :first-child {
    display: block;
  }
`;

/**
 * @description NavLinkSubText styled-component
 * @param {Object} attributes
 * @returns {JSX.Element}
 */
export const NavPopup = styled.div.attrs(() => ({
  role: 'group',
}))`
  display: none;
  margin: 0;
  padding: 0;
  &.nav-popup-open {
    display: block;
  }
  > div {
    padding-bottom: 16px;
  }
  @media (min-width: 1200px) {
    display: block;
    > div {
      padding-bottom: 0px;
    }
  }
`;
