import { Flex, ProgressCircle } from '@adobe/react-spectrum';
import React, { useEffect, useState } from 'react';

import { useIMS } from '@exchange-frontends/custom-hooks';

const RequiresAuthorization = ({ Element }) => {
  const [shouldRenderChildren, setShouldRenderChildren] = useState(false);

  const ims = useIMS();

  useEffect(() => {
    if (!ims?.initialized) {
      setShouldRenderChildren(false);
      return;
    }
    if (ims?.isSignedInUser()) {
      setShouldRenderChildren(true);
    } else {
      ims?.signIn();
    }
  }, [ims]);

  return shouldRenderChildren ? (
    <Element />
  ) : (
    <Flex width="100%" justifyContent="center" alignItems="center" minHeight="100vh">
      <ProgressCircle isIndeterminate size="L" aria-label="Checking your authorization status" />
    </Flex>
  );
};

export default RequiresAuthorization;
