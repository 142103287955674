import React from 'react';

import { DynamicLengthListWithTooltip } from '@exchange-frontends/components';

import { getExtensionDetails } from '../../../../../utils/ECAppsExtensionsHelper';

const ExtensionDetails = ({ app, appBuilderExtensionPoints }) => {
  return (
    <div>
      <DynamicLengthListWithTooltip
        list={getExtensionDetails(app, appBuilderExtensionPoints)}
        amountShown={1}
        style={{
          textAlign: 'left',
          font: 'normal normal normal 12px/15px adobe-clean',
          letterSpacing: 'var(--spectrum-global-dimension-static-size-0)',
          color: 'var(--spectrum-global-color-gray-800)',
          opacity: '1',
        }}
      />
    </div>
  );
};
export default ExtensionDetails;
