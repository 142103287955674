/* istanbul ignore file */
import { Provider as ProviderV3, defaultTheme } from '@adobe/react-spectrum';
import Provider from '@react/react-spectrum/Provider';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';

import { CustomBrowserRouter } from '@exchange-frontends/components';

import App, { history } from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ProviderV3 theme={defaultTheme} colorScheme={`light`}>
      <Provider toastPlacement="bottom center" theme="light">
        <CustomBrowserRouter history={history}>
          <App key="container" />
        </CustomBrowserRouter>
      </Provider>
    </ProviderV3>
  </React.StrictMode>,
  document.getElementById('root')
);
