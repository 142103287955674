//@ts-check
import { useMemo } from 'react';

import { consentStatusMap } from '../../../../pages/manage/components/tabs/apps-list/IntegrationsList/integrationsTableColumns';
import useConsent from './useConsent';

const useActivityLog = () => {
  const { data } = useConsent();

  const activityLog = useMemo(() => {
    const status = data?.consent?.status;
    const logs = [];

    logs.push({
      key: 'acquired',
      date: data?.consent?.createdOn,
      user: data?.user?.find((user) => user?.guid === data?.consent?.createdBy)?.email,
      action: 'Acquired',
    });

    logs.push({
      key: 'reviewed',
      date: data?.consent?.reviewedOn,
      user: data?.user?.find((user) => user?.guid === data.consent?.reviewedBy)?.email,
      action: status === 'DENIED' ? consentStatusMap.DENIED.label : consentStatusMap.ALLOWED.label,
    });

    if (data?.consent?.revokedOn > data?.consent?.reviewedOn) {
      logs.push({
        key: 'revoked',
        date: data.consent.revokedOn,
        user: data.user?.find((user) => user?.guid === data.consent.revokedBy)?.email,
        action: 'Access revoked',
      });
    }

    return logs.filter(({ action, date, user }) => action && date && user).sort((a, b) => a.date - b.date);
  }, [data]);

  return activityLog;
};

export default useActivityLog;
