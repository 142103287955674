//@ts-check
import {
  ActionMenu,
  Button,
  Content,
  ContextualHelp,
  DialogContainer,
  DialogTrigger,
  Flex,
  Heading,
  Item,
  Text,
} from '@adobe/react-spectrum';
import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../../constants';
import EcAcquiredDialog from '../../../components/EcAcquiredDialog';
import RedText from '../../../components/styled-components/RedText';
import { useStore } from '../../store';
import { TABS_DEFINITION } from '../../utils/TabsDefinition';
import SpAdminRevokeDialog from './SpAdminRevokeDialog';
import SpNonAdminActionDialog from './SpNonAdminActionDialog';
import SpProductProfilesDialog from './SpProductProfilesDialog';

/** @param {import('../tabs/apps-list/IntegrationsList/types/integrationsTable').CellContentProps & {type? : 'details' | 'queue'}} props */
const IntegrationActionMenu = ({ data, type = 'queue' }) => {
  const shouldConsent = data.consent?.status !== 'ALLOWED';
  const navigate = useNavigate();
  const orgId = useStore((state) => state.selectedOrg?.code);
  const appId = data?.entitlement?.appId;
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');
  /** @type {ReturnType<typeof useState<'REVOKE' | 'SELECT_PROFILES' | undefined | null>>} */
  const [openedDialog, setOpenedDialog] = useState();

  const actions = [
    {
      label: 'View listing on Exchange',
      key: 'view-listing',
      onPress: () => navigate(`${ROUTES.DETAILS}/ec/${appId}`),
      isHidden: data.appDetails?.isPrivate || type === 'details',
    },
    {
      label: 'View access details',
      key: 'manage-details',
      isHidden: type === 'details',
      onPress: () =>
        navigate(generatePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATION_DETAILS.path}`, { orgId, appId })),
    },
    {
      label: (
        <Text>
          <RedText>Revoke access</RedText>
        </Text>
      ),
      textValue: 'Revoke access',
      key: 'revoke-access',
      onPress: () => setOpenedDialog('REVOKE'),
      isHidden: data.consent?.status !== 'ALLOWED',
    },
  ].filter((action) => !action.isHidden);

  return (
    <Flex gap="size-100" justifyContent="end" marginStart={type === 'details' && { S: 'size-400' }}>
      {shouldConsent && (
        <Flex gap="size-50" alignItems="center">
          <DialogTrigger>
            <Button variant="primary">Begin installation</Button>
            {(close) => (
              <EcAcquiredDialog
                isAdmin={isAdmin}
                close={close}
                dialogVariant="beginInstallation"
                requiredProducts={data?.appDetails?.hostApps}
                optionalProducts={data?.appDetails?.optionalProducts}
                appId={appId}
                orgId={orgId}
              />
            )}
          </DialogTrigger>
          {!isAdmin && (
            <ContextualHelp variant="info">
              <Heading>Installation must be done by a system administrator</Heading>
              <Content>
                <Text>
                  The installation process for this application must be initiated by the system administrator of your
                  organization&apos;s Adobe account.
                </Text>
              </Content>
            </ContextualHelp>
          )}
        </Flex>
      )}
      {!shouldConsent && (
        <Button variant="primary" minWidth="158px" onPress={() => setOpenedDialog('SELECT_PROFILES')}>
          Select product profile
        </Button>
      )}
      {actions?.length > 0 && (
        <ActionMenu
          items={actions}
          marginStart={{ S: 'size-50' }}
          onAction={(actionKey) => actions.find((action) => action.key === actionKey)?.onPress()}
        >
          {(action) => <Item textValue={action.textValue}>{action.label}</Item>}
        </ActionMenu>
      )}
      <DialogContainer onDismiss={() => setOpenedDialog(null)}>
        {openedDialog === 'REVOKE' &&
          (isAdmin ? (
            <SpAdminRevokeDialog appDetails={data.appDetails} type={type} />
          ) : (
            <SpNonAdminActionDialog
              title="Revoking this app must be done by a system administrator"
              content="The revoking process for this application must be done by the system administrator of your organization's Adobe account."
            />
          ))}
        {openedDialog === 'SELECT_PROFILES' &&
          (isAdmin ? (
            <SpProductProfilesDialog />
          ) : (
            <SpNonAdminActionDialog
              title="Viewing or editing product profiles must be done by a system administrator"
              content="Only system administrator can view or edit product profiles. Reach out to the system administrator of your organization's Adobe account to add or update product profiles for this application."
            />
          ))}
      </DialogContainer>
    </Flex>
  );
};

export default IntegrationActionMenu;
