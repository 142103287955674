import { Flex, Heading, Text, View } from '@adobe/react-spectrum';
import Info from '@spectrum-icons/workflow/Info';
import React from 'react';

import { useStore } from '../../../../store';
import { TEST_IDS } from './IntegrationDetails';

const NonAdminBanner = () => {
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');

  if (isAdmin) return null;

  return (
    <View
      borderWidth="thick"
      borderColor="static-blue-500"
      borderRadius="regular"
      backgroundColor="static-white"
      padding={{ base: 'size-200', S: 'size-300' }}
      data-testid={TEST_IDS.NON_ADMIN_BANNER}
    >
      <Flex gap="size-200" justifyContent="space-between">
        <Heading marginTop={0}>System administrators required</Heading>
        <Info size="S" color="informative" />
      </Flex>
      <Text>
        Only system administrator can take certain actions such as providing access to this application to access Adobe
        APIs on your organization&apos;s behalf, adding or editing product profiles, and revoking the access.
      </Text>
    </View>
  );
};

export default NonAdminBanner;
