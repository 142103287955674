import { useCallback, useEffect, useState } from 'react';

export const dateFormatter = new Intl.DateTimeFormat('default', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
});

const DebugMode = ({ children }) => {
  // DEBUG ONLY
  const [isDebugMode, setIsDebugMode] = useState(false);
  const canTurnDebugOn = !process.env.NODE_ENV || process.env.NODE_ENV !== 'production';

  const toggleDebugMode = useCallback(() => {
    if (canTurnDebugOn) {
      setIsDebugMode((val) => !val);
    }
  }, [canTurnDebugOn]);

  useEffect(() => {
    if (canTurnDebugOn) {
      window.addEventListener('exchange:toggle_debug', toggleDebugMode);
      return () => {
        window.removeEventListener('exchange:toggle_debug', toggleDebugMode);
      };
    }
  }, [canTurnDebugOn, toggleDebugMode]);

  return canTurnDebugOn ? isDebugMode && children : null;
};

export default DebugMode;
