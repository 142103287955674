import { StatusLight } from '@adobe/react-spectrum';
import React from 'react';
import styled from 'styled-components';

import { DEPLOYMENT_STATUS } from '../../../../../utils/Constants';

const SmallStatusText = styled.span`
  font-size: 11px;
`;

const AppStatusLight = (props) => {
  const { status } = props;
  const appStatusLights = (status) => {
    switch (status.toLowerCase()) {
      case 'published':
        return (
          <StatusLight variant="positive" {...props}>
            Approved
          </StatusLight>
        );
      case 'rejected':
        return (
          <StatusLight variant="negative" {...props}>
            Rejected
          </StatusLight>
        );
      case 'pending':
        return (
          <StatusLight variant="notice" {...props}>
            Pending Review
          </StatusLight>
        );
      case 'draft':
        return (
          <StatusLight variant="neutral" {...props}>
            Draft
          </StatusLight>
        );
      case 'installed':
        return (
          <StatusLight variant="positive" {...props}>
            Deployed
          </StatusLight>
        );
      case 'error':
      case 'failed':
      case DEPLOYMENT_STATUS.FAILED.toLowerCase():
      case DEPLOYMENT_STATUS.UNDEPLOYMENT_FAILED.toLowerCase():
        return (
          <StatusLight variant="negative" {...props}>
            <SmallStatusText>Error</SmallStatusText>
          </StatusLight>
        );
      case 'success':
        return (
          <StatusLight variant="positive" {...props}>
            <SmallStatusText>Success</SmallStatusText>
          </StatusLight>
        );
      case DEPLOYMENT_STATUS.IN_PROGRESS.toLowerCase():
      case DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS.toLowerCase():
        return (
          <StatusLight variant="notice" {...props}>
            <SmallStatusText>In Progress</SmallStatusText>
          </StatusLight>
        );
      case DEPLOYMENT_STATUS.SUCCESS.toLowerCase():
        return (
          <StatusLight variant="positive" {...props}>
            Deployed
          </StatusLight>
        );
      default:
        return;
    }
  };

  return <>{status && appStatusLights(status)}</>;
};

export default AppStatusLight;
