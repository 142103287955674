import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { handleApiCall, manageApi } from '@exchange-frontends/api';

import { UserDataContext } from '../components/App';
import { useStore } from '../store';

/**
 * Custom hook that's fetching user's orgs and cc apps ids
 * @returns {Promise<{orgs: object[]|null, loading: boolean, error: any}>}
 */
export const useOrgsFetch = () => {
  const [orgs, setOrgs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { accessToken } = useContext(UserDataContext) ?? {};

  useEffect(() => {
    let handler = null;
    const fetchOrgs = async () => {
      handler = handleApiCall(manageApi.getOrgs, { accessToken });
      try {
        const orgs = await handler.run();
        setOrgs(orgs);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    if (accessToken) {
      fetchOrgs();
    }
    return () => {
      handler?.controller.abort();
    };
  }, [accessToken]);

  return { orgs, error, loading };
};

/**
 * Custom hook that's fetching current env and additional details
 * @returns {Promise<{orgs: object[]|null, loading: boolean, error: any}>}
 */
/**
 *
 * @returns {{currentEnv: object || null}}
 */
export const useCurrentEnvFetch = () => {
  const { envId, appId } = useParams();
  const envList = useStore((state) => state.envList);
  const envLoading = useStore((state) => state.envLoading);
  const currentEnv = useMemo(
    () => envList?.[appId]?.find((e) => e?.id === envId) || null,
    [envList, appId, envId, envLoading]
  );

  return { currentEnv: currentEnv };
};

export const useCurrentDeploymentFetch = () => {
  const { envId } = useParams();
  const deployingState = useStore((state) => state.isDeploying);
  const deployments = useStore((state) => state.currentDeploymentsId);
  const currentDeployment = useMemo(
    () => deployments.find((e) => e?.[envId])?.[envId] || {},
    [deployments, envId, deployingState, deployments?.length]
  );

  return { currentDeployment: currentDeployment, deployments: deployments };
};
