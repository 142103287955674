//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { useStore } from '../../../../pages/manage/store';
import { ContainerContext } from '../../../../utils';
import getConsent from '../../actions/get-consent';
import useEnterpriseEntitlements from './useEnterpriseEntitlements';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

/**
 * @param {'ABD' | 'S2S'} appType
 * @param {import('@tanstack/react-query').UseQueryOptions} [options]
 */
const useEcAppsListConsents = (appType = 'S2S', options = {}) => {
  /** @type {string | undefined} */
  const orgId = useStore(selectedOrgSelector);

  const { data: entitlements } = useEnterpriseEntitlements(appType, options);
  const appIds = entitlements?.enterpriseEntitlements?.map(({ appId }) => appId);

  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/consent').EcAppsListConsentsQueryState} */
  const data = useQuery({
    ...options,
    queryKey: ['ec-apps-list-consents', orgId, token, appIds],
    enabled: Boolean(token && orgId && appIds?.length && (options.enabled ?? true)),
    queryFn: ({ signal }) =>
      getConsent({
        signal,
        token: /** @type {string} */ (token),
        orgId: /** @type {string} */ (orgId),
        appIds: /** @type {string[]} */ (appIds),
      }),
  });

  return data;
};

export default useEcAppsListConsents;
