import { ActionButton, Divider, Flex, Heading, ProgressCircle, Text, View } from '@adobe/react-spectrum';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import Alert from '@spectrum-icons/workflow/Alert';
import Copy from '@spectrum-icons/workflow/Copy';
import '@spectrum-web-components/table/sp-table-body.js';
import '@spectrum-web-components/table/sp-table-cell.js';
import '@spectrum-web-components/table/sp-table-checkbox-cell.js';
import '@spectrum-web-components/table/sp-table-head-cell.js';
import '@spectrum-web-components/table/sp-table-head.js';
import '@spectrum-web-components/table/sp-table-row.js';
import '@spectrum-web-components/table/sp-table.js';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { handleApiCall, manageApi } from '@exchange-frontends/api';
import { CopyButton } from '@exchange-frontends/components';

import { useStore } from '../../../../../../../store';
import { UserDataContext } from '../../../../../../App';
import AppStatusLight from '../../../common/AppStatusLight';
import { NoInsightsContainer } from '../../../common/StyledComponents';

const AccordionItemHeader = ({ deployment }) => {
  return (
    <Flex width="100%" gap="5%">
      <sp-table-row>
        <sp-table-cell>{deployment?.timestamp}</sp-table-cell>
        <sp-table-cell>{deployment?.deployedBy}</sp-table-cell>
        <sp-table-cell>{deployment?.appVersion}</sp-table-cell>
        <sp-table-cell>
          <Flex justifyContent="space-between" alignItems="start">
            <div>
              <AppStatusLight status={deployment?.status} position="absolute" top="-4px" />
            </div>
            {deployment?.status?.toLowerCase() === 'failed' && <Alert size="XS" color="negative" />}
          </Flex>
        </sp-table-cell>
      </sp-table-row>
    </Flex>
  );
};

const DeploymentAccordionItem = styled(AccordionItem)`
  background: white;

  .spectrum-Accordion-itemHeader {
    text-transform: none;
  }
`;

export const DeploymentLogs = ({ envId, currentDeployment, orgId, appId, isEnvInstalled }) => {
  const fetchDeploymentLogsById = useStore((state) => state.fetchDeploymentLogsById);
  const columns = [
    { key: 'deployment_date', title: 'deployment date' },
    { key: 'deployed_by', title: 'deployed by' },
    { key: 'version', title: 'version' },
    { key: 'status', title: 'status' },
  ];
  const [deployments, setDeployments] = useState([]);
  const [isDeploymentsLoading, setIsDeploymentsLoading] = useState(true);
  const [isLogsLoading, setIsLogsLoading] = useState(true);
  const { accessToken } = useContext(UserDataContext) ?? {};
  const [logString, setLogString] = useState('');

  /** load deployments */
  useEffect(() => {
    if (currentDeployment?.id && accessToken && appId && envId && orgId) {
      let mounted = true;
      const deploymentId = currentDeployment.id;
      const handler = handleApiCall(manageApi.getDeploymentLogs, { accessToken, orgId, appId, envId, deploymentId });
      (async () => {
        try {
          const deployments = await handler.run();
          if (mounted) {
            setDeployments(deployments);
            setIsDeploymentsLoading(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted && err.name !== 'AbortError') {
            setIsDeploymentsLoading(false);
          }
        }
      })();
      return () => {
        mounted = false;
        handler.controller.abort();
      };
    }
  }, [currentDeployment, accessToken, appId, envId, orgId]);

  const getLogs = (key) => {
    if (key || key === 0) {
      const logId = deployments?.[key]?.id;
      let logs = currentDeployment?.logs || false;
      if (!logs) {
        setIsLogsLoading(true);
        setIsLogsLoading(true);
        fetchDeploymentLogsById(accessToken, orgId, appId, envId, currentDeployment?.id, logId)
          .then((response) => {
            logs = response.content;
            setLogString(logs);
          })
          .finally(() => {
            setIsLogsLoading(false);
          });
      } else {
        setLogString(currentDeployment?.logs);
        setIsLogsLoading(false);
      }
    }
  };

  return isDeploymentsLoading && currentDeployment?.id ? (
    <Flex justifyContent="center" alignItems="center" height="10%" marginTop="52px">
      <ProgressCircle size="L" isIndeterminate aria-label="Loading…" />
    </Flex>
  ) : deployments.length > 0 ? (
    <>
      <sp-table size="m">
        <sp-table-head style={{ padding: '0 17px' }}>
          {columns.map((column) => (
            <sp-table-head-cell key={column.key}>{column.title}</sp-table-head-cell>
          ))}
        </sp-table-head>
      </sp-table>
      <Accordion aria-label="Default" ariaLevel={3} multiselectable={false} onChange={getLogs}>
        {deployments.map((deployment) => (
          <DeploymentAccordionItem
            key={deployment.id}
            disabled={false}
            header={<AccordionItemHeader deployment={deployment} />}
            selected={false}
          >
            {isLogsLoading ? (
              <Flex justifyContent="center" alignItems="center" height="10%">
                <ProgressCircle size="L" isIndeterminate aria-label="Loading…" />
              </Flex>
            ) : (
              <View paddingStart="size-550" paddingEnd="size-300">
                <View backgroundColor="white" height="size-400">
                  <Text marginBottom="size-200">
                    DEPLOYMENT COMPLETED IN: {deployment?.duration ? deployment?.duration + ' MS' : 'N/A'}
                  </Text>
                </View>
                <View backgroundColor="default" paddingX="size-100" paddingY="size-200" paddingBottom="size-200">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Heading level="4">Deployment Logs</Heading>
                    <CopyButton text={logString}>
                      <ActionButton label="Copy" onPress={(e) => e.target.closest('div').click()}>
                        <Copy />
                        <Text>Copy logs</Text>
                      </ActionButton>
                    </CopyButton>
                  </Flex>
                  <Divider size="S" />
                  <div style={{ overflow: 'hidden' }}>{logString}</div>
                </View>
              </View>
            )}
          </DeploymentAccordionItem>
        ))}
      </Accordion>
    </>
  ) : (
    <NoInsightsContainer
      orgId={orgId}
      appId={appId}
      envId={envId}
      isEnvInstalled={isEnvInstalled}
      currentDeployment={currentDeployment}
    />
  );
};
