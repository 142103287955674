import { Content, Heading, IllustratedMessage } from '@adobe/react-spectrum';
import Error from '@spectrum-icons/illustrations/Error';
import Unauthorized from '@spectrum-icons/illustrations/Unauthorized';
import React from 'react';

import { useStore } from '../../../../../store';

const defaultErrorMessage = 'Something went wrong. Please try again in a few minutes.';

const ErrorContainer = ({ children }) => {
  const envError = useStore((state) => state.envError);

  if ([401, 403].includes(envError?.code)) {
    return (
      <IllustratedMessage>
        <Unauthorized />
        <Heading>Access not allowed</Heading>
        <Content>Only admins and developers in your organization are allowed to access environment details.</Content>
      </IllustratedMessage>
    );
  } else if (envError) {
    return (
      <IllustratedMessage>
        <Error />
        <Content>{envError?.userMessage || defaultErrorMessage}</Content>
      </IllustratedMessage>
    );
  } else {
    return children || null;
  }
};

export default ErrorContainer;
