//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from '../../../../pages/manage/store';
import { ContainerContext } from '../../../../utils';
import getEnterpriseEntitlements from '../../actions/get-enterprise-entitlements';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

/** @param {'ABD' | 'S2S'} [appType] */
const useEnterpriseEntitlement = (appType = 'S2S') => {
  /** @type {string | undefined} */
  const orgId = useStore(selectedOrgSelector);
  const { appId } = useParams();

  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/entitlement').EnterpriseEntitlementQueryState} */
  const data = useQuery({
    queryKey: ['enterprise-entitlement', orgId, token, appId],
    enabled: Boolean(token && orgId && appId),
    queryFn: async ({ signal }) => {
      const response = await getEnterpriseEntitlements({
        token: /** @type {string} */ (token),
        orgId: /** @type {string} */ (orgId),
        appId,
        appType,
        signal,
      });
      return response?.enterpriseEntitlements?.[0] || null;
    },
  });

  return data;
};

export default useEnterpriseEntitlement;
