import { utilApiClient } from '../../utils/RequestClient';

/**
 * @param {object} params
 * @param {import('../../../constants').FeatureFlag} params.featureFlag
 * @param {string} params.token
 * @param {AbortSignal} [params.signal]
 * @returns {Promise<boolean>}
 */
const getFeatureFlag = async ({ featureFlag, token, signal }) => {
  try {
    await utilApiClient.fetch({
      endpoint: '/features',
      requestOptions: {
        signal,
        method: 'HEAD',
        headers: {
          'x-feature': featureFlag.key,
          'x-feature-client': featureFlag.client,
          authorization: 'Bearer ' + token,
        },
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export default getFeatureFlag;
