// @ts-check
import { Content, Heading, IllustratedMessage, Link as LinkWrapper, Text } from '@adobe/react-spectrum';
import NotFoundIllustration from '@spectrum-icons/illustrations/NotFound';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

/**
 * @typedef NotFoundMessageProps
 * @property {string | string[]} heading
 * @property {string | string[]} content
 * @property {{ text: string; href: string }} link
 * @property {Omit<import('@adobe/react-spectrum').SpectrumIllustratedMessageProps,'children'>} [rootProps]
 * @property {Omit<import('@adobe/react-spectrum').HeadingProps, 'children'>} [headingProps]
 * @property {Omit<import('@adobe/react-spectrum').ContentProps, 'children'>} [contentProps]
 * @property {Omit<import('@adobe/react-spectrum').SpectrumLinkProps, 'children'>} [linkProps]
 */

/**
 *
 * @param {NotFoundMessageProps} props
 */
export const NotFoundMessage = ({
  heading,
  content,
  link,
  rootProps = {},
  headingProps = {},
  contentProps = {},
  linkProps = {},
}) => {
  return (
    <IllustratedMessage marginTop="size-1000" {...rootProps}>
      <NotFoundIllustration />
      <Heading {...headingProps}>
        {Array.isArray(heading)
          ? heading.map((text, i) => (
              <Fragment key={i}>
                {text}
                <br />
              </Fragment>
            ))
          : heading}
      </Heading>
      <Content {...contentProps}>
        {Array.isArray(content)
          ? content.map((c, i) => (
              <Fragment key={i}>
                <Text>{c}</Text>
                <br />
              </Fragment>
            ))
          : content}
      </Content>
      {link && link.text && link.href && (
        <LinkWrapper {...linkProps}>
          <Link to={link.href}>{link.text}</Link>
        </LinkWrapper>
      )}
    </IllustratedMessage>
  );
};
