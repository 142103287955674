import React from 'react';
import styled from 'styled-components';

/**
 * @returns {JSX.Element}
 * @description Header wrapper styled-component
 */
export const XCHGHeaderWrapper = styled.div`
  position: relative;
  z-index: 10;
  && * {
    box-sizing: border-box;
  }
`;

/**
 * @returns {JSX.Element}
 * @description Header styled-component
 */
export const XCHGHeader = styled.div`
  position: relative;
  z-index: 2;
  -webkit-font-smoothing: antialiased;
  ul {
    list-style: none;
  }
`;

/**
 * @description Nav curtain wrapper styled-component
 * @returns {JSX.Element}
 */
export const NavCurtainWrapper = styled.span`
  display: none;
  &.nav-curtain-wrapper--open {
    display: block;
    span {
      height: 100%;
      width: 100%;
    }
  }
`;

/**
 * @description Nav curtain styled-component
 * @returns {JSX.Element}
 */
export const NavCurtain = styled.span`
  background: #000;
  opacity: 0.5;
  position: fixed;
`;

/**
 * @description Top nav styled-component
 * @returns {JSX.Element}
 */
export const TopNav = styled.header`
  background-color: #fff;
  position: relative;
  z-index: 1;
  li {
    font-size: 14px;
    line-height: 1;
    margin: 0;
  }
  @media (min-width: 1200px) {
    min-height: 60px;
  }
`;

/**
 * @description Nav styled-component
 * @returns {JSX.Element}
 */
export const Nav = styled.nav`
  align-items: inherit;
  display: flex;
  flex-direction: inherit;
  justify-content: inherit;
  min-height: inherit;
  width: 100%;
`;

/**
 * @description nav list wrapper styled-component
 * @returns {JSX.Element}
 */
export const NavListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1;
  width: 100%;
  min-height: inherit;
  &.nav-list-wrapper--main {
    overflow: hidden;
    background-color: #fff;
    z-index: 1;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
    display: none;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 100%;
    width: calc(100% - 20px);
    z-index: 1;
    @media (min-width: 1200px) {
      box-shadow: none;
      display: flex;
      height: auto;
      left: auto;
      padding-bottom: 0;
      position: static;
      right: auto;
      top: auto;
      width: 100%;
    }
  }
`;

/**
 * @description nav list styled-component
 * @returns {JSX.Element}
 */
export const NavList = styled.ul`
  display: flex;
  align-items: inherit;
  flex-direction: inherit;
  flex-wrap: inherit;
  height: 100%;
  justify-content: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
  &.nav-list--slidein {
    background-color: #fafafa;
    position: absolute;
    transform: translateX(100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.25s cubic-bezier(0.45, 0, 0.4, 1);
    &.nav-list--slidein--open {
      transform: translateX(0);
    }
  }
  & #nav-list-item--main {
    overflow-y: auto;
    @media (min-width: 1200px) {
      overflow-y: visible;
    }
  }
`;

/**
 * @description nav list item styled-component
 * @returns {JSX.Element}
 */
export const NavListItem = styled.li`
  display: flex;
  list-style: none;
  :only-child {
    justify-content: inherit;
    width: 100%;
  }
  & #nav-list-item--main {
    order: -1;
    @media (min-width: 1200px) {
      order: 0;
    }
  }
  &.nav-list-item--orgswitcher {
    display: none;
    @media (min-width: 600px) {
      display: flex;
    }
  }
  & button[aria-expanded='true'] + .nav-list-wrapper--main {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
  }
`;

/**
 * @description link component that accepts custom props like `daa-ll`
 * @param {Object} props
 * @returns {JSX.Element}
 */
const Link = (props) => {
  return <a {...props} />;
};

/**
 * @description nav logo styled-component
 * @returns {JSX.Element}
 */
export const NavLogo = styled(Link)`
  align-items: center;
  display: flex;
  color: #fa0f00;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  box-sizing: border-box;
  &.focus-ring {
    outline: 2px solid #109cde;
    outline-offset: -2px;
    z-index: 2;
  }
  .nav-logo-image {
    display: block;
    width: 100%;
  }
`;

/**
 * @description nav logo text styled-component
 * @returns {JSX.Element}
 */
export const NavLogoText = styled.span`
  color: inherit;
  display: flex;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 20px;
  & > :first-child {
    display: block;
  }
  :before {
    content: '';
    margin-left: 10px;
  }
`;

/**
 * @description nav list hamburger button styled-component
 * @returns {JSX.Element}
 */
export const NavListHamburgerButton = styled.button`
  @media (min-width: 1200px) {
    display: none;
  }
  :before {
    content: '\\2630';
  }
  background: transparent;
  border: none;
  box-shadow: none;
  color: #2d2d2d;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  width: 60px;
  &[aria-expanded='true']:before {
    content: '\\2715';
  }
  &.focus-ring {
    outline: 2px solid #109cde;
    outline-offset: -2px;
  }
`;

/**
 * @description nav link styled-component
 * @returns {JSX.Element}
 */
export const NavLink = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  line-height: 1.4;
  text-decoration: none;
  transition: background-color 0.1s ease;
  width: 100%;
  &.nav-link--main {
    padding-top: 16px;
    padding-left: 20px;
    padding-bottom: 16px;
    padding-right: 20px;
    border-bottom: solid 1px #f3f3f3;
    :hover {
      background-color: #fafafa;
    }
  }
  &.nav-link--main.nav-link--active span {
    font-weight: 600;
  }
  &.nav-link--main.nav-link--disabled {
    cursor: default;
  }
  && {
    @media (min-width: 1200px) {
      padding-top: 0px;
      padding-bottom: 0px;
      border-bottom: none;
      &.nav-link--main {
        padding: 0 20px;
        padding-bottom: 0px;
        :hover {
          background-color: #fafafa;
        }
      }
      &.nav-link--main.nav-link--active > span:after {
        content: '';
        border-bottom: 2px solid #2c2c2c;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
  &.focus-ring {
    outline: 2px solid #109cde;
    outline-offset: -2px;
  }
`;

/**
 * @description nav link content wrapper styled-component
 * @returns {JSX.Element}
 */
export const NavLinkContentWrapper = styled.span`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  white-space: nowrap;
`;

/**
 * @description nav link content styled-component
 * @returns {JSX.Element}
 */
export const NavLinkContent = styled.span`
  display: flex;
  flex-direction: column;
`;

/**
 * @description nav link text styled-component
 * @returns {JSX.Element}
 */
export const NavLinkText = styled.span`
  align-items: center;
  display: flex;
  color: #2c2c2c;
  font-size: 14px;
  transition: color 0.1s ease;
`;

/**
 * @description nav profile wrapper styled-component
 * @returns {JSX.Element}
 */
export const NavProfileWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin-left: 12px;
  padding-top: 0px;
  color: #2c2c2c;
  padding-left: 20px;
  padding-bottom: 0px;
  margin-right: 12px;
  padding-right: 20px;
  && .Profile-thumbnail:focus {
    outline: 2px solid #109cde;
    outline-offset: 2px;
  }
`;

/**
 * @description nav profile link styled-component
 * @returns {JSX.Element}
 */
export const NavProfileLink = styled.a`
  align-self: center;
  display: flex;
  padding: 10px 4px;
  text-decoration: none;
  transition: color 0.1s ease;
  :hover {
    color: #1473e6;
  }
`;
