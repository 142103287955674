.spectrum--light .spectrum-Accordion-item {
  border-color: rgb(225, 225, 225);
}
.spectrum--light .spectrum-Accordion-itemIndicator {
  color: rgb(142, 142, 142);
}
.spectrum--light .spectrum-Accordion-itemHeader {
  color: rgb(110, 110, 110);
}
.spectrum--light .spectrum-Accordion-itemHeader:hover {
    color: rgb(44, 44, 44);

    background-color: rgb(234, 234, 234);
  }
.spectrum--light .spectrum-Accordion-itemHeader:hover + .spectrum-Accordion-itemIndicator {
      color: rgb(75, 75, 75);
    }
.spectrum--light .spectrum-Accordion-itemHeader.focus-ring:after  {
      background-color: rgb(38, 128, 235);
    }
.spectrum--light .spectrum-Accordion-item.is-open .spectrum-Accordion-itemHeader:hover {
        background-color: transparent;
      }
.spectrum--light .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader,
.spectrum--light .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader:hover,
.spectrum--light .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader.focus-ring {
      color: rgb(179, 179, 179);
      background-color: transparent;
    }
.spectrum--light .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader + .spectrum-Accordion-itemIndicator {
      color: rgb(202, 202, 202);
    }
