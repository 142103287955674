import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { FEATURED_FLAGS, queryClient } from '../../../constants';
import { ContainerContext } from '../../../utils';
import getFeatureFlag from '../actions/get-feature-flag';

const queryKey = [FEATURED_FLAGS.SP_ISV, 'featureFlag'];

/** @typedef {import('@tanstack/react-query').UseQueryResult<boolean, never>} SpFeatureFlagQueryState */

const useSpFeatureFlag = () => {
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;
  /** @type {SpFeatureFlagQueryState}  */
  const data = useQuery({
    queryKey,
    enabled: !!token,
    staleTime: Infinity,
    queryFn: ({ queryKey: [featureFlag], signal }) => getFeatureFlag({ featureFlag, token, signal }),
  });
  return data;
};

/** @returns {SpFeatureFlagQueryState} */
useSpFeatureFlag.getState = () => queryClient.getQueryState(queryKey);

export default useSpFeatureFlag;
