.spectrum--dark .spectrum-Accordion-item {
  border-color: rgb(74, 74, 74);
}
.spectrum--dark .spectrum-Accordion-itemIndicator {
  color: rgb(144, 144, 144);
}
.spectrum--dark .spectrum-Accordion-itemHeader {
  color: rgb(185, 185, 185);
}
.spectrum--dark .spectrum-Accordion-itemHeader:hover {
    color: rgb(255, 255, 255);

    background-color: rgb(62, 62, 62);
  }
.spectrum--dark .spectrum-Accordion-itemHeader:hover + .spectrum-Accordion-itemIndicator {
      color: rgb(227, 227, 227);
    }
.spectrum--dark .spectrum-Accordion-itemHeader.focus-ring:after  {
      background-color: rgb(38, 128, 235);
    }
.spectrum--dark .spectrum-Accordion-item.is-open .spectrum-Accordion-itemHeader:hover {
        background-color: transparent;
      }
.spectrum--dark .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader,
.spectrum--dark .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader:hover,
.spectrum--dark .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader.focus-ring {
      color: rgb(110, 110, 110);
      background-color: transparent;
    }
.spectrum--dark .spectrum-Accordion-item.is-disabled .spectrum-Accordion-itemHeader + .spectrum-Accordion-itemIndicator {
      color: rgb(90, 90, 90);
    }
