//@ts-check
import { Content, Flex, Heading, IllustratedMessage } from '@adobe/react-spectrum';
import Unauthorized from '@spectrum-icons/illustrations/Unauthorized';
import React from 'react';

import { useStore } from '../../../../store';

/**
 * @param {object} props
 * @param {React.ElementType} props.Element
 * @param {string} [props.description]
 * @param {object} [props.elementProps]
 * @param {string} [props.testId]
 */
const AdminRoute = ({ Element, description, elementProps = {}, testId }) => {
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');

  if (!isAdmin) {
    return (
      <Flex justifyContent="center" marginTop="size-300" data-testid={testId}>
        <IllustratedMessage maxWidth="400px">
          <Unauthorized />
          <Heading>Access denied</Heading>
          {description && <Content>{description}</Content>}
        </IllustratedMessage>
      </Flex>
    );
  }

  return <Element {...elementProps} />;
};

export default AdminRoute;
