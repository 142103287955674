import { ActionButton, Flex, Item, TabList, TabPanels, Tabs, Text } from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEPLOYMENT_STATUS, ENV_STATUS } from '../../../../../../utils/Constants';
import { useCurrentDeploymentFetch, useCurrentEnvFetch } from '../../../../../../utils/customHooks';
import { InlineMessage } from '../../common/StyledComponents';
import { DeploymentLogs } from './insights/DeploymentLogs';
import { ExecutionLogs } from './insights/ExecutionLogs';
import { Insights } from './insights/Insights';

const EnvInsights = () => {
  const { envId, appId, orgId } = useParams();
  const [hasDeploymentErrors, setHasDeploymentErrors] = useState(false);
  const [isEnvInstalled, setIsEnvInstalled] = useState(true);
  const { currentEnv } = useCurrentEnvFetch();
  const { currentDeployment, deployments } = useCurrentDeploymentFetch();

  useEffect(() => {
    if (currentDeployment && Object.keys(currentDeployment).length > 0) {
      setHasDeploymentErrors(currentDeployment?.status === DEPLOYMENT_STATUS.FAILED);
    }
  }, [currentDeployment, currentDeployment?.status]);

  useEffect(() => {
    if (currentEnv?.status) {
      currentEnv.status === ENV_STATUS.INSTALLED ? setIsEnvInstalled(true) : setIsEnvInstalled(false);
    }
  }, [currentEnv]);

  const tabs = [
    {
      name: 'Insights',
      children: (
        <Insights
          envId={envId}
          deployments={deployments}
          appId={appId}
          orgId={orgId}
          currentDeployment={currentDeployment}
          currentEnv={currentEnv}
          isEnvInstalled={isEnvInstalled}
        />
      ),
    },
    {
      name: 'Deployment logs',
      children: (
        <DeploymentLogs
          envId={envId}
          currentDeployment={currentDeployment}
          appId={appId}
          orgId={orgId}
          isEnvInstalled={isEnvInstalled}
        />
      ),
    },
    {
      name: 'Execution logs',
      children: (
        <ExecutionLogs
          envId={envId}
          appId={appId}
          orgId={orgId}
          currentDeployment={currentDeployment}
          isEnvInstalled={isEnvInstalled}
        />
      ),
    },
  ];

  const [tabId, setTabId] = useState('Insights');

  return (
    <>
      {hasDeploymentErrors && (
        <InlineMessage>
          <Alert size="S" />
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Text>Deployment failed. Check deployment logs for more information.</Text>
            <ActionButton variant="primary" staticColor="white" onPress={() => setTabId('Deployment logs')}>
              View logs
            </ActionButton>
          </Flex>
        </InlineMessage>
      )}
      <Tabs aria-label="Environment Insights" selectedKey={tabId} items={tabs} onSelectionChange={setTabId}>
        <TabList>
          {tabs.map((tab) => (
            <Item key={tab.name}>{tab.name}</Item>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((item) => (
            <Item key={item.name}>{item.children}</Item>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default EnvInsights;
