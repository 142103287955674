//@ts-check
import { ActionButton, DialogTrigger, Flex, Text } from '@adobe/react-spectrum';
import Help from '@spectrum-icons/workflow/Help';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import Search from '@spectrum-icons/workflow/Search';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEnterpriseEntitlement from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useEnterpriseEntitlement';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { LINKS, ROUTES } from '../../../../../../constants';
import GetSupportDialog from '../../../../../components/GetSupportDialog';
import IntegrationActionMenu from '../../../common/IntegrationActionMenu';
import IntegrationBreadcrumbs from './IntegrationBreadcrumbs';

const IntegrationDetailsHeader = () => {
  const navigate = useNavigate();
  const { appId } = useParams();

  const appDetails = useEcAppDetails();
  const entitlement = useEnterpriseEntitlement();
  const consent = useConsent();

  return (
    <Flex wrap="wrap-reverse" gap="size-200" alignItems="end">
      <IntegrationBreadcrumbs />
      <Flex wrap gap="size-100" justifyContent="end" flexGrow={1}>
        <DialogTrigger>
          <ActionButton>
            <Help />
            <Text>Get support</Text>
          </ActionButton>
          {/* @TODO: add the new template name when user contacts the publisher from manage page */}
          {(close) => (
            <GetSupportDialog showContactPublisher appId={appId} support={appDetails?.data?.support} close={close} />
          )}
        </DialogTrigger>
        <ActionButton onPress={() => window.open(LINKS.SP.VIEW_DOCS, '_blank', 'noopener, noreferrer')}>
          <LinkOutLight />
          <Text>View documentation</Text>
        </ActionButton>
        <ActionButton onPress={() => navigate(`${ROUTES.DETAILS}/ec/${appId}`)}>
          <Search />
          <Text>View listing</Text>
        </ActionButton>
        <IntegrationActionMenu
          type="details"
          data={{ appDetails: appDetails.data, entitlement: entitlement.data, consent: consent.data?.consent }}
        />
      </Flex>
    </Flex>
  );
};

export default IntegrationDetailsHeader;
