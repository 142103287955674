import React from 'react';

import { ContactPublisherDialog } from '@exchange-frontends/components';

/**
 * @param {object} props
 * @param {string} props.appId
 * @param {() => void} props.close
 * @param {() => void} [props.onGoBack]
 */
const ManageContactPublisherDialog = ({ appId, close, onGoBack }) => (
  <ContactPublisherDialog
    onGoBack={onGoBack}
    close={close}
    appId={appId}
    title="Contact support team"
    description={
      'Fill out the form below to submit a support request for this solution. For Exchange related support reach out to our support team'
    }
  />
);

export default ManageContactPublisherDialog;
