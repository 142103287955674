//@ts-check
import {
  Button,
  Content,
  Dialog,
  Divider,
  Flex,
  Footer,
  Heading,
  Image,
  InlineAlert,
  ProgressCircle,
  Text,
  TextField,
  View,
  useDialogContainer,
} from '@adobe/react-spectrum';
import React, { useState } from 'react';

import useConsent from '../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEcAppsListConsents from '../../../../actions/entitlementApi/selectors/ecAppsList/useEcAppsListConsents';
import useUpdateConsent from '../../../../actions/entitlementApi/selectors/useUpdateConsent';

/**
 * @param {object} props
 * @param {import('@action-types/ecApp').AppCardDetails | import('@action-types/ecApp').AppDetails} props.appDetails
 * @param {'details' | 'queue'} props.type
 */
const SpAdminRevokeDialog = ({ appDetails, type }) => {
  const { mutateAsync: updateConsent, error, isLoading } = useUpdateConsent();
  const { refetch: refetchConsent } = useConsent({ enabled: false });
  const { refetch: refetchConsents } = useEcAppsListConsents('S2S', { enabled: false });

  const { dismiss } = useDialogContainer();
  const [typedAppName, setTypedAppName] = useState('');
  /** @type {ReturnType<typeof useState<undefined | {errorMessage: string, validationState: import('@adobe/react-spectrum').SpectrumTextFieldProps['validationState']}>>} */
  const [appNameValidationState, setAppNameValidationState] = useState();
  const { name, summary, assets } = appDetails ?? {};
  const iconUrl = assets?.find((a) => a?.type === 'icon')?.url;

  const isInvalid = appNameValidationState?.validationState === 'invalid';
  const isDisabled = !typedAppName || isInvalid || isLoading;

  const validateTypedAppName = () => {
    const isAppNameValid = typedAppName === name;
    if (!isAppNameValid) {
      setAppNameValidationState({ errorMessage: 'Application name does not match', validationState: 'invalid' });
    } else {
      setAppNameValidationState(undefined);
    }
    return isAppNameValid;
  };

  const onRevoke = async () => {
    if (validateTypedAppName()) {
      try {
        const refetchMap = {
          details: refetchConsent,
          queue: refetchConsents,
        };
        const refetch = refetchMap[type];
        await updateConsent({
          appId: appDetails?.id,
          action: 'REVOKE',
          refetch,
          successToastMessage: (
            <Flex direction="column">
              <Text>
                This application can no longer access Adobe APIs on your organization&apos;s behalf. Your current
                configurations and historical data should remain unaffected.
              </Text>
              <Text>Click &apos;Begin installation&apos; if you want to allow access to this application again.</Text>
            </Flex>
          ),
        });
        dismiss();
      } catch (e) {
        // Do nothing. The error will be handled by the useUpdateConsent hook.
      }
    }
  };

  return (
    <Dialog>
      <Heading>Revoke this application</Heading>
      <Divider />
      <Content>
        <Flex gap="size-400" direction="column">
          <View padding="size-200" backgroundColor="static-white" borderRadius="regular">
            <Flex gap="size-200" alignItems="center" marginBottom="size-100">
              <View borderRadius="regular" overflow="hidden">
                <Image alt="Application icon" src={iconUrl} width="size-600" height="size-600" />
              </View>
              <Heading margin={0}>{name}</Heading>
            </Flex>
            <Text>{summary}</Text>
          </View>
          <Text>You are about to revoke your organization&apos;s access to connect to this application.</Text>
          <strong>
            This means that this application will lose access to your organization&apos;s Adobe account. Existing tokens
            will work for 1 hour. The application will not be able to create new ones.
          </strong>
          <Text>
            Are you sure you want to revoke access? Confirm by typing in the name of the application (as shown above) in
            the field below.
          </Text>
        </Flex>
        <TextField
          isRequired
          marginTop="size-200"
          label="Application name"
          width="100%"
          maxWidth="300px"
          value={typedAppName}
          onChange={setTypedAppName}
          validationState={appNameValidationState?.validationState}
          errorMessage={appNameValidationState?.errorMessage}
          onBlur={validateTypedAppName}
        />
      </Content>
      <Footer>
        {error && (
          <InlineAlert variant="negative" width="100%" marginBottom="size-200">
            <Heading>Something went wrong while revoking this application</Heading>
            <Content>{error.message}</Content>
          </InlineAlert>
        )}
        <Flex width="100%" justifyContent="end" gap="size-200">
          <Button variant="secondary" onPress={dismiss}>
            No, cancel
          </Button>
          <Button isDisabled={isDisabled} variant="negative" onPress={onRevoke}>
            {isLoading ? (
              <ProgressCircle size="S" aria-label={`Revoking ${name}…`} isIndeterminate />
            ) : (
              <Text>Yes, revoke</Text>
            )}
          </Button>
        </Flex>
      </Footer>
    </Dialog>
  );
};

export default SpAdminRevokeDialog;
