//@ts-check
import { Content, Divider, Flex, Heading, IllustratedMessage, ProgressCircle, View } from '@adobe/react-spectrum';
import { error as errorToast } from '@react/react-spectrum/Toast/js/ToastContainer';
import Error500 from '@spectrum-icons/illustrations/Error';
import React, { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEnterpriseEntitlement from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useEnterpriseEntitlement';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { ROUTES } from '../../../../../../constants';
import { useStore } from '../../../../store';
import { TABS_DEFINITION } from '../../../../utils/TabsDefinition';
import AccessDetailsTab from './AccessDetailsTab';
import AccessGrantedDialog from './AccessGrantedDialog';
import AdminRoute from './AdminRoute';
import IntegrationApplicationDetails from './IntegrationApplicationDetails';
import IntegrationDetailsHeader from './IntegrationDetailsHeader';
import ProductProfilesTab from './ProductProfilesTab';
import SideNavigation from './SideNavigation';
import useConsentBackupStrategy from './useConsentBackupStrategy';

export const WORKFLOWS = Object.freeze({
  CONSENTED: 'consented',
  PROFILES: 'profiles',
});

export const TEST_IDS = Object.freeze({
  APP_DETAILS: 'app-details',
  APP_DETAILS_SECTION: 'app-details-section',
  APP_HEADER_STATUS: 'app-header-status',
  APP_HEADER_EMAIL: 'app-header-email',
  APP_HEADER_DATE: 'app-header-dates',
  REQUIRED_PRODUCTS: 'required-products',
  OPTIONAL_PRODUCTS: 'optional-products',
  DESCRIPTION: 'app-details-description',
  PUBLISHER_NAME: 'app-details-publisher-name',
  ACTIVITY_LOG_TABLE: 'activity-log-table',
  ACCESS_DETAILS_DENIED: 'access-details-denied',
  PRODUCT_PROFILES_DENIED: 'product-profiles-denied',
  NON_ADMIN_BANNER: 'non-admin-banner',
  ACCESS_DETAILS_TAB: 'access-details-tab',
  TECH_ACCOUNT_SECTION: 'tech-account-section',
  SCOPES_SECTION: 'scopes-section',
});

export const INTEGRATION_DETAILS_ROUTES = Object.freeze({
  APPLICATION_DETAILS: { path: 'details', name: 'Application details', Element: IntegrationApplicationDetails },
  PRODUCT_PROFILES: {
    path: 'profiles',
    name: 'Product profiles',
    Element: () => (
      <AdminRoute
        Element={ProductProfilesTab}
        description="Viewing or editing product profiles must be completed by the system administrator of your organization's Adobe account."
        testId={TEST_IDS.PRODUCT_PROFILES_DENIED}
      />
    ),
  },
  ACCESS_DETAILS: {
    path: 'access',
    name: 'Access details',
    Element: () => (
      <AdminRoute
        Element={AccessDetailsTab}
        description="Only system administrators of your organization can view the access details for this app."
        testId={TEST_IDS.ACCESS_DETAILS_DENIED}
      />
    ),
  },
});

const routes = Object.values(INTEGRATION_DETAILS_ROUTES);

const IntegrationDetails = () => {
  const entitlement = useEnterpriseEntitlement();
  const consent = useConsent();
  const appDetails = useEcAppDetails();
  const routeParams = useParams();
  const navigate = useNavigate();
  const { isUpdatingConsent } = useConsentBackupStrategy();
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');

  const isEntitlementError = entitlement.isError && !entitlement.data;
  const isConsentError = consent.isError && !consent.data;
  const isAppDetailsError = appDetails.isError && !appDetails.data;
  const isError = isEntitlementError || isConsentError || isAppDetailsError;

  const isLoadingData = entitlement.isLoading || appDetails.isLoading || consent.isLoading;
  const isLoading = isLoadingData || isUpdatingConsent;

  const status = consent.data?.consent?.status;
  const shownRoutes = status === 'REQUESTED' ? [INTEGRATION_DETAILS_ROUTES.APPLICATION_DETAILS] : routes;
  const { path: selectedTab, Element: SideContent } = shownRoutes.find((route) => route.path === routeParams.tab) ?? {};

  // Redirect to 'Application details' tab if the selected tab is not found in the shown routes array.
  useEffect(() => {
    if (!selectedTab) {
      navigate(
        generatePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATION_DETAILS.path}`, {
          ...routeParams,
          tab: INTEGRATION_DETAILS_ROUTES.APPLICATION_DETAILS.path,
        }),
        { replace: true }
      );
    }
  }, [navigate, routeParams, selectedTab]);

  // Redirect to integrations list if the entitlement is not found.
  useEffect(() => {
    if (!entitlement.data && !entitlement.isLoading && !entitlement.error) {
      errorToast('Application not found', { timeout: 0 });
      navigate(generatePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATIONS.path}`, routeParams));
    }
  }, [entitlement.data, entitlement.error, entitlement.isLoading, navigate, routeParams]);

  if (isLoading || !selectedTab) {
    return (
      <Flex justifyContent="center" flexGrow={1} alignItems="center" marginBottom="size-500">
        <ProgressCircle isIndeterminate aria-label="Loading app details" size="L" />
      </Flex>
    );
  } else if (isError) {
    return (
      <Flex justifyContent="center" flexGrow={1} alignItems="center" marginBottom="size-500">
        <IllustratedMessage>
          <Error500 />
          <Heading>Something went wrong while loading application details.</Heading>
          <Content>Please try again in a few minutes.</Content>
        </IllustratedMessage>
      </Flex>
    );
  }

  return (
    <div data-testid={TEST_IDS.APP_DETAILS}>
      {isAdmin && <AccessGrantedDialog />}
      <IntegrationDetailsHeader />
      <Divider
        size="S"
        marginTop={{ base: 'size-50', M: 'size-200' }}
        marginBottom={{ base: 'size-200', S: 'size-400' }}
      />
      <Flex direction={{ base: 'column', S: 'row' }} gap={{ base: 'size-200', S: 'size-400' }}>
        <SideNavigation routes={shownRoutes} selectedTab={selectedTab} />
        {SideContent && (
          <View flexGrow={1}>
            <SideContent />
          </View>
        )}
      </Flex>
    </div>
  );
};

export default IntegrationDetails;
