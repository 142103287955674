/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

.spectrum--lightest {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1.0;
  --spectrum-gray-50: rgb(255, 255, 255);
  --spectrum-gray-75: rgb(255, 255, 255);
  --spectrum-gray-100: rgb(255, 255, 255);
  --spectrum-gray-200: rgb(235, 235, 235);
  --spectrum-gray-300: rgb(217, 217, 217);
  --spectrum-gray-400: rgb(179, 179, 179);
  --spectrum-gray-500: rgb(146, 146, 146);
  --spectrum-gray-600: rgb(110, 110, 110);
  --spectrum-gray-700: rgb(71, 71, 71);
  --spectrum-gray-800: rgb(34, 34, 34);
  --spectrum-gray-900: rgb(0, 0, 0);
  --spectrum-red-100: rgb(255, 242, 240);
  --spectrum-red-200: rgb(255, 230, 225);
  --spectrum-red-300: rgb(255, 214, 206);
  --spectrum-red-400: rgb(255, 191, 178);
  --spectrum-red-500: rgb(255, 164, 147);
  --spectrum-red-600: rgb(255, 133, 111);
  --spectrum-red-700: rgb(250, 100, 78);
  --spectrum-red-800: rgb(237, 64, 48);
  --spectrum-red-900: rgb(217, 28, 21);
  --spectrum-red-1000: rgb(187, 2, 2);
  --spectrum-red-1100: rgb(154, 0, 0);
  --spectrum-red-1200: rgb(124, 0, 0);
  --spectrum-red-1300: rgb(97, 0, 0);
  --spectrum-red-1400: rgb(77, 0, 0);
  --spectrum-orange-100: rgb(255, 245, 227);
  --spectrum-orange-200: rgb(255, 233, 197);
  --spectrum-orange-300: rgb(254, 218, 162);
  --spectrum-orange-400: rgb(255, 195, 114);
  --spectrum-orange-500: rgb(255, 168, 68);
  --spectrum-orange-600: rgb(250, 139, 26);
  --spectrum-orange-700: rgb(233, 117, 0);
  --spectrum-orange-800: rgb(209, 97, 0);
  --spectrum-orange-900: rgb(182, 80, 0);
  --spectrum-orange-1000: rgb(155, 64, 0);
  --spectrum-orange-1100: rgb(128, 50, 0);
  --spectrum-orange-1200: rgb(103, 38, 0);
  --spectrum-orange-1300: rgb(80, 28, 0);
  --spectrum-orange-1400: rgb(62, 21, 1);
  --spectrum-yellow-100: rgb(253, 247, 196);
  --spectrum-yellow-200: rgb(250, 237, 123);
  --spectrum-yellow-300: rgb(250, 224, 23);
  --spectrum-yellow-400: rgb(238, 205, 0);
  --spectrum-yellow-500: rgb(221, 185, 0);
  --spectrum-yellow-600: rgb(201, 164, 0);
  --spectrum-yellow-700: rgb(181, 144, 0);
  --spectrum-yellow-800: rgb(160, 125, 0);
  --spectrum-yellow-900: rgb(138, 106, 0);
  --spectrum-yellow-1000: rgb(116, 87, 0);
  --spectrum-yellow-1100: rgb(96, 70, 0);
  --spectrum-yellow-1200: rgb(77, 54, 0);
  --spectrum-yellow-1300: rgb(60, 41, 0);
  --spectrum-yellow-1400: rgb(47, 30, 0);
  --spectrum-chartreuse-100: rgb(235, 255, 170);
  --spectrum-chartreuse-200: rgb(211, 249, 88);
  --spectrum-chartreuse-300: rgb(195, 239, 54);
  --spectrum-chartreuse-400: rgb(176, 222, 27);
  --spectrum-chartreuse-500: rgb(157, 203, 13);
  --spectrum-chartreuse-600: rgb(139, 182, 4);
  --spectrum-chartreuse-700: rgb(122, 162, 0);
  --spectrum-chartreuse-800: rgb(106, 141, 0);
  --spectrum-chartreuse-900: rgb(90, 120, 0);
  --spectrum-chartreuse-1000: rgb(75, 100, 0);
  --spectrum-chartreuse-1100: rgb(61, 81, 0);
  --spectrum-chartreuse-1200: rgb(48, 64, 0);
  --spectrum-chartreuse-1300: rgb(36, 49, 0);
  --spectrum-chartreuse-1400: rgb(28, 37, 0);
  --spectrum-celery-100: rgb(226, 253, 218);
  --spectrum-celery-200: rgb(192, 250, 176);
  --spectrum-celery-300: rgb(164, 243, 146);
  --spectrum-celery-400: rgb(126, 229, 114);
  --spectrum-celery-500: rgb(87, 212, 86);
  --spectrum-celery-600: rgb(48, 193, 61);
  --spectrum-celery-700: rgb(15, 172, 38);
  --spectrum-celery-800: rgb(0, 150, 20);
  --spectrum-celery-900: rgb(0, 128, 15);
  --spectrum-celery-1000: rgb(0, 107, 15);
  --spectrum-celery-1100: rgb(0, 87, 13);
  --spectrum-celery-1200: rgb(0, 69, 11);
  --spectrum-celery-1300: rgb(0, 53, 8);
  --spectrum-celery-1400: rgb(0, 40, 6);
  --spectrum-green-100: rgb(228, 251, 236);
  --spectrum-green-200: rgb(195, 247, 218);
  --spectrum-green-300: rgb(156, 240, 198);
  --spectrum-green-400: rgb(113, 227, 174);
  --spectrum-green-500: rgb(80, 209, 153);
  --spectrum-green-600: rgb(54, 189, 133);
  --spectrum-green-700: rgb(29, 169, 115);
  --spectrum-green-800: rgb(0, 148, 97);
  --spectrum-green-900: rgb(0, 126, 80);
  --spectrum-green-1000: rgb(0, 105, 65);
  --spectrum-green-1100: rgb(0, 86, 53);
  --spectrum-green-1200: rgb(4, 67, 41);
  --spectrum-green-1300: rgb(9, 51, 32);
  --spectrum-green-1400: rgb(10, 38, 24);
  --spectrum-seafoam-100: rgb(228, 250, 247);
  --spectrum-seafoam-200: rgb(191, 245, 239);
  --spectrum-seafoam-300: rgb(157, 238, 231);
  --spectrum-seafoam-400: rgb(114, 223, 216);
  --spectrum-seafoam-500: rgb(75, 206, 199);
  --spectrum-seafoam-600: rgb(32, 187, 180);
  --spectrum-seafoam-700: rgb(0, 166, 160);
  --spectrum-seafoam-800: rgb(0, 145, 139);
  --spectrum-seafoam-900: rgb(0, 124, 118);
  --spectrum-seafoam-1000: rgb(0, 103, 99);
  --spectrum-seafoam-1100: rgb(10, 83, 80);
  --spectrum-seafoam-1200: rgb(18, 65, 63);
  --spectrum-seafoam-1300: rgb(18, 49, 48);
  --spectrum-seafoam-1400: rgb(16, 37, 36);
  --spectrum-cyan-100: rgb(221, 251, 255);
  --spectrum-cyan-200: rgb(183, 245, 255);
  --spectrum-cyan-300: rgb(150, 236, 252);
  --spectrum-cyan-400: rgb(107, 221, 245);
  --spectrum-cyan-500: rgb(65, 203, 236);
  --spectrum-cyan-600: rgb(26, 182, 223);
  --spectrum-cyan-700: rgb(4, 161, 205);
  --spectrum-cyan-800: rgb(0, 139, 185);
  --spectrum-cyan-900: rgb(0, 118, 164);
  --spectrum-cyan-1000: rgb(0, 97, 142);
  --spectrum-cyan-1100: rgb(0, 78, 120);
  --spectrum-cyan-1200: rgb(0, 61, 98);
  --spectrum-cyan-1300: rgb(0, 47, 77);
  --spectrum-cyan-1400: rgb(0, 36, 59);
  --spectrum-blue-100: rgb(237, 248, 255);
  --spectrum-blue-200: rgb(214, 238, 255);
  --spectrum-blue-300: rgb(193, 228, 255);
  --spectrum-blue-400: rgb(161, 212, 253);
  --spectrum-blue-500: rgb(130, 193, 251);
  --spectrum-blue-600: rgb(98, 173, 247);
  --spectrum-blue-700: rgb(66, 151, 244);
  --spectrum-blue-800: rgb(27, 127, 245);
  --spectrum-blue-900: rgb(4, 105, 227);
  --spectrum-blue-1000: rgb(0, 87, 190);
  --spectrum-blue-1100: rgb(0, 72, 153);
  --spectrum-blue-1200: rgb(0, 57, 120);
  --spectrum-blue-1300: rgb(0, 43, 92);
  --spectrum-blue-1400: rgb(0, 33, 71);
  --spectrum-indigo-100: rgb(245, 245, 254);
  --spectrum-indigo-200: rgb(232, 233, 255);
  --spectrum-indigo-300: rgb(219, 221, 255);
  --spectrum-indigo-400: rgb(200, 202, 255);
  --spectrum-indigo-500: rgb(178, 181, 255);
  --spectrum-indigo-600: rgb(155, 159, 255);
  --spectrum-indigo-700: rgb(132, 137, 253);
  --spectrum-indigo-800: rgb(109, 115, 246);
  --spectrum-indigo-900: rgb(87, 93, 232);
  --spectrum-indigo-1000: rgb(68, 74, 208);
  --spectrum-indigo-1100: rgb(53, 58, 176);
  --spectrum-indigo-1200: rgb(40, 45, 142);
  --spectrum-indigo-1300: rgb(30, 34, 110);
  --spectrum-indigo-1400: rgb(23, 26, 85);
  --spectrum-purple-100: rgb(251, 243, 255);
  --spectrum-purple-200: rgb(243, 230, 255);
  --spectrum-purple-300: rgb(235, 216, 255);
  --spectrum-purple-400: rgb(223, 194, 254);
  --spectrum-purple-500: rgb(209, 171, 253);
  --spectrum-purple-600: rgb(194, 146, 252);
  --spectrum-purple-700: rgb(178, 121, 250);
  --spectrum-purple-800: rgb(161, 93, 246);
  --spectrum-purple-900: rgb(142, 67, 234);
  --spectrum-purple-1000: rgb(120, 43, 216);
  --spectrum-purple-1100: rgb(98, 23, 190);
  --spectrum-purple-1200: rgb(77, 13, 157);
  --spectrum-purple-1300: rgb(57, 15, 120);
  --spectrum-purple-1400: rgb(42, 16, 88);
  --spectrum-fuchsia-100: rgb(255, 242, 253);
  --spectrum-fuchsia-200: rgb(255, 227, 251);
  --spectrum-fuchsia-300: rgb(255, 208, 249);
  --spectrum-fuchsia-400: rgb(252, 184, 247);
  --spectrum-fuchsia-500: rgb(247, 155, 244);
  --spectrum-fuchsia-600: rgb(240, 123, 239);
  --spectrum-fuchsia-700: rgb(228, 93, 230);
  --spectrum-fuchsia-800: rgb(211, 63, 212);
  --spectrum-fuchsia-900: rgb(188, 39, 187);
  --spectrum-fuchsia-1000: rgb(163, 10, 163);
  --spectrum-fuchsia-1100: rgb(135, 0, 136);
  --spectrum-fuchsia-1200: rgb(107, 3, 107);
  --spectrum-fuchsia-1300: rgb(80, 13, 79);
  --spectrum-fuchsia-1400: rgb(60, 14, 58);
  --spectrum-magenta-100: rgb(255, 243, 247);
  --spectrum-magenta-200: rgb(255, 229, 238);
  --spectrum-magenta-300: rgb(255, 211, 227);
  --spectrum-magenta-400: rgb(255, 188, 212);
  --spectrum-magenta-500: rgb(255, 159, 194);
  --spectrum-magenta-600: rgb(253, 127, 175);
  --spectrum-magenta-700: rgb(242, 98, 157);
  --spectrum-magenta-800: rgb(226, 68, 135);
  --spectrum-magenta-900: rgb(205, 40, 111);
  --spectrum-magenta-1000: rgb(179, 15, 89);
  --spectrum-magenta-1100: rgb(149, 0, 72);
  --spectrum-magenta-1200: rgb(119, 0, 58);
  --spectrum-magenta-1300: rgb(93, 1, 46);
  --spectrum-magenta-1400: rgb(71, 5, 35);
  --spectrum-alias-background-color-modal-overlay: rgba(0,0,0,0.4);
  --spectrum-alias-dropshadow-color: rgba(0,0,0,0.15);
  --spectrum-alias-background-color-hover-overlay: rgba(0,0,0,0.04);
  --spectrum-alias-highlight-hover: rgba(0,0,0,0.06);
  --spectrum-alias-highlight-active: rgba(50,50,50,0.1);
  --spectrum-alias-highlight-selected: rgba(4,105,227,0.1);
  --spectrum-alias-highlight-selected-hover: rgba(4,105,227,0.2);
  --spectrum-alias-highlight-invalid: rgba(217, 28, 21, 0.15); /* matches red-900 */
  --spectrum-alias-text-highlight-color: rgba(4,105,227,0.2);
  --spectrum-alias-background-color-quickactions: rgba(255,255,255,0.9);
  --spectrum-alias-radial-reaction-color-default: rgba(34,34,34,0.6);
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-300);
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-colorarea-border-color: rgba(50,50,50,0.1);
  --spectrum-colorarea-border-color-hover: rgba(50,50,50,0.1);
  --spectrum-colorarea-border-color-down: rgba(50,50,50,0.1);
  --spectrum-colorarea-border-color-key-focus: rgba(50,50,50,0.1);
  --spectrum-colorslider-border-color: rgba(50,50,50,0.1);
  --spectrum-colorslider-border-color-hover: rgba(50,50,50,0.1);
  --spectrum-colorslider-border-color-down: rgba(50,50,50,0.1);
  --spectrum-colorslider-border-color-key-focus: rgba(50,50,50,0.1);
  --spectrum-colorslider-vertical-border-color: rgba(50,50,50,0.1);
  --spectrum-colorslider-vertical-border-color-hover: rgba(50,50,50,0.1);
  --spectrum-colorslider-vertical-border-color-down: rgba(50,50,50,0.1);
  --spectrum-colorslider-vertical-border-color-key-focus: rgba(50,50,50,0.1);
  --spectrum-colorwheel-border-color: rgba(50,50,50,0.1);
  --spectrum-colorwheel-border-color-hover: rgba(50,50,50,0.1);
  --spectrum-colorwheel-border-color-down: rgba(50,50,50,0.1);
  --spectrum-colorwheel-border-color-key-focus: rgba(50,50,50,0.1);
  --spectrum-miller-column-item-background-color-selected: rgba(38,128,235,0.1);
  --spectrum-miller-column-item-background-color-selected-hover: rgba(38,128,235,0.2);
  --spectrum-well-background-color: rgba(80,80,80,0.02);
  --spectrum-well-border-color: rgba(50,50,50,0.05);
  /* BEGIN RSP PATCH */
  /* missing in spectrum-css */
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-50);
  /* END RSP PATCH */
  /* react spectrum additions */
  --react-spectrum-datepicker-placeholder-color: rgb(118, 118, 118); /* 4.54:1 contrast ratio */

  --spectrum-accent-background-color-default: var(--spectrum-accent-color-900);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-1000);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-1100);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-1000);

  --spectrum-neutral-background-color-default: var(--spectrum-gray-800);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-900);

  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-600);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-700);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-800);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-700);

  --spectrum-negative-background-color-default: var(--spectrum-red-900);
  --spectrum-negative-background-color-hover: var(--spectrum-red-1000);
  --spectrum-negative-background-color-down: var(--spectrum-red-1100);
  --spectrum-negative-background-color-key-focus: var(--spectrum-red-1000);

  --spectrum-positive-background-color-default: var(--spectrum-green-900);
  --spectrum-positive-background-color-hover: var(--spectrum-green-1000);
  --spectrum-positive-background-color-down: var(--spectrum-green-1100);
  --spectrum-positive-background-color-key-focus: var(--spectrum-green-1000);

  --spectrum-informative-background-color-default: var(--spectrum-blue-900);
  --spectrum-informative-background-color-hover: var(--spectrum-blue-1000);
  --spectrum-informative-background-color-down: var(--spectrum-blue-1100);
  --spectrum-informative-background-color-key-focus: var(--spectrum-blue-1000);

  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-600);
  --spectrum-orange-background-color-default: var(--spectrum-orange-600);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-400);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-500);
  --spectrum-celery-background-color-default: var(--spectrum-celery-600);
  --spectrum-green-background-color-default: var(--spectrum-green-900);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-900);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-900);
  --spectrum-blue-background-color-default: var(--spectrum-blue-900);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-900);
  --spectrum-purple-background-color-default: var(--spectrum-purple-900);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-900);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-900);

  --spectrum-negative-visual-color: var(--spectrum-red-800);
  --spectrum-positive-visual-color: var(--spectrum-green-700);
  --spectrum-notice-visual-color: var(--spectrum-orange-700);
  --spectrum-informative-visual-color: var(--spectrum-blue-800);

  --spectrum-gray-visual-color: var(--spectrum-gray-500);
  --spectrum-red-visual-color: var(--spectrum-red-800);
  --spectrum-orange-visual-color: var(--spectrum-orange-700);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-600);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-600);
  --spectrum-celery-visual-color: var(--spectrum-celery-700);
  --spectrum-green-visual-color: var(--spectrum-green-700);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-700);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-600);
  --spectrum-blue-visual-color: var(--spectrum-blue-800);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-800);
  --spectrum-purple-visual-color: var(--spectrum-purple-800);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-800);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-800);
}
