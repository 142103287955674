//@ts-check
import { Cell, Column, Row, TableBody, TableHeader, TableView } from '@adobe/react-spectrum';
import React from 'react';

import { LineClampWithPopup } from '../../../../../../components/LineClampWithPopup';

const scopeDescriptionsColumns = [
  {
    label: 'Scope',
    key: 'scope',
  },
  {
    label: 'End-user description',
    key: 'description',
  },
];

/**
 * @param {object} props
 * @param {import('@action-types/ecApp').AppDetails['scopeDescription']} props.scopeDescriptions
 */
const ScopeDescriptionsTable = ({ scopeDescriptions }) => {
  return (
    <TableView overflowMode="wrap" aria-label="Scope descriptions">
      <TableHeader columns={scopeDescriptionsColumns}>
        {(column) => <Column key={column.key}>{column.label}</Column>}
      </TableHeader>
      <TableBody items={scopeDescriptions}>
        {(scope) => (
          <Row key={scope.description}>
            {(columKey) => (
              <Cell key={`${scope.scope}-${columKey}`}>
                <LineClampWithPopup lines={2}>
                  {scope?.[scopeDescriptionsColumns.find((column) => columKey === column.key)?.key]}
                </LineClampWithPopup>
              </Cell>
            )}
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};

export default ScopeDescriptionsTable;
