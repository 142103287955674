import { Flex, LabeledValue } from '@adobe/react-spectrum';
import React from 'react';

/**
 * @param {object} props
 * @param {import('react').ReactNode} props.label
 * @param {import('react').ReactNode} props.children
 * @param {import('react').ReactNode} [props.icon]
 * @param {string} [props.testId]
 */
const IconLabeledValue = ({ label, icon, children, testId }) => {
  return (
    <Flex direction="column" data-testid={testId}>
      <LabeledValue
        value=""
        label={
          <Flex alignItems="end" gap="size-50" minHeight="23px">
            {icon}
            <span>{label}</span>
          </Flex>
        }
      />
      <Flex alignItems="end" minHeight="24px">
        {children}
      </Flex>
    </Flex>
  );
};

export default IconLabeledValue;
