//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useStore } from '../../../../pages/manage/store';
import { ContainerContext } from '../../../../utils';
import getEnterpriseEntitlements from '../../actions/get-enterprise-entitlements';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

/**
 * @param {'ABD' | 'S2S'} appType
 * @param {import('@tanstack/react-query').UseQueryOptions} options */
const useEnterpriseEntitlements = (appType = 'S2S', options = {}) => {
  /** @type {string | undefined} */
  const orgId = useStore(selectedOrgSelector);

  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1');
  const order = searchParams.get('order') === 'ascending' ? 'ASC' : 'DESC';
  const sort = searchParams.get('sort') || 'CREATED';
  const pageIndex = page - 1;

  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/entitlement').EnterpriseEntitlementsQueryState} */
  const data = useQuery({
    ...options,
    queryKey: ['enterprise-entitlements', orgId, pageIndex, order, token, appType],
    enabled: Boolean(token && orgId && (options.enabled ?? true)),
    queryFn: ({ signal }) =>
      getEnterpriseEntitlements({
        token: /** @type {string} */ (token),
        orgId: /** @type {string} */ (orgId),
        pageIndex,
        order,
        signal,
        appType,
        sort,
      }),
  });

  return data;
};

export default useEnterpriseEntitlements;
