//@ts-check
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useDialogContainer,
} from '@adobe/react-spectrum';
import React from 'react';

import { LINKS } from '../../../../constants';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.content
 */
const SpNonAdminActionDialog = ({ title, content }) => {
  const { dismiss } = useDialogContainer();

  return (
    <Dialog>
      <Heading>{title}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-200">
          <Text>{content}</Text>
          <Text>
            <Link isQuiet>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.SP.VIEW_DOCS}>
                Read more
              </a>
            </Link>{' '}
            about accessing and managing service integration applications.
          </Text>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dismiss}>
          Close
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default SpNonAdminActionDialog;
