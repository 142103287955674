//@ts-check
import {
  error as errorToast,
  success as successToast,
  warning as warningToast,
} from '@react/react-spectrum/Toast/js/ToastContainer';
import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';

import { useStore } from '../../../pages/manage/store';
import { ContainerContext } from '../../../utils';
import patchConsent from '../actions/patch-consent';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

const useUpdateConsent = () => {
  const orgId = useStore(selectedOrgSelector);
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/consent').EcUpdateConsentMutationState} */
  const data = useMutation({
    mutationKey: ['updateConsent'],
    mutationFn: async ({ refetch, ...params }) => {
      if (!token) {
        throw new Error('Missing token');
      }
      if (!orgId) {
        throw new Error('Missing orgId');
      }
      const response = await patchConsent({
        token,
        orgId,
        ...params,
      });
      let refetched = true;
      try {
        const refetchResult = await refetch?.();
        if (refetchResult?.error) {
          throw refetchResult.error;
        }
      } catch (e) {
        refetched = false;
      }
      return { refetched, response };
    },
    onSuccess: ({ refetched }, variables) => {
      const successToastMessage = variables?.successToastMessage;
      if (refetched && successToastMessage) {
        successToast(successToastMessage);
      } else if (!refetched) {
        warningToast(
          'Consent updated, but failed to refetch data. You may need to refresh the page to see the updated status.'
        );
      }
    },
    onError: (error, variables) => {
      if (variables?.showErrorToast && error.message) {
        errorToast(error.message, {
          timeout: 0,
        });
      }
    },
  });

  return data;
};

export default useUpdateConsent;
